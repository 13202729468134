import { ApolloProvider } from "@apollo/react-hooks";
import React from "react";
import ReactDOM from "react-dom";
import "react-app-polyfill/stable";

import { GridThemeProvider } from "styled-bootstrap-grid";

import client from "./api/client";
import ThemeProviderHOC from "./themeProviderHOC";
import gridTheme from "./config/theme/grid";
import "./index.css";

import Root from "./routing/index";
import Store from "./store/index";

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <ThemeProviderHOC>
        <GridThemeProvider gridTheme={gridTheme}>
          <Store>
            <Root />
          </Store>
        </GridThemeProvider>
      </ThemeProviderHOC>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById("root"),
);
