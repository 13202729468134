import { Styles } from "react-select";
import { selectedTheme } from "../../../themeProviderHOC";

export const styles: Styles = {
  dropdownIndicator: (base, props) => ({
    ...base,
    transform: props.isFocused ? "rotate(0deg)" : "rotate(-90deg)",
    color:
      process.env.REACT_APP_THEME_KEY === "COVEA"
        ? selectedTheme.colors.darkText
        : selectedTheme.colors.primaryTextLight,
    transition: "all",
    transitionDuration: "0.2s",
    "&:hover": {
      color:
        process.env.REACT_APP_THEME_KEY === "COVEA"
          ? selectedTheme.colors.darkText
          : selectedTheme.colors.primaryTextLight,
    },
  }),
  container: (base) => ({
    ...base,
    width: "100%",
  }),
  control: (base) => ({
    ...base,
    border: "none",
    boxShadow: "none",
    display: "flex",
    width: "100%",
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    overflow: "hidden",
  }),
  valueContainer: (base) => {
    return {
      ...base,
      fontFamily: "Arial",
      fontStyle: "italic",
      fontSize: 16,
      paddingLeft: 16,
    };
  },
  placeholder: (base) => ({
    ...base,
    color: selectedTheme.colors.placeholder,
    textTransform: "none",
  }),
  indicatorsContainer: (base) => ({
    ...base,
    backgroundColor: selectedTheme.colors.disabled,
    width: 50,
    height: 48,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  menu: (base) => ({
    ...base,
    zIndex: 2,
  }),
  menuList: (base) => ({
    ...base,
  }),
  option: (base, props) => {
    return {
      ...base,
      backgroundColor: props.isSelected || props.isFocused ? selectedTheme.colors.primaryTextLight : "transparent",
      fontFamily: "Arial",
      fontStyle: "italic",
      fontSize: 16,
      paddingLeft: 17,
      paddingRight: 17,
      opacity: props.isFocused ? 0.4 : 1,
      "&:active": {
        backgroundColor: selectedTheme.colors.primaryTextLight,
        opacity: 0.4,
      },
      "&:hover": {
        backgroundColor: selectedTheme.colors.primaryTextLight,
        opacity: 0.4,
      },
    };
  },
};

export const upperCaseStyles: Styles = {
  ...styles,
  valueContainer: (base) => ({
    ...base,
    textTransform: "uppercase",
  }),
  option: (base) => ({
    ...base,
    textTransform: "uppercase",
  }),
};
