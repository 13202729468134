import React from "react";
import { Vehicles } from "../../../pages/questionnaire/configuration/vehicles";
import { BasicTransportInfo } from "../../../pages/subscription/transport/index";
import { CompletedStep } from "../../index";

export type PersonalTransportInfoData = CompletedStep & BasicTransportInfo;

type PersonalTransportContext = PersonalTransportInfoData & {
  savePersonalTransportInfo(modifiedData: PersonalTransportInfoData): void;
};

export const initialTransportInfoData: BasicTransportInfo = {
  type: 0,
  brand: 0,
  model: "",
  serial: "",
  typeName: Vehicles.trottinette,
  brandName: "",
  speedLimitPolicy: false,
};

export const PersonalTransportContext = React.createContext<PersonalTransportContext>({
  ...initialTransportInfoData,
  completed: false,
  savePersonalTransportInfo(modifiedData: PersonalTransportInfoData): PersonalTransportInfoData {
    return modifiedData;
  },
});

export const PersonalTransportContextProvider = PersonalTransportContext.Provider;
