import gql from "graphql-tag";

const SUBMIT = gql`
  mutation submit($input: Sumbission) {
    submit(input: $input) {
      redirect
    }
  }
`;

export default SUBMIT;
