import React, { FC } from "react";
import styled, { css } from "styled-components";
import { CustomIcon } from "../index";
import { DefaultFlexStyles } from "../../styles";
import { media } from "styled-bootstrap-grid";
import { DefinedColors } from "../../types";

type TooltipIconProps = {
  ibanLocker?: boolean;
};

const TooltipIcon = styled.div<TooltipIconProps>`
  ${DefaultFlexStyles};
  cursor: pointer;
  margin-right: ${(props: TooltipIconProps) => (props.ibanLocker ? "18px" : "0")};
`;

type TooltipBoxProps = {
  rightPosition?: string;
  ibanLocker?: boolean;
};

const TooltipBox = styled.div<TooltipBoxProps>`
  position: absolute;
  top: 25px;
  right: 0;
  transform: translate(0, 0);
  color: rgba(84, 86, 91, 0.8);
  box-shadow: 0 5px 15px 0 rgba(82, 90, 101, 0.15);
  background-color: #fff;
  font-size: 13px;
  width: ${({ rightPosition }: TooltipBoxProps) => (rightPosition === "bottom" ? "245px" : "280px")};
  padding: 8px 8px;
  border-radius: 4px;
  visibility: hidden;
  transform: translate(61px, 0);
  text-align: ${({ rightPosition }: TooltipBoxProps) => (rightPosition === "bottom" ? "center" : "left")};
  letter-spacing: 0.2px;
  ${media.lg`
    left: 50%;
    transform: translate(-60%, 0);
    width: 280px;
	`};
  ${media.xl`
    transform: translate(-50%, 0);
	`};

  ${(props: TooltipBoxProps) => {
    switch (props.rightPosition) {
      case "top":
        return css`
          top: 18px;
          right: auto;
          transform: translate(-58px, 0);
          ${media.lg`
					top: 25px;
  				right: 0;
						transform: translate(20px, -90px);
					`};
          ${media.xl`
						transform: translate(20px, -90px);
					`};
        `;
      case "bottom":
        return css`
          top: -15px;
          transform: translate(-20px, 0);
          ${media.lg`
						top: 25px;
						text-align: left;
					`};
          ${media.xl`
						transform: translate(15px,-30px);
					`};
        `;
    }
  }}
`;

type TooltipCardProps = {
  right: string;
};

const TooltipCard = styled.div<TooltipCardProps>`
  position: absolute;
  bottom: 10px;
  ${(props: TooltipCardProps) => css`
    right: ${props.right || "-5px"};
  `};
  margin: 0;
  z-index: 1;

  & ${TooltipIcon}:hover + ${TooltipBox} {
    visibility: visible;
  }
`;

type CustomTooltipProps = {
  text: string;
  right?: string;
  rightPosition?: string;
  ibanLocker?: boolean;
};

const Tooltip: FC<CustomTooltipProps> = ({ text, right, rightPosition, ibanLocker }) => {
  const iconImage: string = ibanLocker ? "filledLocker" : "tooltip";
  const iconColor: DefinedColors = ibanLocker ? "primaryLight" : "tooltipColor";

  return (
    <TooltipCard right={right}>
      <TooltipIcon ibanLocker={ibanLocker}>
        <CustomIcon icon={iconImage} iconColor={iconColor} width="12" height="12" />
      </TooltipIcon>
      <TooltipBox rightPosition={rightPosition} ibanLocker={ibanLocker}>
        {text}
      </TooltipBox>
    </TooltipCard>
  );
};

export default Tooltip;
