import { media } from "styled-bootstrap-grid";
import styled, { css } from "styled-components";
import { StyledThemePropsType } from "../../config/theme/index";
import { H1DesktopStyles } from "../../shared/typography/index";

const QuizStepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding-bottom: 50px;
  ${media.lg`
    align-items: flex-start;
    padding-bottom: 80px;
  `};
`;

type QuizStepTitleProps = {
  finish?: boolean;
  centered?: boolean;
  marginTop?: string;
};

const QuizStepTitle = styled.div<QuizStepTitleProps>`
  width: 100%;
  ${H1DesktopStyles};
  letter-spacing: 0.18px;
  color: ${(props: StyledThemePropsType & QuizStepTitleProps) =>
    props.finish ? props.theme.colors.primaryText : props.theme.colors.primaryText};
  margin-top: ${(props: StyledThemePropsType & QuizStepTitleProps) => (props.marginTop ? props.marginTop : "87px")};
  font-size: 16px;
  line-height: 1.38;
  text-align: left;

  ${(props: QuizStepTitleProps) => css`
    ${media.lg`
			margin-top: 0;
			font-size: 22px;
			text-align: ${props.centered ? "center" : "initial"};
			line-height: 1.45;
		`};
  `};
`;

export { QuizStepWrapper, QuizStepTitle };
