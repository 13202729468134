import React, { useCallback, useContext, useEffect, useLayoutEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import { GetVoucherQueryResult, GetVoucherQueryVariables } from "../../../api/interfaces/index";
import GET_VOUCHER from "../../../api/queries/getVoucher";
import { AddressContext } from "../../../store/context/subscription/address";
import { PaymentContext } from "../../../store/context/subscription/payment";
import { RedirectionContext } from "../../../store/context/subscription/redirection";
import { PersonalTransportContext } from "../../../store/context/subscription/transport";
import {
  CouponContainer,
  VoucherIconContainer,
  VoucherIcon,
  VoucherActivationText,
  VoucherTitle,
  ElementToNextRow,
	SubmitButton,
	DefaultLink,
} from "./elements";
import { Loader } from "../../../shared/elements/Loader";

const AutomaticVoucherActivation = () => {
  useLayoutEffect(() => window.scrollTo({ top: 0, behavior: "smooth" }), []);
  const { voucher } = useParams<{ voucher: string }>();
  const { push } = useHistory();

  const { clearPersistedStorage } = useContext(RedirectionContext);
  useEffect(() => clearPersistedStorage(), [clearPersistedStorage]);

  const { data, loading, error } = useQuery<GetVoucherQueryResult, GetVoucherQueryVariables>(GET_VOUCHER, {
    variables: { voucher },
    fetchPolicy: "cache-and-network",
  });

  const [state, setState] = useState({
    loading: false,
    result: false,
    message: (
      <span>
        Attention, avant de valider votre contrat, <br/> Prenez connaissance des garanties et remplissez le
        questionnaire
      </span>
    ),
  });

  const { savePersonalTransportInfo, ...transportParams } = useContext(PersonalTransportContext);

  const { saveAddressInfo, ...addressParams } = useContext(AddressContext);

  const { savePaymentInfo, ...paymentParams } = useContext(PaymentContext);

  useEffect(() => {
    if (!loading && !state.loading) {
      if (data) {
        const {
          productBrandId,
          productModel,
          productSerial,
          productTypeId,
          email,
          mobile,
          lastName,
          firstName,
          discount,
        } = data.voucher;
        setState({ ...state, result: true, loading: true });
        savePersonalTransportInfo({
          ...transportParams,
          type: productTypeId,
          brand: productBrandId,
          model: productModel,
          serial: productSerial,
        });
        saveAddressInfo({
          ...addressParams,
          email,
          phone: `0${mobile}`.replace(/\d{2}(?=.)/g, '$& '),
        });
        savePaymentInfo({
          ...paymentParams,
          discount,
          voucher,
          userName: firstName ? `${lastName} ${firstName}` : "",
          firstName: firstName || "",
          lastName: lastName || "",
        });
      }
      if (error) {
        setState({ message: (
					<span>
						Oups, le code saisi a déjà été utilisé, vous pouvez toujours souscrire à&nbsp;
						<DefaultLink to="/questionnaire/step1">l’assurance sans coupon</DefaultLink>
            &nbsp;ou consulter&nbsp;
						<DefaultLink to="/">nos autres offres</DefaultLink>					
					</span>
				), result: false, loading: true });
      }
    }
  }, [
    addressParams,
    data,
    error,
    loading,
    paymentParams,
    push,
    saveAddressInfo,
    savePaymentInfo,
    savePersonalTransportInfo,
    state,
    transportParams,
    voucher,
  ]);

  const redirect = useCallback(() => push("/questionnaire"), [push]);

  return (
    <Loader isLoading={loading}>
      <CouponContainer>
        <VoucherIconContainer>
          <VoucherIcon icon="coupon" iconColor={state.result ? "primaryText" : "error"} width="100" height="65" />
        </VoucherIconContainer>
        {state.result ? (
          <VoucherTitle>
            Bravo, <ElementToNextRow /> votre coupon est activé !
          </VoucherTitle>
        ) : null}
        <VoucherActivationText>{state.message}</VoucherActivationText>
      </CouponContainer>
      {state.result ? (
        <SubmitButton variant="dark" size="l" disabled={false} onClick={redirect}>
          Souscrire à la garantie responsabilité civile
        </SubmitButton>
      ) : null}
    </Loader>
  );
};

export default AutomaticVoucherActivation;
