import React, { FC } from "react";
import styled, { css } from "styled-components";
import { UnderlinedLinkTextStyles } from "../../typography/index";
import { CustomIcon } from "../index";
import { RedirectLinkProps } from "../RedirectLink/index";

type LinkContainerProps = {
  font: string;
  noneDecoration?: boolean;
};

const LinkContainer = styled.a<LinkContainerProps>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  ${({ noneDecoration }: LinkContainerProps) =>
    noneDecoration
      ? css`
          text-decoration: none;`
      : UnderlinedLinkTextStyles};
  font-size: 12px;
  font-size: ${(props: LinkContainerProps) => props.font};
`;

type LinksIconProps = {
  icon?: boolean;
};

type CustomLinkProps = RedirectLinkProps &
  LinksIconProps & {
    target: "_blank";
    font: string;
    noneDecoration?: boolean;
  };

const CustomLink: FC<CustomLinkProps> = ({ children, href, target, icon, font, noneDecoration }) => (
  <LinkContainer font={font} href={href} target={target} noneDecoration={noneDecoration}>
    {children}
    {icon ? <CustomIcon icon="targetBlank" width="15" height="15" iconColor="gray" margin="0 0 0 7px" /> : null}
  </LinkContainer>
);

export default CustomLink;
