import styled from "styled-components";
import { StyledThemePropsType } from "../../config/theme/index";
import { Button } from "../../shared/elements/index";
import { DefaultFlexStyles } from "../../shared/styles/index";
import { ErrorPopupTextDesktopStyles, H2MobileStyles, H2Styles } from "../../shared/typography/index";
import { media } from "styled-bootstrap-grid";

const isThemeCovea: boolean = process.env.REACT_APP_THEME_KEY === "COVEA";

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const HeaderText = styled.div`
  ${DefaultFlexStyles};
  flex-direction: column;
  align-items: flex-start;
`;

const HeaderTitle = styled.div`
  ${H2MobileStyles};
  color: ${(props: StyledThemePropsType) => props.theme.colors.primaryTextLight};
  padding-top: 87px;
  margin-bottom: 7px;
  text-align: left;

  ${media.lg`
    padding-top: 125px;
  `};

  ${(props: StyledThemePropsType) => media.xl`
    ${H2Styles};
    color: ${props.theme.colors.primaryTextLight};
  `};
`;

const HeaderDescription = styled.div`
  ${H2MobileStyles};
  padding-top: 87px;
  text-align: left;
  position: relative;
  color: ${(props: StyledThemePropsType) =>
    isThemeCovea ? props.theme.colors.gray : props.theme.colors.primaryTextLight};
  ${media.lg`
    padding-top: 125px;
  `};
  ${(props: StyledThemePropsType) => media.xl`
    ${H2Styles};
    text-align: left;
		font-size: 22px;
		color: ${isThemeCovea ? props.theme.colors.gray : props.theme.colors.primaryTextLight};
  `};
`;

const TooltipWrapper = styled.div`
  position: absolute;
  right: 20px;
  top: 35px;
  right: 20px;
  ${media.lg`
		right:-13px;
		top: auto;
		bottom: 0;
  `};
`;

const ContentHeaderWrapper = styled.div`
  position: relative;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 40px 220px;
  background-color: ${(props: StyledThemePropsType) => props.theme.colors.disabled};
`;

const DialogButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 200px;
  margin-top: 34px;
  position: relative;

  ${media.lg`
    flex-direction: row;
    width: 232px;
    margin-top: 88px;
  `};
`;

const DialogButton = styled(Button)`
  width: 100%;
  padding: 9px 34px;
  border-radius: 3px;

  ${media.lg`
    width: 100px;
  `};

  &:hover {
    background-color: ${(props: StyledThemePropsType) => props.theme.colors.white};
    color: ${(props: StyledThemePropsType) => props.theme.colors.primaryTextLight};
    border: 1px solid ${(props: StyledThemePropsType) => props.theme.colors.primaryTextLight};
  }
`;

const DialogButtonSpacing = styled.div`
  width: 100%;
  margin: 32px 0 0 0;

  ${media.lg`
    margin: 0 0 0 32px;
  `};
`;

type TransportIconProps = {
  icon: string;
};

const TransportIcon = styled.div<TransportIconProps>`
  width: 76px;
  height: 154px;
  position: absolute;
  background: url(${(props: TransportIconProps & StyledThemePropsType) => props.theme.icons[props.icon]}) no-repeat
    center;
  background-size: contain;
  left: -42px;
  bottom: -129px;

  ${media.lg`
    width: 76px;
    height: 154px;
    right: -140px;
    bottom: -65px;
		left: auto;
  `};

  ${media.xl`
    width: 108px;
    height: 220px;
    right: -337px;
    bottom: -157px;
		left: auto;
  `};
`;

const ErrorTextLink = styled.a`
  ${ErrorPopupTextDesktopStyles};
  text-decoration: underline;
  cursor: pointer;
  line-height: 22px;
  ${media.lg`
	line-height: 30px;
	`};
`;

const NextLine = styled.div`
  display: block;
  width: 100%;
  margin-top: 20px;

  ${media.lg`
		display: none;
	`};
`;

const CustomSvg = styled.div<any>`
  display: flex;
  align-items: center;
  height: 110px;
  width: 110px;
  background: url(${(props) => props.icon}) no-repeat
  center;
  background-size: contain;
`;

const CustomSvgLogo = styled(CustomSvg)`
  height: ${isThemeCovea ? "45px" : "63px"};
`;

const ContentHeader = styled.h2`
  ${H2MobileStyles};
  margin-top: 44px;
  position: relative;
  font-weight: normal;
  margin-bottom: 0;

  ${(props) => media.xl`
    font-size: 20px;
    font-weight: bold;
    line-height: 22px;
    letter-spacing: normal;
    color: ${props.theme.colors.textStyleMain};
    margin-top: 41px;
  `};
`;

const DialogPagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
`;

const MessageWrapper = styled.div`
  width: 280px;
  position: fixed;
  top: 35%;
  left: 50%;
  z-index: 101;
  transform: translate(-50%, -50%);

  ${media.lg`
		position: relative;
		top: 0;
		left: 0;
		z-index: 0;
		transform: translate(0);
    width: 485px;
  `};
  ${media.lg`
	margin: 0 0 30px;
`};
  ${media.xl`
		width: 680px;
		margin: 30px 0;
  `};
`;

const HiddenWrapper = styled.div`
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background-color: ${(props: StyledThemePropsType) => props.theme.colors.darkColor};
  z-index: 100;
  ${media.lg`
		display: none;
	`};
`;

const QuestionnaireFooterWrapper = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
`;

const EmptyContainer = styled.div`
  width: 100%;
  height: 100px;
  margin-bottom: 50px;
`;

export {
  HeaderContainer,
  HeaderText,
  HeaderTitle,
  HeaderDescription,
  ContentWrapper,
  DialogButtonsContainer,
  DialogButton,
  DialogButtonSpacing,
  TooltipWrapper,
  TransportIcon,
  ErrorTextLink,
  CustomSvg,
  CustomSvgLogo,
  ContentHeader,
  DialogPagesContainer,
  MessageWrapper,
  HiddenWrapper,
  ContentHeaderWrapper,
  NextLine,
  QuestionnaireFooterWrapper,
  EmptyContainer,
};
