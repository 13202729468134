import React from "react";
import {
  CardWrapper,
  Card,
  CardButton,
  CardTitle,
  PlanName,
  CheckmarksWrapper,
  CardDivider,
  UncheckIcon,
  PriceWrapper,
  PriceText,
  DescriptionText,
} from "./elements";
import { CustomIcon } from "../../../../../shared/elements";
import { useHistory } from "react-router-dom";

interface PlanCardProps {
  buttonText: string;
  tariffName: string;
  checks: boolean[];
  price: number;
  wholePrice: number;
  url: string;
}

const PlanCardDesktop: React.FC<PlanCardProps> = ({ buttonText, tariffName, checks, price, url }) => {
  const { push } = useHistory();

  const priceFormatted = price.toFixed(2).replace(".", ",");
  const yearlyPriceFormatted = (price * 12).toFixed(2).replace(".", ",");
  return (
    <CardWrapper>
      <Card>
        <CardTitle>
          Formule &nbsp;
          <PlanName>{tariffName}</PlanName>
        </CardTitle>
        <CheckmarksWrapper>
          {checks.map((check, index) => {
            return check ? (
              <CustomIcon key={index} icon="check" iconColor="primaryLight" width="30" height="21" />
            ) : (
              <UncheckIcon key={index} />
            );
          })}
        </CheckmarksWrapper>
        <CardDivider />
        <PriceWrapper>
          <PriceText>{priceFormatted} €</PriceText>
          <DescriptionText>/mois</DescriptionText>
        </PriceWrapper>
        <DescriptionText bottom>soit {yearlyPriceFormatted} € pour un an&nbsp;</DescriptionText>
      </Card>
      <CardButton
        onClick={() => {
          push(url);
        }}
      >
        {buttonText}
      </CardButton>
    </CardWrapper>
  );
};

export default PlanCardDesktop;
