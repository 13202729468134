import * as yup from "yup";

const modelFirstChar = /[A-Z]/;
const modelChar = /[0-9a-zA-Z]/;
export const modelMask = [modelFirstChar, " ", modelChar, modelChar, modelChar];

const type = yup
  .number()
  .required("Vehicle type is required field.")
;

const brand = yup
  .number()
  .required("Brand is required field.")
;

const brandName = yup
  .string()
  .required()
;

const model = yup
  .string()
  .required("Model is required field.")
;

const serial = yup
  .string()
  .required("Serial is required field.")
;

const speedLimitPolicy = yup
  .boolean()
  .required("Speed Limit Policy is required field.")
  .equals([true])
;

export const transportSchema = yup.object({
  type,
  brand,
  brandName,
  model,
  serial,
  speedLimitPolicy,
});
