import React, { FC } from "react";
import styled from "styled-components";
import { StyledThemePropsType } from "../../../../config/theme";

interface PriceProps {
  price: number;
  active: boolean;
}

const Price: FC<PriceProps> = ({ price, active }) => {
  const firstPart = price.toFixed(2).toString().split(".")[0];
  const secondPart = price.toFixed(2).toString().split(".")[1];

  return (
    <PriceWrapper>
      <FirstPartText active={active}>{firstPart}</FirstPartText>
      <SecondPartText active={active}>{`,${secondPart}`}</SecondPartText>
      <EuroSymbol active={active}>€</EuroSymbol>
      <MonthText active={active}>/ mois</MonthText>
    </PriceWrapper>
  );
};

type TariffCardContainerProps = {
  active: boolean;
};

const PriceWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const FirstPartText = styled.div<TariffCardContainerProps>`
  /* font-size: ${(props: TariffCardContainerProps) => (props.active ? "55px" : "47px")}; */
  font-size: 55px;
  font-weight: bold;
  letter-spacing: 1.13px;
  color: ${(props: StyledThemePropsType) => props.theme.colors.primaryText};
  line-height: 69px;
`;

const SecondPartText = styled.div<TariffCardContainerProps>`
  /* font-size: ${(props: TariffCardContainerProps) => (props.active ? "27px" : "23px")}; */
  font-size: 27px;
  line-height: 36px;
  margin: 10px 0 0 3px;
  letter-spacing: 0.58px;
  color: ${(props: StyledThemePropsType) => props.theme.colors.primaryText};
`;

const EuroSymbol = styled.div`
  /* font-size: ${(props: TariffCardContainerProps) => (props.active ? "22px" : "19px")}; */
  font-size: 22px;
  margin: 10px 0 0 7px;
  letter-spacing: 0.4px;
  color: ${(props: StyledThemePropsType) => props.theme.colors.primaryText};
`;

const MonthText = styled.div`
  margin: 32px 0 0 4px;
  /* font-size: ${(props: TariffCardContainerProps) => (props.active ? "19px" : "16px")}; */
  font-size: 19px;
  font-weight: bold;
  color: ${(props: StyledThemePropsType) => props.theme.colors.textStyleMain};
  justify-self: flex-end;
`;

export default Price;
