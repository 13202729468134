import React from "react";
import Page, { PageContainer } from "../../../shared/layout/Page";
import { Container } from "styled-bootstrap-grid";
import styled from "styled-components";
import Footer from "../../../shared/elements/Footer";

const Spacing = styled.div`
    margin-top: 70px;
    width: 100%;
    height: 100%;
  `;

const StyledContainer = styled(Container)`
  display: flex;
  height: 100%;
  flex-direction: column;
`;

const Wrapper = (props: React.PropsWithChildren<any>) => {
  return (
      <PageContainer>
        <Page fullPage>
          <Spacing>
            <StyledContainer>
              {props.children}
            </StyledContainer>
          </Spacing>
        </Page>
				<Footer />
      </PageContainer>
  );
};

export default Wrapper;
