import { CouponContainer as CouponContainerOriginal, SubmitButton as SubmitButtonOriginal } from "../elements";
import styled, { css } from "styled-components";
import { media } from "styled-bootstrap-grid";
import CustomIcon from "../../../shared/elements/Icon";
import { H2MobileStyles, UnderlinedLinkTextStyles } from "../../../shared/typography";
import { StyledThemePropsType } from "../../../config/theme";
import { Link } from "react-router-dom";

const CouponContainer = styled(CouponContainerOriginal)`
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 280px;
  height: 327px;

  ${media.lg`
    width: auto;
    margin-top: 90px;
		padding: 0 125px 77px;
		height: 390px;
  `};
`;

const VoucherIconContainer = styled.div`
  margin: 50px 0 30px;
  width: 100px;
  height: 65px;

  ${media.lg`
    width: 100px;
    height: 65px;
    margin-top: 77px;
    margin-bottom: 40px;
  `};
`;

const VoucherIcon = styled(CustomIcon)`
  width: 100%;
  height: 100%;
`;

const VoucherActivationText = styled.p`
  ${H2MobileStyles};
  font-size: 16px;
  margin: 0;

  ${media.xl`
    font-size: 22px;
    letter-spacing: 0.17px;
    line-height: 32px;
  `};
`;
const ElementToNextRow = styled.br`
  display: block;
  ${media.lg`
	display: none;
	`};
`;
const VoucherTitle = styled.p`
  color: ${(props: StyledThemePropsType) => props.theme.colors.primaryText};
  font-size: 18px;
  font-weight: bold;
  line-height: 1.33;
  letter-spacing: 0.46px;
  text-align: center;
  margin: 0 0 15px;

  ${media.lg`
	font-size: 30px;
	margin: 0 0 10px 0;
	`};
`;

const SubmitButton = styled(SubmitButtonOriginal)`
  font-size: 14px;
  letter-spacing: 0.5px;
  height: auto;
  min-height: 50px;

  ${media.lg`
    font-size: 16px;
    width: auto;
  `}
`;

type DefaultLinkProps = {
  manual?: boolean;
};
const DefaultLink = styled(Link)<DefaultLinkProps>`
  ${UnderlinedLinkTextStyles};
  ${H2MobileStyles};
  font-size: 16px;
  margin: 0;
  color: ${(props: StyledThemePropsType & DefaultLinkProps) =>
    props.manual ? props.theme.colors.errorTextColor : props.theme.colors.darkGray};
  ${({ manual }: DefaultLinkProps) => css`
    ${media.lg`
			font-size: ${manual ? "22px" : "16px"};
		`};
  `};
  ${media.xl`
    font-size: 22px;
    letter-spacing: 0.17px;
    line-height: 32px;
  `};
`;

export {
  CouponContainer,
  VoucherIconContainer,
  VoucherIcon,
  VoucherActivationText,
  ElementToNextRow,
  VoucherTitle,
  SubmitButton,
  DefaultLink,
};
