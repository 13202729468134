import { media } from "styled-bootstrap-grid";
import styled from "styled-components";
import { StyledThemePropsType } from "../../../config/theme/index";
import { DefaultFlexStyles } from "../../styles/index";
import { H3Styles } from "../../typography/index";

const isThemeCovea: boolean = process.env.REACT_APP_THEME_KEY === "COVEA";

const LogoContainer = styled.div`
  ${DefaultFlexStyles};
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99;
  box-shadow: 0 2px 6px 0 rgba(84, 86, 91, 0.1);
  background-color: ${(props: StyledThemePropsType) => props.theme.colors.white};
  height: 60px;
  width: 100%;

  ${media.lg`
    height: 70px;
  `};
`;

const GoBack = styled.div`
  ${DefaultFlexStyles};
  ${H3Styles};
  font-size: 12px;
  color: ${(props: StyledThemePropsType) =>
    isThemeCovea ? props.theme.colors.darkColor : props.theme.colors.primaryTextLight};
  cursor: pointer;
  width: 81px;
  position: absolute;
  top: auto;
  left: 20px;
  ${media.lg`
    font-size: 16px;
    left: 70px;
    ${DefaultFlexStyles};
  `};
`;

const LogoWrapper = styled.button`
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
`;

export { LogoContainer, GoBack, LogoWrapper };
