import React, { FC, useCallback, useContext } from "react";
import { GoBack, LogoContainer, LogoWrapper } from "./elements";
import { useHistory } from "react-router-dom";
import { CustomSvgLogo } from "../../../pages/questionnaire/elements";
import { IconsThemeContext } from "../../../themeProviderHOC";
import { ReactComponent as ReturnIcon} from "../../icons/actions/return_arrow.svg";

type LogoProps = {
  showGoBack: boolean;
  goBack(): void;
};

const Logo: FC<LogoProps> = ({ showGoBack, goBack }) => {
  const { push } = useHistory();
  const ContextIcons = useContext(IconsThemeContext);

  const onClick = useCallback(() => {
    const confirmToLanding = window.confirm("Vous allez quitter le parcours de souscription : êtes vous sur ?");
    if (!confirmToLanding) return;
    push("/");
  }, [push]);

  return (
    <LogoContainer>
      {showGoBack &&
        <GoBack onClick={goBack}>
          <ReturnIcon className="return-icon" />
          <span>Retour</span>
        </GoBack>
      }
      <LogoWrapper onClick={onClick}>
        <CustomSvgLogo icon={ContextIcons.logo} />
      </LogoWrapper>
    </LogoContainer>
  );
};

export default Logo;
