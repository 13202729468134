export enum ExternalLinksOptions {
	ADVICES_INFO = "ADVICES_INFO",
	PRODUCT_INFO = "PRODUCT_INFO",
	SIMPLE_PAGE = "SIMPLE_PAGE",
	PREMIUM_PAGE = "PREMIUM_PAGE",
	CONSULT_LINK = "CONSULT_LINK",
	COVERAGES_SUMMARY = "COVERAGES_SUMMARY",
	WORDPRESS_CONSULTER_LINK = "WORDPRESS_CONSULTER_LINK",
	WORDPRESS_INFORMATION_LINK = "WORDPRESS_INFORMATION_LINK"
}

export type ExternalLinksType = {
	[key in keyof typeof ExternalLinksOptions]: string;
}

export const ExternalLinks:ExternalLinksType = {
	ADVICES_INFO: process.env.REACT_APP_ADVICES_INFO,
	PRODUCT_INFO: process.env.REACT_APP_PRODUCT_INFO,
	SIMPLE_PAGE: process.env.REACT_APP_SIMPLE_PAGE,
	PREMIUM_PAGE: process.env.REACT_APP_PREMIUM_PAGE,
	CONSULT_LINK: process.env.REACT_APP_CONSULT_LINK,
	COVERAGES_SUMMARY: process.env.REACT_APP_COVERAGES_SUMMARY,
	WORDPRESS_CONSULTER_LINK: process.env.REACT_APP_WORDPRESS_CONSULTER_LINK,
	WORDPRESS_INFORMATION_LINK: process.env.REACT_APP_WORDPRESS_INFORMATION_LINK
}

