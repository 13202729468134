import React from "react";
import { CustomIcon } from "../../index";
import { LeftLine, RightLine, StepIndicator, StepIndicatorWrapper, StepText, StepWrapper } from "./elements";

interface StepProps {
  text: string;
  isSelected?: boolean;
  isFirst?: boolean;
  isLast?: boolean;
  currentlySelected?: boolean;
  completed?: boolean;
}

const Step = (props: StepProps) => {
  const { isFirst, isLast, isSelected, text, currentlySelected, completed } = props;
  return (
    <StepWrapper>
      <StepText isSelected={isSelected}>{text}</StepText>
      <LeftLine isSelected={isSelected && !currentlySelected} notShown={isLast} />
      <StepIndicatorWrapper isSelected={isSelected}>
        <StepIndicator>
          {completed ?
            <CustomIcon icon="check" iconColor="white" width="15" height="10" margin="0" />
            : null
          }
        </StepIndicator>
      </StepIndicatorWrapper>
      <RightLine isSelected={isSelected} notShown={isFirst} />
    </StepWrapper>
  );
};

export default Step;
