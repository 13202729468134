import { TariffTitleVariants } from "../../../../api/interfaces";
import { TariffAdvantage } from "./index";

type Advantages = {
  [key in keyof typeof TariffTitleVariants]: TariffAdvantage[]
}
export const advantages: Advantages = {
  "simple": [
    {
      text: `Responsabilité civile`,
      showIcon: true,
    },
    {
      text: "Garantie Défense pénale et Recours suite à accident",
      showIcon: true,
    },
  ],
  "premium": [
    {
      text: "Responsabilité civile",
      showIcon: true,
    },
    {
      text: "Garantie Défense pénale et Recours suite à accident",
      showIcon: true,
    },
    {
      text: "Garantie Protection Corporelle du Conducteur",
      showIcon: true,
    },
  ],
};
