import { media } from "styled-bootstrap-grid";
import styled from "styled-components";
import { StyledThemePropsType } from "../../../../config/theme/index";
import { HexToRgba } from "../../../helpers/index";
import { StepperItemTextHighlightedStyles, StepperItemTextStyles } from "../../../typography/index";

interface LineProps {
  notShown?: boolean;
  isSelected: boolean;
}

interface StepIndicatorWrapperProps {
  isSelected: boolean;
}

const StepWrapper = styled.div`
  display: none;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  position: relative;
  min-width: 70px;

  ${media.lg`
    display: flex;
    min-width: 130px;
  `}

  ${media.xl`
    min-width: 158px;
  `}
`;

const StepText = styled.div`
  ${(props: StepIndicatorWrapperProps) => props.isSelected ? StepperItemTextHighlightedStyles : StepperItemTextStyles};
  font-size: 10px;

  ${media.xl`
    font-size: 12px;
  `};
`;

const StepIndicatorWrapper = styled.div<StepIndicatorWrapperProps & StyledThemePropsType>`
  background-color: ${(props: StepIndicatorWrapperProps & StyledThemePropsType) => {
    const { primaryTextLight, white } = props.theme.colors;

    return props.isSelected ? primaryTextLight : white;
  }};
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 1px;
  border-color: ${(props: StyledThemePropsType) => props.theme.colors.primaryTextLight};
  border-style: dashed;
  margin-top: 7px;
  z-index: 2;
  opacity: ${(props: StepIndicatorWrapperProps & StyledThemePropsType) => props.isSelected ? 1 : 0.5};
`;

const StepIndicator = styled.div`
  background-color: ${(props: StyledThemePropsType) => HexToRgba(props.theme.colors.primaryTextLight, 0.5)};
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LeftLine = styled.div`
  display: ${(props: LineProps) => props.notShown ? "none" : "initial"};
  height: 1px;
  background-color: ${(props: StyledThemePropsType) => props.theme.colors.primaryTextLight};
  opacity: ${(props: LineProps) => props.isSelected ? 1 : 0.5};
  position: absolute;
  width: 43%;
  left: 57%;
  top: 27px;

  ${media.xl`
    top: 30px;
  `};
`;

const RightLine = styled.div`
  display: ${(props: LineProps) => props.notShown ? "none" : "initial"};
  height: 1px;
  background-color: ${(props: StyledThemePropsType) => props.theme.colors.primaryTextLight};
  opacity: ${(props: LineProps) => props.isSelected ? 1 : 0.5};
  position: absolute;
  width: 43%;
  right: 57%;
  top: 27px;

  ${media.xl`
    top: 30px;
  `};
`;

export {
  StepWrapper,
  StepIndicatorWrapper,
  StepIndicator,
  LeftLine,
  RightLine,
  StepText,
};
