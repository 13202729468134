import React, { FC } from "react";
import { CustomIcon } from "../../../shared/elements/index";
import { DefinedIcons } from "../../../shared/types/index";
import { ContactMethodIcon, ContactMethodText, ContactMethodWrapper } from "./elements";

type ContactMethodProps = {
  icon: DefinedIcons;
  text: string;
};

const ContactMethod: FC<ContactMethodProps> = ({ icon, text }) => {
  return (
    <ContactMethodWrapper>
      <ContactMethodIcon>
        <CustomIcon iconColor="darkGray" icon={icon} width="37" height="37" />
      </ContactMethodIcon>
      <ContactMethodText>
        {text}
      </ContactMethodText>
    </ContactMethodWrapper>
  );
};

export default ContactMethod;
