import { add, getDaysInMonth, getUnixTime, isAfter, isBefore } from "date-fns";
import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from "react";
import { useQuery } from "@apollo/react-hooks";

import { Civility } from "../../../../api/interfaces";
import GET_COUNTRIES from "../../../../api/queries/getCountries";
import { DropdownOption } from "../../../../shared/elements/Dropdown";
import { CustomIcon, Datepicker, Dropdown, TextField, TextFieldBottomMargin } from "../../../../shared/elements/index";
import createFullDriversBirthday from "../../../../shared/helpers/fullDriversBirthday";
import { DriverErrors } from "../index";
import { Driver } from "../mocks";
import {
  CloseButton,
  DateLabel,
  DatePickerWrapper,
  ErrorContainer,
  FixedWrapper,
  FormContainer,
  HorizontalWrapper,
  MonthPickerWrapper,
  TextGeographyWrapper,
  YearPickerWrapper,
} from "./elements";

export type DriverNameTitle = {
  title: "primary" | "secondary";
};

type SingleDriverInfoProps = DriverNameTitle & {
  index: number;
  inputsOnChange(field: string, index: number, value: string | Date): void;
  checkAgeValidation(value: string | null): void;
  deleteCurrentDriver(index: number): void;
  driverInfo: Driver;
  driverErrors: DriverErrors;
};

const SingleDriverInfo: FC<SingleDriverInfoProps> = ({
  title,
  index,
  driverInfo,
  driverErrors,
  inputsOnChange,
  checkAgeValidation,
  deleteCurrentDriver,
}) => {
  const actualTitle = {
    primary: "PRÉNOM DU SOUSCRIPTEUR & CONDUCTEUR PRINCIPAL",
    secondary: "Prénom du conducteur supplémentaire",
  };

  const genderOptions = [
    { value: "Madam", label: Civility.Madam },
    { value: "Mr", label: Civility.Mr },
  ];

  const { data: { countries = [] } = {} } = useQuery<{ countries: string[] }>(GET_COUNTRIES);

  const options: DropdownOption[] = countries
    .map((c) => ({ value: c, label: c }))
    .sort((a, b) => {
      if (/France/.test(b.value)) return 1;
      if (/France/.test(a.value)) return -1;
      return a.value.localeCompare(b.value);
    });

  const [countryValue, setCountryValue] = useState<DropdownOption>({
    value: driverInfo.country,
    label: driverInfo.country,
  });

  const onChangeDropDownCountry = useCallback(
    (option) => {
      setCountryValue(option);
      return inputsOnChange("country", index, option.value);
    },
    [inputsOnChange, index]
  );

  const onChange = useCallback(
    (field: string) => (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      return inputsOnChange(field, index, event.target.value);
    },
    [index, inputsOnChange]
  );

  const markCurrentDriver = useCallback(
    (key: number) => () => {
      return deleteCurrentDriver(key);
    },
    [deleteCurrentDriver]
  );

  /**
   * Create one full birthday from three inputs.
   */

  const fullDate = createFullDriversBirthday(driverInfo.day, driverInfo.month, driverInfo.year);

  const [day, setDay] = useState<Date>(driverInfo.day);
  const [month, setMonth] = useState<Date>(driverInfo.month);
  const [year, setYear] = useState<Date>(driverInfo.year);

  const onChangeDate = useCallback(
    (value) => {
      setDay(value);
      return inputsOnChange("day", index, value);
    },
    [inputsOnChange, index]
  );

  const onChangeMonth = useCallback(
    (value) => {
      setMonth(value);
      return inputsOnChange("month", index, value);
    },
    [inputsOnChange, index]
  );
  const onChangeYear = useCallback(
    (value) => {
      setYear(value);
      return inputsOnChange("year", index, value);
    },
    [inputsOnChange, index]
  );

  useEffect(() => {
    if (day && month && year) {
      const possibleDayCount: number = getDaysInMonth(new Date(year.getFullYear(), month.getMonth()));
      if (possibleDayCount < day.getDate()) {
        setDay(fullDate);
        setMonth(fullDate);
      }
    }
  }, [day, month, year, fullDate]);
  /**
   * Variables to show/hide error message depend on cases
   */

  const isSecondDriver: boolean = driverInfo.key > 1;

  const currentlyDay = getUnixTime(new Date());

  const valideMinDateForMainDriver = !isSecondDriver && getUnixTime(add(fullDate, { years: 18 }));
  const valideMinDateForAdditionalDriver = isSecondDriver && getUnixTime(add(fullDate, { years: 14 }));
  const valideMaxDate = getUnixTime(add(fullDate, { years: 75 }));

  const isMinAgeForAdditionalDriver: boolean = isAfter(valideMinDateForAdditionalDriver, currentlyDay);
  const isMinAgeForMainDriver: boolean = isAfter(valideMinDateForMainDriver, currentlyDay);
  const isMaxAge: boolean = isBefore(valideMaxDate, currentlyDay);
  /**
   * Logic related to show the error message per validation year input.
   */
  const isRulesForAdditionalDriver: boolean = useMemo(() => isMinAgeForAdditionalDriver || isMaxAge, [
    isMaxAge,
    isMinAgeForAdditionalDriver,
  ]);
  const isRulesNotForMainDriver: boolean = useMemo(() => isMinAgeForMainDriver || isMaxAge, [
    isMinAgeForMainDriver,
    isMaxAge,
  ]);

  const errorMessageForMainDriver = isMaxAge
    ? "Souscription impossible pour ce conducteur"
    : "Le souscripteur de l'assurance doit être majeur";
  const messageText = !isSecondDriver ? errorMessageForMainDriver : "Souscription impossible pour ce conducteur";

  useEffect(() => {
    return checkAgeValidation(isRulesForAdditionalDriver || isRulesNotForMainDriver ? messageText : null);
  }, [isRulesForAdditionalDriver, isRulesNotForMainDriver, checkAgeValidation, messageText]);

  const [genderValue, setGenderValue] = useState<DropdownOption>({
    value: driverInfo.gender,
    label: Civility[driverInfo.gender],
  });

  const onChangeDropDownGender = useCallback(
    (option) => {
      setGenderValue(option);
      return inputsOnChange("gender", index, option.value);
    },
    [inputsOnChange, index]
  );

  return (
    <FormContainer>
      {isSecondDriver ? (
        <CloseButton onClick={markCurrentDriver(index)}>
          <CustomIcon icon="close" iconColor="crossIconColor" width="100" height="10" />
        </CloseButton>
      ) : null}
      <TextFieldBottomMargin>
        <Dropdown
          isDisabled={false}
          label="ETAT CIVIL"
          placeholder=""
          options={genderOptions}
          onChange={onChangeDropDownGender}
          value={genderValue}
          error={!!driverErrors.gender}
          upperCaseOptions
        />
      </TextFieldBottomMargin>
      <TextFieldBottomMargin>
        <TextField
          initialVisibility={true}
          onChange={onChange("firstName")}
          label={actualTitle[title]}
          error={!!driverErrors.firstName}
          value={driverInfo.firstName}
          capitalize="capitalize"
        />
      </TextFieldBottomMargin>
      <TextFieldBottomMargin>
        <TextField
          initialVisibility={true}
          onChange={onChange("lastName")}
          label="Nom"
          error={!!driverErrors.lastName}
          value={driverInfo.lastName}
          capitalize="uppercase"
        />
      </TextFieldBottomMargin>
      <DateLabel>date de naissance</DateLabel>
      <ErrorContainer>
        <HorizontalWrapper controlMargin={isRulesForAdditionalDriver || isRulesNotForMainDriver}>
          <FixedWrapper>
            <DatePickerWrapper className="hide-calendar">
              <Datepicker
                onChange={(d) => onChangeDate(d)}
                placeholder="jour"
                value={day}
                dateFormat="dd"
                error={!!driverErrors.day}
              />
            </DatePickerWrapper>
            <MonthPickerWrapper className="month-calendar">
              <Datepicker
                onChange={(m) => onChangeMonth(m)}
                placeholder="mois"
                value={month}
                dateFormat="MMMM"
                showMonthYearPicker
                showFullMonthYearPicker
                error={!!driverErrors.month}
              />
            </MonthPickerWrapper>
            <YearPickerWrapper className="hide-calendar">
              <Datepicker
                onChange={(y) => onChangeYear(y)}
                placeholder="année"
                value={year}
                dateFormat="yyyy"
                showYearPicker
                error={
                  !isSecondDriver
                    ? isRulesNotForMainDriver || !!driverErrors.year
                    : isRulesForAdditionalDriver || !!driverErrors.year
                }
              />
            </YearPickerWrapper>
          </FixedWrapper>
        </HorizontalWrapper>
      </ErrorContainer>
      <HorizontalWrapper>
        <TextGeographyWrapper>
          <TextField
            initialVisibility={true}
            onChange={onChange("city")}
            label="Ville de naissance"
            error={!!driverErrors.city}
            value={driverInfo.city}
          />
        </TextGeographyWrapper>
        <TextGeographyWrapper>
          <Dropdown
            isDisabled={false}
            label="Pays de naissance"
            placeholder=""
            options={options}
            onChange={onChangeDropDownCountry}
            value={countryValue}
            error={!!driverErrors.country}
            upperCaseOptions
          />
        </TextGeographyWrapper>
      </HorizontalWrapper>
    </FormContainer>
  );
};

export default SingleDriverInfo;
