import styled from "styled-components";
import { StyledThemePropsType } from "../../../config/theme/index";
import { media } from "styled-bootstrap-grid";
import { InputLabelTextMobileStyles, LabelStyles } from "../../typography/index";

export const DropdownWrapper = styled.div`
  width: 100%;
`;

export const LabelContainer = styled.div`
  margin-bottom: 5px;
  ${InputLabelTextMobileStyles};
  color: ${(props: StyledThemePropsType) => props.theme.colors.primaryText};
  
  ${media.lg`
    ${LabelStyles};
  `};
`;

