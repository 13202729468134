import { useCallback, useEffect, useState, useContext } from "react";
import { useRouteMatch } from "react-router-dom";
import { QuestionnaireContext } from "../../store/context/questionnaire/questionnaire";
/**
 * Props arguments:
 * @currentRoute - / + current sub-route name
 * @nextRoute - / + next sub-route name
 */
type GenerateNextRouteProps = {
	currentRoute: string;
	nextRoute: string;
	prevRoute?: string;
}

type UseRedirectionHookReturnType = [string, string, () => void];

export function useRedirection(options: GenerateNextRouteProps): UseRedirectionHookReturnType {
	const { path } = useRouteMatch();
	const { firstStepCompleted, secondStepCompleted, thirdStepCompleted } = useContext(QuestionnaireContext);
	const [nextRoute, setNextRoute] = useState<string>(path);
	const [prevRoute, setPrevRoute] = useState<string>(path);

	const generateNextRoute = useCallback(() => {
		/**
		 * Extracting pure route address without it's sub-route.
		 */
		const pureRoute = path.split(`/${options.currentRoute}`)[0];
		/**
		 * Combining pure route address with the next, forwarded, sub-route.
		 */
		setNextRoute(`${pureRoute}/${options.nextRoute}`);
	}, [options.currentRoute, options.nextRoute, path]);

	const generatePrevRoute = useCallback(() => {
		/**
		 * Extracting pure route address without it's sub-route.
		 */
		const pureRoute = path.split(`/${options.currentRoute}`)[0];
		/**
		 * Combining pure route address with the prev, forwarded, sub-route.
		 */
		if (options.currentRoute === "insurance" &&
			(!firstStepCompleted || !secondStepCompleted || !thirdStepCompleted )) {
			setPrevRoute("/");
		} else if (options.currentRoute === "insurance") {
			setPrevRoute(`/${options.prevRoute}`);
		} else {
			setPrevRoute(`${pureRoute}/${options.prevRoute}`);
		}
	}, [options.currentRoute, options.prevRoute, path, firstStepCompleted, secondStepCompleted, thirdStepCompleted]);

	/**
	 * Applying above logic to return the most actual state.
	 * That logic was moved to the separate memoized callback to give more flexibility in using this hook.
	 */
	useEffect(() => {
		generateNextRoute();
		generatePrevRoute();
	}, [generateNextRoute, generatePrevRoute]);

	return [nextRoute, prevRoute, generateNextRoute];
}
