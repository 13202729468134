import { media } from "styled-bootstrap-grid";
import styled, { css } from "styled-components";
import { StyledThemePropsType } from "../../../config/theme/index";
import { BodyStyles, SummaryFormHeaderTextStyles, UnderlinedLinkTextStyles } from "../../../shared/typography/index";
import { IconContainer } from "../transport/elements";

const PaymentContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-wrap: wrap;
  margin: 46px 0 0;

  ${(props: StyledThemePropsType) => css`
    ${media.lg`
    flex-direction: row;
    justify-content: center;
		margin-top: 16px;
		background-color: ${props.theme.colors.grayBackground};
  `};
  `};
`;

type PaymentInfoBlockProps = {
  overflow?: "auto" | "visible";
};

const PaymentInfoBlockBackground = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: ${(props: StyledThemePropsType) => props.theme.colors.grayBackground};
  border-radius: 12px;
  margin: 14px 0;
  padding: 15px 18px 26px;
  width: 290px;

  ${media.xl`
    width: unset;
    padding: 0 0 30px;
    margin: 0;
  `};
`;

const PaymentInfoBlockBackgroundForInsurance = styled(PaymentInfoBlockBackground)`
  flex-shrink: 0;
`;

const PaymentInfoBlockBackgroundForTransport = styled(PaymentInfoBlockBackground)`
  flex: 1 0 auto;
`;

const PaymentInfoBlock = styled.div<PaymentInfoBlockProps>`
  width: 290px;
  height: 100%;
  background-color: ${(props: StyledThemePropsType) => props.theme.colors.grayBackground};
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 12px 15px 5px;
  overflow-y: ${(props: PaymentInfoBlockProps) => (props.overflow ? props.overflow : "auto")};

  ${(props: StyledThemePropsType) => css`
    ${media.lg`
				padding: 12px 40px 13px 15px;
				width: 450px;
				background-color: ${props.theme.colors.white};
		`};
  `};
`;

const PaymentBlockTitle = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: 12px;
  ${SummaryFormHeaderTextStyles};
  text-transform: uppercase;
  color: ${(props: StyledThemePropsType) => props.theme.colors.primaryTextLight};
`;

const SeveralPropertiesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  ${media.lg`
    flex-direction: row;
    justify-content: space-between;
  `};
`;

type PropertyWidthWrapperProps = {
  width: number;
};

const PropertyWidthWrapper = styled.div<PropertyWidthWrapperProps>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  ${(props: PropertyWidthWrapperProps) => media.lg`
    width: ${props.width}px;
  `};
`;

const ModifierButton = styled.div`
  background-color: ${(props: StyledThemePropsType) => props.theme.colors.orange};
  border-radius: 10px;
  margin-top: 15px;
  align-self: flex-end;
  ${media.lg`
    position: absolute;
    right: 20px;
    top: 15px;
    margin-top: 0;
    align-self: unset;
  `};
  padding: 0;
  width: 75px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  ${UnderlinedLinkTextStyles};
  line-height: normal;
  font-size: 12px;
  color: ${(props: StyledThemePropsType) => props.theme.colors.white};
`;

const ModifierLink = styled.a`
  ${UnderlinedLinkTextStyles};
  font-size: 12px;
  color: ${(props: StyledThemePropsType) => props.theme.colors.disabled};
  text-decoration: none;

  &:visited {
    color: ${(props: StyledThemePropsType) => props.theme.colors.disabled};
    text-decoration: none;
  }
`;

const CouponWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: ${(props: StyledThemePropsType) => props.theme.colors.secondary};
  border-radius: 5px;
  padding: 8px 11px;
  margin-top: 18px;
`;

const CouponText = styled.div`
  ${BodyStyles};
  text-align: center;
  color: ${(props: StyledThemePropsType) => props.theme.colors.primaryTextLight};
`;

const CouponTextThin = styled(CouponText)`
  font-weight: normal;
  font-size: 12px;
  ${media.lg`
	font-size: 16px;
`};
`;

const SubmitButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 375px;
  margin-top: 28px;
`;

const TwoBLocksColumn = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  background-color: transparent;
  ${(props: StyledThemePropsType) => {
    return media.xl`
      padding: 20px 15px 0;
    `;
  }};
`;

const VehicleIcon = styled(IconContainer)`
  width: 66px;
  height: 93px;
  position: absolute;
  right: 0;
  top: -25%;
  transform: translateY(25%);
  display: block;
  ${media.lg`
    display: none;
  `};
`;

const MessageWrapper = styled.div`
  width: 280px;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 101;
  transform: translate(-50%, -50%);

  ${media.lg`
		position: relative;
		top: 0;
		left: 0;
		z-index: 0;
		transform: translate(0);
		width: 476px;

		display: flex;
		align-items: flex-end;
	`};

  ${media.xl`
		margin-left: 28px;
	`};
`;

const HiddenWrapper = styled.div`
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background-color: ${(props: StyledThemePropsType) => props.theme.colors.darkText};
  z-index: 100;
  ${media.lg`
		display: none;
	`};
`;
const ElementsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${media.xl`
		flex-direction: row;
	`};
`;

const TransferConpoment = styled.br`
  display: block;
  ${media.lg`
	display: none;
	`};
`;

const SummaryWrapperText = styled.div`
  margin-top: 40px;
`;

const SummaryTitle = styled.div`
  font-size: 12px;
  display: inline-block;
  color: ${(props: StyledThemePropsType) => props.theme.colors.gray};
  margin-bottom: 5px;
`;

const SummaryTitleBold = styled(SummaryTitle)`
  font-weight: bold;
  font-size: 16px;
  color: ${(props: StyledThemePropsType) => props.theme.colors.darkText};
`;

const SummaryText = styled.div`
  color: ${(props: StyledThemePropsType) => props.theme.colors.gray};
  font-size: 12px;
  line-height: 1.38;
  letter-spacing: 0.2px;
`;

export {
  PaymentContent,
  PaymentInfoBlockBackground,
  PaymentInfoBlock,
  PaymentBlockTitle,
  SeveralPropertiesContainer,
  PropertyWidthWrapper,
  CouponWrapper,
  CouponText,
  ModifierButton,
  ModifierLink,
  SubmitButtonContainer,
  TwoBLocksColumn,
  VehicleIcon,
  MessageWrapper,
  HiddenWrapper,
  ElementsWrapper,
  CouponTextThin,
  TransferConpoment,
  PaymentInfoBlockBackgroundForInsurance,
  PaymentInfoBlockBackgroundForTransport,
  SummaryWrapperText,
  SummaryTitle,
  SummaryTitleBold,
  SummaryText,
};
