import React, { useCallback, useState, useContext } from "react";
import { media } from "styled-bootstrap-grid";
import styled from "styled-components";
import { StyledThemePropsType } from "../../../config/theme";
import { HexToRgba } from "../../helpers";
import Icon from "../Icon";
import {ReactComponent as Logo} from '../../images/logo.svg';
import { useHistory, useRouteMatch, Link } from "react-router-dom";
import {
  ButtonsWrapper,
  HeaderButton,
  SubscriptionButton,
  BurgerButton,
  MenuWrapper,
  BurgerMenuContainer,
  BurgerCloseButton,
  BurgerMenuButtons,
  BurgerSubscriptionButton,
} from "./elements";
import { IconsThemeContext } from "../../../themeProviderHOC";
import { CustomSvgLogo } from "../../../pages/questionnaire/elements";
import CustomLink from "../Link/index";

interface MenuButtonProps {
  key: number;
  name: string;
  url: string;
}

const Header: React.FC = () => {
  const ContextIcons = useContext(IconsThemeContext);
  const { path } = useRouteMatch();

  const { push } = useHistory();

  const [burgerOpened, setBurgerOpened] = useState(false);

  const changeMenuState = useCallback(() => {
    setBurgerOpened(!burgerOpened);
  }, [burgerOpened, setBurgerOpened]);

  const goTo = useCallback(
    (route: string) => {
      changeMenuState();
      push(route);
    },
    [push, changeMenuState]
  );

  return (
    <Wrapper>
      <HeaderWrapper>
        <Link to='/'>
          <CustomSvgLogo>
            <Logo width={96} height={32}/>
          </CustomSvgLogo>
        </Link>
      </HeaderWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  box-shadow: 0 2px 6px 0 ${(props: StyledThemePropsType) => props.theme.colors.opacityBackground};
  background-color: ${(props: StyledThemePropsType) => props.theme.colors.white};
  position: fixed;
  top: 0;
  z-index: 100;

  ${(props: StyledThemePropsType) => media.xl`
    background-color: ${HexToRgba(props.theme.colors.white, 0.8)};
  `}
`;

const HeaderWrapper = styled.div`
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin: 0 20px;

  ${media.lg`
    height: 80px;
    margin: 0 auto;
    width: 725px;
  `}

  ${media.xl`
    width: 1160px;
  `}
`;

export default Header;
