export enum Steps {
  insurance = "insurance",
  transport = "transport",
  drivers = "drivers",
  address = "address",
  payment = "payment",
  summary = "summary",
  redirection = "redirection",
}

export type StepProps = {
  key: Steps;
  value: string;
};

export type StepsConfig = {
  currentlySelected: number;
  steps: StepProps[];
};

export const stepperMock: StepsConfig = {
  currentlySelected: 1,
  steps: [
    {
      key: Steps.insurance,
      value: "Votre offre",
    },
    {
      key: Steps.transport,
      value: "Votre véhicule",
    },
    {
      key: Steps.drivers,
      value: "Vos données personnelles",
    },
    {
      key: Steps.payment,
      value: "Vos données bancaires",
    },
    {
      key: Steps.summary,
      value: "Paiement",
    },
    {
      key: Steps.redirection,
      value: "Signature",
    },
  ],
};


