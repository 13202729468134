import React, { useCallback, useContext, useEffect, useLayoutEffect, useState, useMemo } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import Slider from "react-slick";
import { useQuery } from "@apollo/react-hooks";
import { useMutation } from "@apollo/client";

import {
  GetProductsQueryResult,
  GetQuestionsQueryResult,
  GetVoucherQueryResult,
  GetVoucherQueryVariables,
  ProductTypeEntity,
  SubmitMutationVoucherOpenedInput,
  SubmitMutationVoucherOpenedResult,
  SubmitMutationVoucherOpenedVariables,
} from "../../../api/interfaces/index";
import SUBMIT_VOUCHER_OPENED from "../../../api/mutations/submitVoucherOpened";

import GET_PRODUCTS from "../../../api/queries/getProducts";
import GET_QUESTIONS from "../../../api/queries/getQuestions";
import { Loader } from "../../../shared/elements/Loader/index";
import { useRedirection } from "../../../shared/helpers/index";
import { QuestionnaireContext, QuestionnaireDate } from "../../../store/context/questionnaire/questionnaire";
import { PersonalTransportContext } from "../../../store/context/subscription/transport";
import { IconsThemeContext, selectedTheme } from "../../../themeProviderHOC";
import { Vehicles } from "../configuration/vehicles";
import { ContentHeader, CustomSvg } from "../elements";
import { RouteParams } from "../types";
import { SliderContainer } from "./elements";
import sliderSettings from "./sliderSettings";
import VehicleCard from "./VehicleCard";
import GET_VOUCHER from "../../../api/queries/getVoucher";
import { AddressContext } from "../../../store/context/subscription/address";
import { PaymentContext } from "../../../store/context/subscription/payment";
import { RedirectionContext } from "../../../store/context/subscription/redirection";

// Prevents slick-slider width 0 issue
const defaultProducts: ProductTypeEntity[] = Array.from({ length: 5 }, () => ({
  id: 0,
  name: Vehicles.trottinette,
  brands: [
    {
      id: 0,
      name: "noname",
    },
  ],
}));

const Step1 = () => {
  useLayoutEffect(() => window.scrollTo({ top: 0, behavior: "smooth" }), []);
  const { path } = useRouteMatch<RouteParams>();
  const { push, location } = useHistory();
  const modelIndex = Number(path.split("/questionnaire/step")[1]);
  const [nextRoute] = useRedirection({ currentRoute: "step1", nextRoute: "step2" });
  const stepRedirection = useCallback(() => setTimeout(() => push(nextRoute), 500), [nextRoute, push]);
  const ContextIcons = useContext(IconsThemeContext);
  const { savePersonalTransportInfo, ...other } = useContext(PersonalTransportContext);
  const { saveAddressInfo, ...addressParams } = useContext(AddressContext);

  const { clearPersistedStorage } = useContext(RedirectionContext);
  const { savePaymentInfo, ...paymentParams } = useContext(PaymentContext);

  const voucher = new URLSearchParams(location.search).get("voucherId");
  const oldVouncher = localStorage.getItem("voucher");

  if (voucher) clearPersistedStorage();
  // if (oldVouncher && voucher && oldVouncher !== voucher) clearPersistedStorage();
  localStorage.setItem("voucher", voucher);
  const { data, loading: loadingVoucher } = useQuery<GetVoucherQueryResult, GetVoucherQueryVariables>(GET_VOUCHER, {
    variables: { voucher },
    fetchPolicy: "cache-and-network",
    skip: !voucher,
  });
  const [state, setState] = useState({
    stateLoading: false,
  });

  /**
   * Logic related to the storing page-data in the state.
   */

  const { data: getProducts } = useQuery<GetProductsQueryResult>(GET_PRODUCTS);
  const options = useMemo(
    () =>
      (getProducts?.products || defaultProducts).map((entity) => {
        const name = entity.name?.split(" ").shift()?.toLowerCase() as Vehicles;
        if (!Vehicles[name]) throw new Error(`Invalid vehicle name ${name}`);
        return {
          ...entity,
          name,
          isSelected: false,
          icon: <CustomSvg icon={ContextIcons[name]} />,
        };
      }),
    [ContextIcons, getProducts]
  );

  const { saveQuestionnaireInfo, ...questionnaireContextInfo } = useContext(QuestionnaireContext);

  const [questionnaireInfo] = useState<QuestionnaireDate>({ ...questionnaireContextInfo });

  const { loading } = useQuery<GetQuestionsQueryResult>(GET_QUESTIONS, { fetchPolicy: "cache-and-network" });

  useEffect(() => {
    document.head.appendChild(
      document.createElement("style")
    ).innerHTML = `button:before {color: ${selectedTheme.colors.primaryText} !important;}`;
  }, []);

  const [submitMutationVoucherOpened] = useMutation<
    SubmitMutationVoucherOpenedResult,
    SubmitMutationVoucherOpenedVariables
  >(SUBMIT_VOUCHER_OPENED);

  const mutationVoucher: SubmitMutationVoucherOpenedInput = {
    voucher,
  };

  const voucherOpenedTrack = useCallback(async () => {
    await submitMutationVoucherOpened({
      variables: {
        input: mutationVoucher,
      },
    })
      .then(({ data: { voucherOpenedTrack: voucherOpenedTrackData } }) => {
        if (voucherOpenedTrackData) {
          if (voucherOpenedTrackData.voucher) {
            console.log(voucherOpenedTrackData.voucher === voucher);
          }
        }
      })
      .catch((e) => {
        const gqlError = e.graphQLErrors ? e.graphQLErrors[0] : undefined;
        const message = gqlError?.extensions?.error;
        const validationMessage = e.message
          ? e.message
          : "Votre coupon a déjà été utilisé ou n’est plus valide, pour plus d’information rendez-vous sur notre FAQ.";
        alert(`${message ? message : validationMessage}`);
      });
  }, [voucher, mutationVoucher, submitMutationVoucherOpened]);

  useEffect(() => {
    if (voucher) {
      clearPersistedStorage();
      voucherOpenedTrack();
    }
    return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [voucher]);

  const onVehicleSelect = useCallback(
    (id: number, voucherApplied: boolean = false) => {
      /**
       * Finding picked vehicle and setting it as the only one selected.
       */

      const option = options.find((opt) => +opt.id === +id);
      if (!option) return;

      options.forEach((p) => (p.isSelected = false));
      option.isSelected = true;

      /**
       * Setting current step { [completeVariable]: [true] }.
       */
      const contextDataMapping = {
        1: "firstStepCompleted",
        2: "secondStepCompleted",
        3: "thirdStepCompleted",
      };
      const completeStep = { ...questionnaireInfo };
      completeStep[contextDataMapping[modelIndex]] = true;

      /**
       * Storing step-date to the context..
       */
      saveQuestionnaireInfo({ ...completeStep, vehicle: option.name, vehicleName: option.name });
      if (!voucherApplied) savePersonalTransportInfo({ ...other, typeName: Vehicles[option.name], type: option.id });

      /**
       * Smooth redirection to the next step.
       */
      stepRedirection();
    },
    [modelIndex, options, other, questionnaireInfo, savePersonalTransportInfo, saveQuestionnaireInfo, stepRedirection]
  );

  useEffect(() => {
    if (loadingVoucher || state?.stateLoading || !data || !getProducts?.products) return;
    const {
      productBrandId,
      productModel,
      productSerial,
      productTypeId,
      email,
      mobile,
      lastName,
      firstName,
    } = data.voucher;

    setState({ ...state, stateLoading: true });
    console.log("productSerial ", productSerial);
    savePersonalTransportInfo({
      ...other,
      type: productTypeId,
      brand: productBrandId,
      model: productModel,
      serial: productSerial,
    });
    saveAddressInfo({
      ...addressParams,
      email,
      phone: `0${mobile}`.replace(/\d{2}(?=.)/g, "$& "),
    });
    savePaymentInfo({
      ...paymentParams,
      discount: null,
      voucher,
      userName: firstName ? `${lastName} ${firstName}` : "",
      firstName: firstName || "",
      lastName: lastName || "",
    });
    onVehicleSelect(productTypeId, true);
  }, [
    addressParams,
    data,
    getProducts,
    loadingVoucher,
    onVehicleSelect,
    other,
    paymentParams,
    saveAddressInfo,
    savePaymentInfo,
    savePersonalTransportInfo,
    state,
    voucher,
  ]);

  if (loading) {
    return <Loader isLoading={loading} />;
  }

  return (
    <>
      <ContentHeader>Quel véhicule électrique possédez-vous ?</ContentHeader>
      <SliderContainer>
        <Slider {...sliderSettings}>
          {options.map((veh) => (
            <VehicleCard
              onClick={onVehicleSelect}
              isSelected={veh.isSelected}
              key={veh.id}
              id={veh.id}
              icon={veh.icon}
              name={veh.name}
            />
          ))}
        </Slider>
      </SliderContainer>
    </>
  );
};

export default Step1;
