import React from "react";
import { CustomIcon } from "../index";
import { FooterLink, FooterNavContent, FooterNavWrapper, LinkDivider, SocialLinks } from "./elements";

const Footer = () => {
  return (
    <FooterNavWrapper>
      <FooterNavContent>
        {/* TODO: Revisit to array mapping. */}
        <FooterLink
          href="https://docs.assur-link.com/wp-content/uploads/2022/06/protection-des-donnees-personnelles-site-RC-EDPM-MAJ-Owen-30-5-22.pdf"
          target="_blank"
        >
          Conditions Générales d’Utilisation
        </FooterLink>
        <LinkDivider />
        <FooterLink
          href="https://docs.assur-link.com/wp-content/uploads/2022/06/Mentions-legales-pour-site-souscription-assur-link-com-ref-ML-assur-link-com-MAJ-Owen-30-5-2022.pdf"
          target="_blank"
        >
          Mentions légales
        </FooterLink>
        <LinkDivider />
        <FooterLink
          href="https://docs.assur-link.com/wp-content/uploads/2022/06/CGU-site-de-souscription-AssurLink-nom-Owen-MAJ-30-5-22.pdf"
          target="_blank"
        >
          Politique de protection des données personnelles
        </FooterLink>
        <LinkDivider />
        <SocialLinks>
          <FooterLink href="https://www.linkedin.com/company/assurlink/" target="_blank">
            <CustomIcon iconColor="white" icon="linkedIn" height="16" width="16" />
          </FooterLink>
        </SocialLinks>
      </FooterNavContent>
    </FooterNavWrapper>
  );
};

export default Footer;
