import React, { FC } from "react";
import styled from "styled-components";
import { StyledThemePropsType } from "../../../../config/theme/index";
import { DefinedColors } from "../../../../shared/types/index";
import { InputValueTextMobileStyles } from "../../../../shared/typography/index";

type TabContainerProps = {
  textColor: DefinedColors;
	backgroundColor: DefinedColors;
	opacity: string;
};

const TabContainer = styled.div<TabContainerProps>`
  ${InputValueTextMobileStyles};
  letter-spacing: 0.25px;
  color: ${(props: StyledThemePropsType & TabContainerProps) => props.theme.colors[props.textColor]};
  background-color: ${(props: StyledThemePropsType & TabContainerProps) => props.theme.colors[props.backgroundColor]};
  border-radius: 16px;
  padding: 7px 12px;
  opacity: ${(props: TabContainerProps) => props.opacity};
  flex: 1 1 auto;
	cursor: pointer;
`;

type TabProps = {
  activeTariff: string,
  tabTariff: string,
  onClick(value: string): void;
};

const Tab: FC<TabProps> = ({ activeTariff, tabTariff, onClick }) => {
  const active = (activeTariff === tabTariff);
  const backgroundColor: DefinedColors = active ? "primaryTextLight" : "white";
  const textColor: DefinedColors = active ? "white" : "gray";
	const opacity = active ? "1" : "0.4";
  let tariff = tabTariff;

  // fix of regular x simple naming
  if (tabTariff.toLowerCase() === 'regular') {
    tariff = 'SIMPLE';
  }

  return (
			<TabContainer onClick={() => onClick(tabTariff)} opacity={opacity} textColor={textColor} backgroundColor={backgroundColor}>
      Formule <b>{tariff}</b>
    </TabContainer>
  );
};

export default Tab;
