import React from "react";
import { TariffTitleVariants } from "../../../api/interfaces";
import { RedirectionFields } from "../../../pages/subscription/redirection/index";
import { CompletedStep } from "../../index";

export type RedirectionInfoData = CompletedStep & RedirectionFields & {
  redirectionURL: string;
};

type RedirectionContext = RedirectionInfoData & {
  saveRedirectionInfo(modifiedData: RedirectionInfoData): void;
  clearPersistedStorage(): void;
};

export const initialRedirectionInfoData: RedirectionFields =  {
  tariff: TariffTitleVariants.simple,
  userName: "Prénom Nom",
  monthPrice: "7,45",
  vehicle: "Trottinette électrique",
  startData: "xx/xx/xxxx",
};

export const RedirectionContext = React.createContext<RedirectionContext>({
  ...initialRedirectionInfoData,
  completed: false,
  redirectionURL: "",
  saveRedirectionInfo(modifiedData: RedirectionInfoData): RedirectionInfoData {
    return modifiedData;
  },
  clearPersistedStorage() {
    return localStorage.clear();
  },
});

export const RedirectionContextProvider = RedirectionContext.Provider;
