import React, { ChangeEvent, FC } from "react";
import styled from "styled-components";
import { StyledThemePropsType } from "../../../config/theme";

export type CustomCheckBoxProps = {
  onChange(evt: ChangeEvent<HTMLInputElement>): void;
  checked: boolean;
  disabled?: boolean;
};

const CheckBox = styled.input<CustomCheckBoxProps & StyledThemePropsType & { icon: string }>`
  display: none;

  :checked {
    + span {
      border: 1px solid ${(props: StyledThemePropsType) => props.theme.colors.primaryText};
    }

    + span::before {
      display: block;
      content: url("${(props: { icon: string } & StyledThemePropsType) => props.theme.icons[props.icon]}");
      position: absolute;
      top: -7px;
      left: -8px;
      font-size: 23px;
      color: ${(props: StyledThemePropsType) => props.theme.colors.primaryText};
      transform: scale(0.5);
    }
  }
`;

const CustomCheckBox: FC<CustomCheckBoxProps> = ({ onChange, checked, disabled }) => (
    <CheckBox type="checkbox" icon="inputCheck" checked={checked} onChange={onChange} disabled={disabled} />
);

export default CustomCheckBox;
