import { InterestsCardProps } from "./index";

export const interests: InterestsCardProps[] = [
  {
    title: "Assurance Mobile",
    description: "Protéger son portable contre les risques de casse et de vol",
    icon: "mobileInterest",
    buttonText: "Bientôt disponible",
  },
  {
    title: "Assurance Voiture",
    description: "L’assurance auto pour protéger votre voiture des conséquences d’un accident",
    icon: "voitureInterest",
    buttonText: "Bientôt disponible",
  },
  {
    title: "Assurance Habitation",
    description: "Assurer son toit contre tous les risques de dégâts causés par vous, vos proches et vos voisins",
    icon: "habitationInterest",
    buttonText: "Bientôt disponible",
  },
];
