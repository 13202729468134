import gql from "graphql-tag";

const GET_PLANS = gql`
  query getPlans($voucher: String) {
		plans(voucher:$voucher) {
			regular {
				id
				monthlyPrice
				yearlyPrice
				title
				type
				discount {
					text
					discountedMonths
					initialPayment
					percentage
					validityMonths
					monthsToPay
					type
					isPrepaid
				}
			}
			premium {
				id
				monthlyPrice
				yearlyPrice
				title
				type
				discount {
					text
					discountedMonths
					initialPayment
					percentage
					validityMonths
					monthsToPay
					type
					isPrepaid
				}
			}
			voucher {
				id
				firstName
				lastName
				mobile
				email
				productTypeId
				productBrandId
				productModel
				productSerial
				discount
			}
		}
  }
`;

export default GET_PLANS;
