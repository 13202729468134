import { media } from "styled-bootstrap-grid";
import styled from "styled-components";
import { StyledThemePropsType } from "../../../config/theme/index";
import { ButtonContainer } from "../../../shared/elements/Button/elements";
import { DefaultFlexStyles } from "../../../shared/styles/index";
import {
  ButtonTextDesktopStyles,
  H3PreFooterStyles,
  InputValueTextDesktopStyles,
} from "../../../shared/typography/index";
import { QuizStepTitle } from "../elements";

const ContentWrapper = styled.div`
  ${DefaultFlexStyles};
  flex-direction: column;
  border-radius: 12px;
  box-shadow: 0 2px 34px 0 rgba(29, 37, 45, 0.15);
  border: solid 0.5px ${(props: StyledThemePropsType) => props.theme.colors.borderLight};
  background-color: ${(props: StyledThemePropsType) => props.theme.colors.white};
  margin: 16px auto 67px;
  padding: 17px 27px 40px;

  ${media.lg`
    padding: 55px 125px 77px;
    margin: 74px auto 40px;
  `};
`;

const IconWrapper = styled.div`
  ${DefaultFlexStyles};
  margin-bottom: 21px;

  ${media.lg`
    margin-bottom: 42px
  `};
`;

const InfoText = styled.div`
  ${InputValueTextDesktopStyles};
  font-size: 14px;
  color: ${(props: StyledThemePropsType) => props.theme.colors.gray};
  text-align: center;
  text-transform: uppercase;
  ${media.lg`
    font-size: 16px;
    max-width: 350px;
  `};
`;

const InfoTextFormuleName = styled(InfoText)`
  text-transform: capitalize;
`;

const MonthPriceText = styled.div`
  ${H3PreFooterStyles};
  font-size: 16px;
  margin-top: 15px;
  text-align: center;
  width: 80%;
  ${media.lg`
    font-size: 18px;
    margin-top: 15px;
  `}
  ${media.xl`
		width: 100%;
  `}
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 280px;
  margin: 0 auto;

  ${media.lg`
    margin: 0;
    width: 361px;
    align-self: center;
  `};
`;

const SubmitButton = styled(ButtonContainer)`
  font-size: 14px;
  letter-spacing: 0.5px;

  ${media.xl`
    font-size: 16px;
  `};
`;

const InformedText = styled.div`
  text-align: center;
  max-width: 350px;
  margin-top: 15px;
  font-size: 12px;
	color: ${(props: StyledThemePropsType) => props.theme.colors.highlightedText};
	line-height: 1.33;
`;

type RedirectionLinkProps = {
  disabled?: boolean;
};

export const RedirectionLink = styled.a<RedirectionLinkProps>`
  ${ButtonTextDesktopStyles};
  text-decoration: none;
  width: 100%;
  font-size: 14px;
  letter-spacing: 0.5px;
  color: ${(props: StyledThemePropsType) => props.theme.colors.white};
  pointer-events: ${({ disabled }: RedirectionLinkProps) => (disabled ? "none" : "all")};

  ${media.xl`
    font-size: 16px;
  `};
`;

export const TopMargin = styled(QuizStepTitle)`
  margin-top: 87px;

  ${media.lg`
    margin-top: 0px;
	`};

  ${media.xl`
		text-align: center;
		max-width: 829px;
		margin: 55px auto 0;
  `};
`;

export {
  ContentWrapper,
  IconWrapper,
  InfoText,
  MonthPriceText,
  ButtonWrapper,
  SubmitButton,
  InfoTextFormuleName,
  InformedText,
};
