import styled from "styled-components";
import { StyledThemePropsType } from "../../../../../config/theme";
import { HexToRgba } from "../../../../../shared/helpers";
import { BottomSingleTextDesktopStyles, H2Styles, H3Styles } from "../../../../../shared/typography";

export const CardWrapper = styled.div`
  width: 280px;
`;

export const Card = styled.div`
  width: 280px;
  height: 497px;
  border-radius: 5px;
  background-color: ${(props: StyledThemePropsType) => props.theme.colors.container};
  padding: 21px 16px 24px;
  box-sizing: border-box;
`;

export const CardButton = styled.button`
  ${H3Styles};
  display: block;
  font-size: 14px;
  font-weight: bold;
  line-height: 22px;
  letter-spacing: 0.5px;
  background-color: ${(props: StyledThemePropsType) => props.theme.colors.primaryDarkButton};
  color: ${(props: StyledThemePropsType) => props.theme.colors.white};
  box-shadow: 0 2px 34px 0 ${(props: StyledThemePropsType) => props.theme.colors.buttonShadow1};
  width: 280px;
  height: 50px;
  border: none;
  margin: 34px 0 30px;
  cursor: pointer;
  padding: 0 35px;
  box-sizing: border-box;

  &:hover {
    background-color: ${(props: StyledThemePropsType) => props.theme.colors.primaryDarkButtonHover};
  }
  &:active {
    background-color: ${(props: StyledThemePropsType) => props.theme.colors.primaryDarkButtonActive};
  }
  &:disabled {
    background-color: ${(props: StyledThemePropsType) => props.theme.colors.primaryDarkButtonDisabled};
  }
`;

export const CardTitle = styled.div`
  ${H3Styles};
  color: ${(props: StyledThemePropsType) => props.theme.colors.textStyleMain};
  font-size: 18px;
  line-height: 27px;
  margin-left: 13px;
  letter-spacing: 0.2px;
`;

export const PlanName = styled.div`
  ${H3Styles};
  color: ${(props: StyledThemePropsType) => props.theme.colors.primaryTextLight};
  font-size: 18px;
  line-height: 27px;
  display: inline;
`;

export const PriceWrapper = styled.div`
  margin: 18px 0 0 13px;
  display: flex;
  align-items: flex-start;
`;

export const PriceText = styled.div`
  ${H2Styles};
  font-size: 48px;
  letter-spacing: 0.58px;
  line-height: 53px;
  color: ${(props: StyledThemePropsType) => props.theme.colors.primaryTextLight};
`;

export const EuroText = styled.div`
  ${H2Styles};
  font-size: 30px;
  letter-spacing: 0.37px;
  color: ${(props: StyledThemePropsType) => props.theme.colors.primaryTextLight};
  line-height: 1;
  margin-top: 5px;
`;

export const MonthText = styled.div`
  ${BottomSingleTextDesktopStyles};
  font-size: 25px;
  letter-spacing: 0.5px;
  margin: 21px 0 0 4px;
  color: ${(props: StyledThemePropsType) => props.theme.colors.primaryTextLight};
`;

export const DescriptionText = styled.div`
  ${BottomSingleTextDesktopStyles};
  font-size: 16px;
  line-height: 17px;
  color: ${(props: StyledThemePropsType) => props.theme.colors.primaryTextLight};
  letter-spacing: 1px;
  margin: 7px 0 0 14px;
`;

export const FeaturesWrapper = styled.div`
  margin: 24px 0;
`;

export interface FeatureWrapperProps {
  isLast?: boolean;
}

export const FeatureWrapper = styled.div<FeatureWrapperProps>`
  display: flex;
  margin-bottom: ${({ isLast }: FeatureWrapperProps) => (isLast ? "0" : "30px")};
`;

export interface FeatureTextProps {
  active: boolean;
}

export const FeatureText = styled.div<FeatureTextProps>`
  ${BottomSingleTextDesktopStyles};
  width: 228px;
  font-size: 17px;
  line-height: 27px;
  letter-spacing: 0.2px;
  color: ${({ active, theme }: StyledThemePropsType & FeatureTextProps) =>
    active ? theme.colors.textStyleMain : HexToRgba(theme.colors.textStyleMain, 0.4)};
  margin-left: 8px;
`;
