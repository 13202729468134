import { media } from "styled-bootstrap-grid";
import styled from "styled-components";
import { StyledThemePropsType } from "../../../../config/theme/index";
import { Button } from "../../../../shared/elements";
import { DefinedIcons } from "../../../../shared/types/index";

export const InterestCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 280px;
  margin: 0 auto;

  ${media.lg`
    width: 350px;
  `};
`;

export const InterestCardBackground = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  border-radius: 3px;
  background-color: ${(props: StyledThemePropsType) => props.theme.colors.backgroundDark};
  padding: 30px 11px;
  margin-bottom: 10px;
  min-height: 355px;
  ${media.lg`
    padding: 47px 28px 24px;
    margin-bottom: 22px;
  `};
`;

type IconContainerProps = {
  icon: DefinedIcons;
};

export const IconContainer = styled.div<IconContainerProps>`
  background: url(${(props: IconContainerProps & StyledThemePropsType) => props.theme.icons[props.icon]}) bottom
    no-repeat;
  width: 206px;
  height: 155px;
  margin: 0 auto;
`;

export const InterestTitle = styled.div`
  color: ${(props: StyledThemePropsType) => props.theme.colors.gray};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  font-size: 18px;
  line-height: 1.5;
  letter-spacing: 0.2px;
  text-align: center;
  margin: 21px 0 13px;

  ${media.lg`
    font-size: 20px;
    line-height: 1.35;
  `};
`;

export const InterestDescription = styled.div`
  color: ${(props: StyledThemePropsType) => props.theme.colors.gray};
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: 0.2px;
  text-align: center;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 320px;
  ${media.lg`
    margin-bottom: 32px;
    width: 210px;
  `};
  ${media.xl`
      margin-bottom: 0;
  `};
`;

export const InterestButton = styled(Button)`
  background-color: ${(props: StyledThemePropsType) => props.theme.colors.primaryDarkButton};
  border-radius: 8px;
  
	&:hover {
		background-color: ${(props: StyledThemePropsType) => props.theme.colors.primaryDarkButtonHover};
	}
  &:active {
    background-color: ${(props: StyledThemePropsType) => props.theme.colors.primaryDarkButtonActive};
  }
  &:disabled {
    background-color: ${(props: StyledThemePropsType) => props.theme.colors.primaryDarkButtonDisabled};
  }
`;
