import React, { useCallback, useContext, useLayoutEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { CustomCheckBox, CustomIcon, Message } from "../../../shared/elements/index";
import { QuestionnaireContext } from "../../../store/context/questionnaire/questionnaire";
import {
  ButtonWrapper,
  SummaryContainer,
  SummaryItemText,
  SummaryItemWrapper,
  SummaryTransportIcon,
  AgreementText,
  AgreementWrapper,
  SubmitButton,
  ElementsStep5Wrapper,
  CustomQuestionnaireSpan,
} from "./elements";
import { HiddenWrapper } from "../elements";
import { mockedSummaryArray } from "./mock";
import { MessageWrapper } from "../../subscription/transport/elements";
import { FifthStepError } from "../configuration";
const Step4 = () => {
  const [agree, setAgree] = useState(false);
  const [showError, setShowError] = useState<boolean>(false);

  useLayoutEffect(() => window.scrollTo({ top: 0, behavior: "smooth" }), []);
  /**
   * Logic related to the routing actions.
   */
  const { push } = useHistory();

  const { vehicle, saveQuestionnaireInfo, vehicleName, ...questionnaireInfo } = useContext(QuestionnaireContext);
  const [errorFlag, setErrorFlag] = useState(false);
  const onCheck = useCallback(() => {
    setAgree(!agree);
    setErrorFlag(false);
  }, [setAgree, agree, setErrorFlag]);

  const savePageProgress = useCallback(() => {
    if (agree) {
      saveQuestionnaireInfo({ ...questionnaireInfo, vehicle, vehicleName, summaryStepCompleted: true });
      push("/subscription");
    }
    setErrorFlag(true);
    setShowError(true);
  }, [push, agree, questionnaireInfo, saveQuestionnaireInfo, vehicle, vehicleName]);

  const onCloseError = useCallback(() => setShowError(false), []);
  const lowerCaseVehicleName = vehicleName.charAt(0).toLowerCase() + vehicleName.slice(1);
  const updatedVehicleName =
    vehicleName === "trottinette"
      ? "trottinette électrique"
      : vehicleName === "skateboard"
      ? "e-skateboard"
      : lowerCaseVehicleName;
  const summaryArray = [`Vous possédez un ${updatedVehicleName}.`, ...mockedSummaryArray];
  return (
    <SummaryContainer>
      <SummaryTransportIcon icon={vehicle} />
      {summaryArray.map((questionSummary, index) => (
        <SummaryItemWrapper key={index}>
          <CustomIcon icon="check" iconColor="primaryLight" margin="0 12px 0 0" height="15" width="21" />
          <SummaryItemText>{questionSummary}</SummaryItemText>
        </SummaryItemWrapper>
      ))}

      <AgreementWrapper>
        <label>
          <CustomCheckBox disabled={false} checked={agree} onChange={onCheck} />
          <CustomQuestionnaireSpan error={errorFlag} />
        </label>
        <AgreementText onClick={onCheck} error={errorFlag}>
          Je certifie que ces réponses sont exactes et confirme mon souhait de souscrire une garantie responsabilité
          civile pour l'utilisation de mon engin de déplacement personnel motorisé.
        </AgreementText>
      </AgreementWrapper>
      <ElementsStep5Wrapper>
        <ButtonWrapper>
          <SubmitButton isDisabled={!agree} onClick={savePageProgress}>
            Choisir ma garantie
          </SubmitButton>
        </ButtonWrapper>

        {showError && (
          <>
            <HiddenWrapper />
            <MessageWrapper>
              <Message
                close={onCloseError}
                icon="error"
                iconColor="error"
                backgroundColor="errorBackground"
                textColor="error"
                message={FifthStepError()}
              />
            </MessageWrapper>
          </>
        )}
      </ElementsStep5Wrapper>
    </SummaryContainer>
  );
};

export default Step4;
