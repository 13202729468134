import React, { FC } from "react";
import { DefinedIcons } from "../../../../shared/types/index";
import {
  ButtonWrapper,
  IconContainer,
  InterestCardBackground,
  InterestCardContainer,
  InterestDescription,
  InterestTitle,
  InterestButton,
} from "./elements";

export type InterestsCardProps = {
  title: string;
  description: string;
  icon: DefinedIcons;
  buttonText: string;
};

const InterestsCard: FC<InterestsCardProps> = (
  {
    title,
    description,
    icon,
    buttonText,
  },
) => {
  return (
    <InterestCardContainer>
      <InterestCardBackground>
        <IconContainer icon={icon} />
        <InterestTitle>
          {title}
        </InterestTitle>
        <InterestDescription>
          {description}
        </InterestDescription>
      </InterestCardBackground>
      <ButtonWrapper>
        <InterestButton variant="dark" size="l" active={true} disabled={false} onClick={()=>{}}>
          {buttonText}
        </InterestButton>
      </ButtonWrapper>
    </InterestCardContainer>
  );
};

export default InterestsCard;
