import { useMutation } from "@apollo/react-hooks";
import { format, isAfter } from "date-fns";
import React, { useCallback, useContext, useLayoutEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  RequestedInsurancePlanType,
  SubmitMutationInput,
  SubmitMutationResult,
  SubmitMutationVariables,
  TariffTitleVariants,
} from "../../../api/interfaces/index";
import SUBMIT from "../../../api/mutations/submit";
import Footer from "../../../shared/elements/Footer";
import { CustomIcon, Logo, Stepper } from "../../../shared/elements/index";
import { stepperMock } from "../../../shared/elements/Stepper/mock";
import createFullDriversBirthday from "../../../shared/helpers/fullDriversBirthday";
import { useRedirection } from "../../../shared/helpers/useRedirection";
import Page, { Container, PageContainer } from "../../../shared/layout/Page/index";
import { AddressContext } from "../../../store/context/subscription/address";
import { DriversContext } from "../../../store/context/subscription/drivers";
import { InsuranceContext } from "../../../store/context/subscription/insurance";
import { PaymentContext, PaymentFrequency, Tariff } from "../../../store/context/subscription/payment";
import { RedirectionContext } from "../../../store/context/subscription/redirection";
import { SummaryContext } from "../../../store/context/subscription/summary";
import { PersonalTransportContext } from "../../../store/context/subscription/transport";
import { QuizStepWrapper } from "../elements";
import { InsuranceTariff } from "../insurance/TariffCard/index";
import { PaymentInfoFields } from "../payment/index";
import {
  ButtonWrapper,
  ContentWrapper,
  IconWrapper,
  InfoText,
  InfoTextFormuleName,
  MonthPriceText,
  SubmitButton,
  TopMargin,
  InformedText,
} from "./elements";

export type RedirectionFields = {
  tariff: TariffTitleVariants;
  userName: string;
  monthPrice: string;
  vehicle: string;
  startData: string | Date;
};

const Redirection = () => {
  useLayoutEffect(() => window.scrollTo({ top: 0, behavior: "smooth" }), []);
  /**
   * Logic related to the routing actions.
   */
  const { push } = useHistory();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [nextRoute, prevRoute] = useRedirection({
    currentRoute: "redirection",
    nextRoute: "finish", // unnecessary
    prevRoute: "summary",
  });
  const back = useCallback(() => push(prevRoute), [prevRoute, push]);

  /**
   * Logic related to the page submitting.
   */
  const { type, brand, model, serial } = useContext(PersonalTransportContext);
  const { drivers } = useContext(DriversContext);
  const { address, city, email, information, phone, postalCode, subscription } = useContext(AddressContext);
  const { regular, premium } = useContext(InsuranceContext);
  const insuranceState: InsuranceTariff = regular.selected ? regular : premium;
  const {
    bankingСonsent,
    iban,
    startDate,
    tariff,
    userName,
    frequency,
    discount,
    voucher,
    formula,
    savePaymentInfo,
  } = useContext(PaymentContext);
  const [paymentState] = useState<PaymentInfoFields>({
    bankingСonsent,
    iban,
    startDate,
    tariff,
    userName,
    frequency,
    discount,
    voucher,
    formula,
  });
  const { completeSummaryInfo } = useContext(SummaryContext);
  const { saveRedirectionInfo } = useContext(RedirectionContext);

  /**
   * This value will be initialized after successfully BE response.
   */
  const [redirectionFields] = useState<RedirectionFields>();

  /**
   * Logic related to the API requests.
   */
  const [submitMutation] = useMutation<SubmitMutationResult, SubmitMutationVariables>(SUBMIT);

  // TODO: Map payment type for backend (? IDK the issue V.Sokolov)
  const planId: number = +insuranceState.planId;
  const paymentType =
    paymentState.frequency === PaymentFrequency.monthly
      ? RequestedInsurancePlanType.MONTHLY
      : RequestedInsurancePlanType.YEARLY;

  const mutationInput: SubmitMutationInput = {
    email,
    city,
    address,
    information,
    voucher,
    planId,
    productSerial: serial,
    productModel: model,
    productTypeId: Number(type),
    productBrandId: Number(brand),
    type: paymentType,
    drivers: drivers.map(({ firstName, lastName, city: birthPlace, day, month, year }) => {
      const fullDateString = format(new Date(createFullDriversBirthday(day, month, year)), "MM/dd/yyyy");
      return { firstName, lastName, birthPlace, birthDay: fullDateString };
    }),
    accountOwner: userName,
    mobile: phone.toString(),
    firstName: drivers[0].firstName,
    lastName: drivers[0].lastName,
    postalCode: postalCode.toString(),
    accountIBAN: iban,
    newsletterSubscribe: subscription,
    effectiveDate: startDate.toString(),
    dateOfBirth: format(
      new Date(createFullDriversBirthday(drivers[0].day, drivers[0].month, drivers[0].year)),
      "MM/dd/yyyy"
    ),
    cityOfBirth: drivers[0].city,
    nativeCountry: drivers[0].country,
    country: drivers[0].country,
    civility: drivers[0].gender,
    formule: formula,
  };

  const validationFailure = useCallback(() => {
    completeSummaryInfo({ completed: false });
    savePaymentInfo({
      bankingСonsent,
      iban,
      tariff,
      userName,
      frequency,
      discount,
      voucher,
      startDate: new Date(),
      completed: false,
      formula,
    });
    push("/payment");
  }, [
    completeSummaryInfo,
    savePaymentInfo,
    bankingСonsent,
    iban,
    tariff,
    userName,
    frequency,
    discount,
    voucher,
    formula,
    push,
  ]);

  const submit = useCallback(async () => {
    // TODO: Add validation.
    const validationPassed: boolean = !isAfter(startDate, new Date());
    if (validationPassed) {
      await submitMutation({
        variables: {
          input: mutationInput,
        },
      })
        .then(({ data: { submit: submitData } }) => {
          // TODO: API response
          if (submitData.redirect) {
            completeSummaryInfo({ completed: true });
            saveRedirectionInfo({ ...redirectionFields, completed: true, redirectionURL: submitData.redirect });
            // TODO: for staging env
            window.open(`https://${submitData.redirect}`, "_self");
            // TODO: for prod env
            //push("/finish");
          }
        })
        .catch((e) => {
          const gqlError = e.graphQLErrors ? e.graphQLErrors[0] : undefined;
          const message = gqlError?.extensions?.error;
          const validationMessage = e.message
            ? e.message
            : "Votre coupon a déjà été utilisé ou n’est plus valide, pour plus d’information rendez-vous sur notre FAQ.";
          alert(`${message ? message : validationMessage}`);
        });
    } else {
      validationFailure();
    }
  }, [
    validationFailure,
    startDate,
    completeSummaryInfo,
    redirectionFields,
    mutationInput,
    saveRedirectionInfo,
    submitMutation,
  ]);

  const currentTariff = regular.selected ? regular : premium;
  const amount = frequency === PaymentFrequency.monthly ? currentTariff.monthlyPrice : currentTariff.yearlyPrice;
  const price = tariff === Tariff.single ? amount * 2 : amount;

  const title =
    tariff === Tariff.single
      ? `Premier montant à payer de ${price} €`
      : currentTariff === regular
      ? "Premier montant à payer de 15 €"
      : "Premier montant à payer de 22,40 €";

  const description =
    tariff === Tariff.single
      ? "Ce 1er réglement correspond au montant de 2 mois de primes, vos prochains réglements concerneront les 10 prochains mois"
      : "Ce 1er règlement correspond au montant des deux premiers mois à régler comptant avant la mise en place de votre unique prélèvement pour le reste de l'année";
  return (
    <PageContainer>
      <Logo showGoBack={true} goBack={back} />
      <Stepper steps={stepperMock.steps} currentlySelected={4} />
      <Page>
        <Container>
          <QuizStepWrapper>
            <TopMargin>
              Vous allez être redirigé vers le site de notre partenaire ATM afin de sécuriser et finaliser votre
              souscription
            </TopMargin>
            <ContentWrapper>
              <IconWrapper>
                <CustomIcon icon="locked" iconColor="primaryTextLight" width="46" height="46" />
              </IconWrapper>
              <InfoText>ASSURANCE RESPONSABILITE CIVILE EDPM</InfoText>
              <InfoTextFormuleName>Formule {currentTariff.title}</InfoTextFormuleName>
              <MonthPriceText>{title}</MonthPriceText>
              <InformedText>{description}</InformedText>
            </ContentWrapper>
            <ButtonWrapper>
              <SubmitButton fullWidth={true} variant="dark" size="m" active={true} disabled={false} onClick={submit}>
                Finaliser votre souscription
              </SubmitButton>
            </ButtonWrapper>
          </QuizStepWrapper>
        </Container>
      </Page>
      <Footer />
    </PageContainer>
  );
};

export default Redirection;
