import React, { useCallback } from "react";
import { VehicleCardStyle, VehicleCardText, VehicleIconCard, VehicleCardTextDesktop } from "./elements";

const VehicleCard = (props) => {
  const cardStyleClick = useCallback(() => props.onClick(props.id), [props]);
  const vehicleName: string = props.name[0].toUpperCase() + props.name.slice(1);
  const updatedVehicleName =
    props.name === "trottinette"
      ? "Trottinette électrique"
      : props.name === "skateboard"
      ? "E-skateboard"
      : vehicleName;
  return (
    <VehicleCardStyle onClick={cardStyleClick}>
      <VehicleIconCard isSelected={props.isSelected}>
        {props.icon}
        <VehicleCardText isSelected={props.isSelected}>{updatedVehicleName}</VehicleCardText>
      </VehicleIconCard>

      <VehicleCardTextDesktop isSelected={props.isSelected}>{updatedVehicleName}</VehicleCardTextDesktop>
    </VehicleCardStyle>
  );
};

export default VehicleCard;
