import gql from "graphql-tag";

const GET_VOUCHER = gql`
  query getVoucher($voucher: String!) {
    voucher(voucher: $voucher) {
      firstName
      lastName
      mobile
      email
      productTypeId
      productBrandId
      productModel
      productSerial
      discount
    }
  }
`;

export default GET_VOUCHER;
