import gql from "graphql-tag";

const SUBMIT_VOUCHER_OPENED = gql`
  mutation submitVoucherOpened($input: VoucherOpened) {
    voucherOpenedTrack(input: $input) {
      voucher
    }
  }
`;

export default SUBMIT_VOUCHER_OPENED;
