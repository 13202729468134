import React from "react";
import {
  FooterDescription,
  FooterMessageBlockWrapper,
  FooterText,
  FooterTitle,
  GrayButton,
  GrayButtonWrapper,
} from "./elements";

const FooterMessageBlock = () => {
  return (
    <FooterMessageBlockWrapper>
      <FooterText>
        <FooterTitle>
          Découvrez l’assurance Responsabilité Civile pour les EDPM*
        </FooterTitle>
        <FooterDescription>
          *Engin de Déplacement Personnel Motorisé
        </FooterDescription>
      </FooterText>
      <GrayButtonWrapper>
        <GrayButton fullWidth={true} variant={"dark"} size="mH" disabled={false} onClick={()=> console.log("asd")}>
          découvrir
        </GrayButton>
      </GrayButtonWrapper>
    </FooterMessageBlockWrapper>
  );
};

export default FooterMessageBlock;
