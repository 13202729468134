import React from "react";
import InputMask from "react-input-mask";
import { media } from "styled-bootstrap-grid";
import styled, { css } from "styled-components";
import { StyledThemePropsType } from "../../config/theme";
import { Button } from "../../shared/elements";
import {
  ActivateCouponDescriptionDesktopStyles,
  ActivateCouponDescriptionMobileStyles,
  ActivateCouponTitleDesktopStyles,
  ActivateCouponTitleMobileStyles,
  H2DesktopStyles,
} from "../../shared/typography";

export type VoucherState = {
  isValid: boolean;
};

const TitleText = styled.div`
  ${ActivateCouponTitleMobileStyles};
  text-align: center;
  margin-top: 27px;
  ${media.lg`
    ${ActivateCouponTitleDesktopStyles}
  `};
`;

const DescriptionText = styled.div`
  position: relative;
  ${ActivateCouponDescriptionMobileStyles};
  text-align: center;
  ${(props) => css`
    color: ${props.theme.colors.textStyleMain};
  `};
  ${media.lg`
    ${ActivateCouponDescriptionDesktopStyles}
  `};
`;

const DescriptionTextContainer = styled.div`
  position: relative;
  text-align: center;
`;

const CouponIconContainer = styled.div`
  width: 100px;
  height: 65px;
  margin: 65px 0 45px 0;
`;

const CouponContainer = styled.div`
  margin-top: 15px;
  background: ${(props: StyledThemePropsType) => props.theme.colors.container};
  border: 0.5px solid ${(props: StyledThemePropsType) => props.theme.colors.borderLight};
  box-shadow: 0 2px 34px 0 rgba(29, 37, 45, 0.15);
  background-color: ${(props: StyledThemePropsType) => props.theme.colors.white};
  border-radius: 3px;
  display: flex;
  justify-content: start;
  padding-bottom: 30px;
  align-items: center;
  flex-direction: column;
	${(props: StyledThemePropsType) => css`
	${media.lg`
    margin-top: 40px;
    box-shadow: none;
    border: none;
    background: ${props.theme.colors.container};
    padding-bottom: 90px;
  `};
	`};
  
`;

const CouponText = styled.div<VoucherState & StyledThemePropsType>`
  ${H2DesktopStyles};
  color: ${(props: VoucherState & StyledThemePropsType) =>
    props.isValid ? props.theme.colors.primaryText : props.theme.colors.error};
  text-align: center;
  margin: 24px 24px 0;
  font-size: 16px;

  ${media.lg`
    font-size: 22px;
  `};
`;

/**
 * Needed the arrow function due to bad mapping inside react virtual attributes.(https://github.com/reach/reach-ui/issues/259)
 */
const VoucherInputSingle = styled(({ isValid, ...props }) => <InputMask {...props} />)`
  outline-color: ${(props: VoucherState & StyledThemePropsType) => {
    return props.isValid ? props.theme.colors.primaryText : props.theme.colors.error;
  }};
  color: ${(props: StyledThemePropsType) => props.theme.colors.primaryText};
  border-radius: 7.2px;
  border: solid 1.2px
    ${(props: VoucherState & StyledThemePropsType) => {
      return props.isValid ? props.theme.colors.primaryText : props.theme.colors.error;
    }};
  text-transform: uppercase;
  text-align: center;
  width: 85%;
  font-size: 18px;
  line-height: 36px;

  ${media.lg`
    width: 455px;
    font-size: 32px;
    line-height: 1.5;
  `};
`;

export type SubmitButtonProps = {
  disabled?: boolean;
};

const SubmitButton = styled(Button)<SubmitButtonProps>`
  width: 100%;
  margin: 55px 0 20px;
  height: 50px;
  background: ${(props: StyledThemePropsType) => props.theme.colors.primaryTextLight};
  box-shadow: 0 2px 34px 0 rgba(0, 0, 0, 0.15);
  opacity: ${(props: SubmitButtonProps) => (props.disabled ? "0.6" : "1")};
  color: ${(props: StyledThemePropsType) => props.theme.colors.white};

  ${media.lg`
    margin-top: 40px;
    width: 296px;
    height: 62px;
    align-self: center;
 `};
`;

const TooltipWrapper = styled.div`
  right: 71px;
  bottom: -2px;
  position: absolute;
	${media.lg`
		right: 24px;
		bottom: 5px;
	`};
  ${media.xl`
		right: 30px;
		bottom: 5px;
	`};
`;

export {
  TitleText,
  DescriptionText,
  DescriptionTextContainer,
  CouponContainer,
  CouponText,
  VoucherInputSingle,
  SubmitButton,
  CouponIconContainer,
  TooltipWrapper,
};
