import React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import AutomaticVoucherActivation from "./automatic";
import Wrapper from "./components/Wrapper";
import VoucherActivationHeader from "./Header";
import ManualVoucherActivation from "./manual";

const VoucherActivation = () => {
  const { path } = useRouteMatch();

  return (
    <>
      <VoucherActivationHeader />
      <Wrapper>
        <Switch>
          <Route exact path={`${path}/enter`} component={ManualVoucherActivation} />
          <Route exact path={`${path}/:voucher`} component={AutomaticVoucherActivation} />
          <Redirect to={`${path}/enter`} />
        </Switch>
      </Wrapper>
    </>
  );
};

export default VoucherActivation;
