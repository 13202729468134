import Icons from "../../../shared/icons/index";

export enum Vehicles {
  gyropode = "gyropode",
  gyroroue = "gyroroue",
  hoverboard = "hoverboard",
  onewheel = "onewheel",
  skateboard = "skateboard",
  trottinette = "trottinette",
  draisienne = "draisienne",
}

const VehicleIcons: { [key in keyof typeof Vehicles]: string } = {
  "gyropode": Icons.gyropode,
  "gyroroue": Icons.gyroroue,
  "hoverboard": Icons.hoverboard,
  "onewheel": Icons.onewheel,
  "skateboard": Icons.skateboard,
  "trottinette": Icons.trottinette,
  "draisienne": Icons.draisienne,
};

export default VehicleIcons;
