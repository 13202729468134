import React, { ChangeEvent, useCallback, useContext, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import Footer from "../../../shared/elements/Footer";
import {
  Button,
  CustomCheckBox,
  Datepicker,
  Logo,
  Message,
  Stepper,
  TextField,
  Tooltip,
} from "../../../shared/elements/index";
import { stepperMock } from "../../../shared/elements/Stepper/mock";
import { mergeIbanValue, getFormulaType } from "../../../shared/helpers/index";
import { useRedirection } from "../../../shared/helpers/useRedirection";
import Page, { Container, PageContainer } from "../../../shared/layout/Page/index";
import { ibanMask, paymentSchema } from "../../../shared/validation/payment";
import { AddressContext } from "../../../store/context/subscription/address";
import { DriversContext } from "../../../store/context/subscription/drivers";
import { InsuranceContext } from "../../../store/context/subscription/insurance";
import { PaymentContext, PaymentFrequency, Tariff } from "../../../store/context/subscription/payment";
import { PersonalTransportContext } from "../../../store/context/subscription/transport";
import { ErrorMessage } from "../../questionnaire/Step3/index";
import { QuizStepTitle, QuizStepWrapper } from "../elements";
import {
  BottomDescription,
  ButtonContainer,
  CalendarWrapper,
  ContentWrapper,
  CustomSpan,
  DirectionContainer,
  ElementsWrapper,
  HiddenWrapper,
  MessageWrapper,
  PaymentDirectionWrapper,
  PaymentPrice,
  PaymentSettingsWrapper,
  SubscriptionText,
  SubscriptionWrapper,
  SuccessMessageWrapper,
  TextFieldWrapper,
  TooltipWrapper,
} from "./elements";

type IBAN = string;

export type PaymentInfoFields = {
  tariff: Tariff;
  frequency: PaymentFrequency;
  startDate: Date;
  userName: string;
  firstName?: string;
  lastName?: string;
  iban: IBAN;
  voucher: string;
  discount: string;
  formula: string;
  bankingСonsent: boolean;
};

type PaymentValidationErrors = {
  userName: string;
  iban: string;
  date: string;
  formula: string;
  bankingСonsent: string;
};

const Payment = () => {
  useLayoutEffect(() => window.scrollTo({ top: 0, behavior: "smooth" }), []);
  /**
   * Logic related to the routing actions.
   */
  const { push } = useHistory();
  const [nextRoute, prevRoute] = useRedirection({
    currentRoute: "payment",
    nextRoute: "summary",
    prevRoute: "address",
  });
  const back = useCallback(() => push(prevRoute), [prevRoute, push]);

  /**
   * Logic related to the storing page-data in the state.
   */
  const { completed: transportStepCompleted } = useContext(PersonalTransportContext);
  const { completed: addressStepCompleted } = useContext(AddressContext);
  const {
    iban,
    bankingСonsent,
    startDate,
    userName,
    tariff,
    frequency,
    voucher,
    discount,
    formula,
    savePaymentInfo,
    completed: paymentStepCompleted,
  } = useContext(PaymentContext);
  const { drivers, completed: driversStepCompleted } = useContext(DriversContext);
  const { regular, premium, completed: insuranceStepCompleted } = useContext(InsuranceContext);
  const [paymentFields, setPaymentFields] = useState<PaymentInfoFields>({
    bankingСonsent,
    iban,
    startDate,
    userName: !!userName ? userName : `${drivers[0].firstName} ${drivers[0].lastName}`,
    tariff,
    frequency,
    voucher,
    discount,
    formula,
  });
  const flowComppleted: boolean = useMemo(
    () =>
      transportStepCompleted &&
      insuranceStepCompleted &&
      driversStepCompleted &&
      addressStepCompleted &&
      paymentStepCompleted,
    [addressStepCompleted, driversStepCompleted, insuranceStepCompleted, paymentStepCompleted, transportStepCompleted]
  );
  const pickSingleTariff = useCallback(() => {
    setPaymentFields({ ...paymentFields, frequency: PaymentFrequency.monthly, tariff: Tariff.single });
  }, [paymentFields]);
  const pickMultipleTariff = useCallback(() => {
    setPaymentFields({ ...paymentFields, frequency: PaymentFrequency.yearly, tariff: Tariff.multiple });
  }, [paymentFields]);
  /**
   * Logic related to the setting data in the context and move forward to the next step.
   */

  const [checkboxValidation, setCheckboxValidation] = useState<boolean>(false);

  const checkBoxOnChange = useCallback(() => {
    setPaymentFields({ ...paymentFields, bankingСonsent: !paymentFields.bankingСonsent });
  }, [paymentFields]);

  const savePaymentInfoContext = useCallback(() => {
    savePaymentInfo({
      ...paymentFields,
      iban: mergeIbanValue(paymentFields.iban),
      completed: true,
      formula: getFormulaType(regular.selected ? regular.type : premium.type),
    });
    if (!flowComppleted) push(nextRoute);
    push("/subscription/summary");
  }, [flowComppleted, savePaymentInfo, paymentFields, push, nextRoute, regular, premium]);

  /**
   * Error message.
   */

  const ERROR_MESSAGE = {
    iban: "L'IBAN que vous avez saisi n'est pas valide. Merci de réessayer.",
    checkbox: "Vous devez attester être titulaire du compte bancaire avant de poursuivre.",
  };

  const [error, setError] = useState<ErrorMessage>({
    display: false,
    message: "",
  });
  const hideErrorMessage = useCallback(() => setError({ ...error, display: false }), [error]);
  const showErrorComponent = error.display && !!error.message;

  const [success, setSuccess] = useState<ErrorMessage>({
    display: false,
    message: "La garantie ne prendra effet qu'à partir de la signature électronique du contrat.",
  });
  const hideSuccessMessage = useCallback(() => setSuccess({ ...success, display: false }), [success]);
  const showSuccessComponent = success.display && !!success.message;

  const inputsOnChange = useCallback(
    (field) => (event: ChangeEvent<HTMLInputElement>) => {
      setSuccess({ display: true, message: success.message });
      const value = event.target.value;

      setPaymentFields((prev) => ({ ...prev, startDate: new Date() }));
      if (field === "iban") {
        return setPaymentFields((prev) => ({ ...prev, iban: value.toUpperCase() }));
      }

      setPaymentFields((prev) => ({
        ...prev,
        [field]: value,
      }));
    },
    [success]
  );

  /**
   * Logic related to the API requests.
   */
  const initialPaymentErrors: PaymentValidationErrors = {
    userName: "",
    iban: "",
    date: "",
    formula: "",
    bankingСonsent: "",
  };
  const [validationErrors, setValidationErrors] = useState({ ...initialPaymentErrors });

  const datepickerOnChange = useCallback((value: Date) => {
    return setPaymentFields((prev) => ({
      ...prev,
      startDate: value,
    }));
  }, []);

  const submitPaymentInfo = useCallback(async () => {
    setCheckboxValidation(false);
    // TODO: Add validation.
    const validationPassed = await paymentSchema
      .validate(
        {
          iban: mergeIbanValue(paymentFields.iban),
          userName: paymentFields.userName,
          date: paymentFields.startDate,
          bankingСonsent: paymentFields.bankingСonsent,
        },
        { abortEarly: false }
      )
      .then(() => {
        setValidationErrors({ ...initialPaymentErrors });
        return true;
      })
      .catch((e) => {
        const errors: any = { ...initialPaymentErrors };
        e.inner.map((err: yup.ValidationError) => (errors[err.path] = err.message));
        setValidationErrors({ ...errors });
        return false;
      });

    if (validationPassed) {
      savePaymentInfoContext();
    }

    if (!paymentFields.bankingСonsent) {
      setCheckboxValidation(true);
    }

    if ((paymentFields.iban.length < 1 || !!validationErrors.iban) && !paymentFields.bankingСonsent) {
      setError({ display: true, message: `${ERROR_MESSAGE.iban} \n${ERROR_MESSAGE.checkbox}` });
    } else if (paymentFields.iban.length < 1 || !!validationErrors.iban) {
      setError({ display: true, message: ERROR_MESSAGE.iban });
    } else if (!paymentFields.bankingСonsent) {
      setError({ display: true, message: ERROR_MESSAGE.checkbox });
    } else {
      setError({ ...error, display: false, message: "" });
    }
  }, [
    paymentFields.iban,
    paymentFields.userName,
    paymentFields.startDate,
    paymentFields.bankingСonsent,
    validationErrors.iban,
    initialPaymentErrors,
    savePaymentInfoContext,
    ERROR_MESSAGE.iban,
    ERROR_MESSAGE.checkbox,
    error,
  ]);

  /**
   * General logic and variables needed to define some render settings.
   */
  const plan = regular.selected ? regular : premium;
  const singleTariffIsActive = paymentFields.tariff === "single";
  const multipleTariffIsActive = paymentFields.tariff === "multiple";
  const monthlyPrice = plan.monthlyPrice;
  const yearlyPrice = plan.yearlyPrice;
  const months = plan.discount ? 12 - +plan.discount.discountedMonths : 12;
  const monthlyPriceValue: string = monthlyPrice.toFixed(2).replace(".", ",");
  const yearlyPriceValue: string = yearlyPrice.toFixed(2).replace(".", ",");

  useEffect(() => {
    if (paymentFields.bankingСonsent) setCheckboxValidation(false);
  }, [paymentFields.bankingСonsent]);

  return (
    <PageContainer>
      <Logo showGoBack={true} goBack={back} />
      <Stepper steps={stepperMock.steps} currentlySelected={3} />
      <Page>
        <Container>
          <QuizStepWrapper>
            <QuizStepTitle>
              Plus que 2 étapes pour finir la souscription ! <br /> Veuillez renseigner ci-dessous vos informations de
              paiement
            </QuizStepTitle>
            <ContentWrapper>
              <PaymentDirectionWrapper>
                <PaymentSettingsWrapper selected={singleTariffIsActive} first onClick={pickSingleTariff}>
                  Je préfère payer <PaymentPrice>{monthlyPriceValue} € par mois </PaymentPrice>
                  pendant {months} mois
                </PaymentSettingsWrapper>
                <PaymentSettingsWrapper selected={multipleTariffIsActive} onClick={pickMultipleTariff}>
                  Je préfère payer en une seule fois
                  <PaymentPrice> {yearlyPriceValue} € pour l’année</PaymentPrice>
                </PaymentSettingsWrapper>
              </PaymentDirectionWrapper>
              <BottomDescription>
                Le contrat proposé est conclu pour une durée de 12 mois à compter de sa date d'effet et se renouvellera
                automatiquement pour une période de 12 mois chaque année à son échéance principale sauf résiliation par
                vous ou l'assureur. <br />
                <br /> NB : S'agissant d'une assurance obligatoire, la souscription de ce contrat pour Véhicule
                Terrestre à Moteur ne vous permettra pas de bénéficier d'un délai de renonciation.
              </BottomDescription>
              <CalendarWrapper className="black-placeholder">
                <Datepicker
                  onChange={datepickerOnChange}
                  label="date d’effet du contrat"
                  value={new Date()}
                  error={!!validationErrors.date}
                  readOnly
                  disabled
                  calendar
                  dateFormat="dd/MM/yyyy"
                />
              </CalendarWrapper>
              <DirectionContainer>
                <TextFieldWrapper>
                  <TextField
                    isGray
                    initialVisibility={true}
                    onChange={inputsOnChange("userName")}
                    label="Prénom et nom du titulaire du compte (souscripteur du contrat)"
                    value={paymentFields.userName}
                    error={!!validationErrors.userName}
                    disabled
                  />
                </TextFieldWrapper>
                <TextFieldWrapper>
                  <TextField
                    initialVisibility={true}
                    onChange={inputsOnChange("iban")}
                    label="IBAN"
                    mask={ibanMask}
                    placeholder="FRxx xxxx xxxx xxxx xxxx xxxx xxx"
                    value={paymentFields.iban}
                    error={!!validationErrors.iban}
                  />

                  <TooltipWrapper>
                    <Tooltip
                      rightPosition="top"
                      ibanLocker
                      text={
                        "Saisissez dans un 1er temps votre IBAN ici. Nous vous demanderons de confirmer votre autorisation de prélèvement par signature électronique dans quelques instants. Aucun prélèvement ne pourra survenir sans cette signature"
                      }
                    />
                    <Tooltip
                      rightPosition="top"
                      text={
                        "27 caractères pour les comptes tenus en France, commençant par FR suivi de 2 caractères puis des 23 chiffres correspondant au code de l’établissement bancaire, code du guichet, n° de compte et la clé RIB"
                      }
                    />
                  </TooltipWrapper>
                </TextFieldWrapper>
                {showSuccessComponent ? (
                  <>
                    <SuccessMessageWrapper>
                      <Message
                        close={hideSuccessMessage}
                        icon="warning"
                        iconColor="warningText"
                        backgroundColor="warningBackground"
                        textColor="warningText"
                        message={success.message}
                      />
                    </SuccessMessageWrapper>
                  </>
                ) : null}
              </DirectionContainer>
              <BottomDescription>
                Les primes d'assurances seront prélevées sur votre compte bancaire qu'après la signature du contrat. A
                noter, le souscripteur doit être le titulaire du compte bancaire. <br />
                <br />
                Le premier paiement correspondant à l'équivalent de deux mois de prime sera cependant à régler par carte
                bancaire à l'issue de la souscription pour la délivrance de votre carte verte de circulation.
              </BottomDescription>
            </ContentWrapper>
            <SubscriptionWrapper>
              <label>
                <CustomCheckBox disabled={false} checked={paymentFields.bankingСonsent} onChange={checkBoxOnChange} />
                <CustomSpan error={checkboxValidation} />
              </label>
              <SubscriptionText onClick={checkBoxOnChange} error={checkboxValidation} fontSize="16px">
                J'atteste être le titulaire du compte bancaire.
              </SubscriptionText>
            </SubscriptionWrapper>
            <ElementsWrapper>
              <ButtonContainer>
                <Button
                  fullWidth={true}
                  variant="dark"
                  // active={true}
                  size="auto"
                  disabled={false}
                  active={!!paymentFields.userName && !!paymentFields.iban && !!paymentFields.bankingСonsent}
                  // disabled={!paymentFields.bankingСonsent}
                  onClick={submitPaymentInfo}
                >
                  Valider
                </Button>
              </ButtonContainer>
              {showErrorComponent && (
                <>
                  <HiddenWrapper />
                  <MessageWrapper>
                    <Message
                      close={hideErrorMessage}
                      icon="error"
                      iconColor="error"
                      backgroundColor="errorBackground"
                      textColor="error"
                      message={error.message}
                    />
                  </MessageWrapper>
                </>
              )}
            </ElementsWrapper>
          </QuizStepWrapper>
        </Container>
      </Page>
      <Footer />
    </PageContainer>
  );
};

export default Payment;
