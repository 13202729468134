import React from "react";
import { PaymentInfoFields } from "../../../pages/subscription/payment/index";
import { CompletedStep } from "../../index";

export enum Tariff {
  single = "single",
  multiple = "multiple",
}

export enum PaymentFrequency {
  monthly = "Mensuel",
  yearly = "Annuel",
}

export type PaymentInfoData = CompletedStep & PaymentInfoFields;

type PaymentContext = PaymentInfoData & {
  savePaymentInfo(modifiedData: PaymentInfoData): void;
};

export const initialPaymentInfoData: PaymentInfoFields = {
  tariff: Tariff.single,
  frequency: PaymentFrequency.monthly,
  startDate: undefined,
  userName: "",
  firstName: "",
  lastName: "",
  iban: "",
  voucher: "",
  discount: "",
  formula: "",
  bankingСonsent: false
};

export const PaymentContext = React.createContext<PaymentContext>({
  ...initialPaymentInfoData,
  completed: false,
  savePaymentInfo(modifiedData: PaymentInfoData): PaymentInfoData {
    return modifiedData;
  },
});

export const PaymentContextProvider = PaymentContext.Provider;
