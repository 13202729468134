import React, { useState, FunctionComponent, useEffect } from "react";
import { Loader } from "./shared/elements/Loader";

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
const SCRIPT_URL = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places&callback=onMapsScriptLoaded`;

function loadScript() {
  let script = document.createElement("script");
  script.src = SCRIPT_URL;
  document.getElementsByTagName("head")[0].appendChild(script);
}

if (!(window.google && window.google.maps)) {
  loadScript();
}

const scriptLoaded = new Promise((resolve) => {
  if (window.google && window.google.maps) {
    resolve();
  }

  // @ts-ignore
  window.onMapsScriptLoaded = () => {
    resolve();
  };
});

const withGoogleMapsAPI = (WrappedComponent: FunctionComponent) => (ownProps: any) => {
  const [isLoading, setLoading] = useState(!window.google || !window.google.maps);

  useEffect(() => {
    scriptLoaded.then(() => setLoading(false));
  }, []);

  return isLoading ? <Loader isLoading /> : <WrappedComponent {...ownProps} />;
};

export default withGoogleMapsAPI;
