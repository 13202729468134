import { media } from "styled-bootstrap-grid";
import styled from "styled-components";
import { StyledThemePropsType } from "../../../config/theme";

interface MobileStepperProps {
  steps: number;
  currentStep: number;
}

const StepperWrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: fixed;
  margin: 60px 0 0;
  width: 100%;
  z-index: 100;

  ${media.lg`
    z-index: initial;
    position: initial;
    width: auto;
    margin: 100px 0 30px;
  `};

  ${media.xl`
    margin: 100px 0 30px;
  `};
`;

const StepperMobile = styled.div<MobileStepperProps & StyledThemePropsType>`
  position: absolute;
  height: 5px;
  border-radius: 16px;
  background-color: ${(props: MobileStepperProps & StyledThemePropsType) => props.theme.colors.primaryTextLight};
  width: ${(props: MobileStepperProps & StyledThemePropsType) => {
  const { steps, currentStep } = props;

  return `${(100 / steps) * currentStep}%`;
}};

  ${media.lg`
    display: none;
  `};
`;

export {
  StepperWrapper,
  StepperMobile,
};
