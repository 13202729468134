import styled, {
  css,
  FlattenInterpolation,
  ThemedStyledProps,
} from "styled-components";
import { StyledThemePropsType, TypographyKeyType } from "../../config/theme";
import { cssifyObject } from 'css-in-js-utils';

const tryGetProperty = (props : StyledThemePropsType, key: TypographyKeyType) => {
  const element = props.theme.typography[key];

  if (element) {
    return cssifyObject(element);
  }
}

const buildTypographyStyle  = (typographyKey: TypographyKeyType) : FlattenInterpolation<ThemedStyledProps<StyledThemePropsType, any>> => {
  return css`
    ${(props: StyledThemePropsType) => tryGetProperty(props, typographyKey)}
  `;
};

// declaration of styles
export const TextStyles = buildTypographyStyle('text');

export const H1DesktopStyles = buildTypographyStyle("h1Desktop");
export const H1MobileStyles = buildTypographyStyle("h1Mobile");
export const TextHighlightedStyles = buildTypographyStyle("textHighlighted");
export const ButtonTextDesktopStyles = buildTypographyStyle("buttonTextDesktop");
export const ButtonTextMobileStyles = buildTypographyStyle("buttonTextMobile");
export const ButtonPrimaryTextStyles = buildTypographyStyle("buttonPrimaryText");
export const ButtonSecondaryTextStyles = buildTypographyStyle("buttonSecondaryText");
export const TooltipTextDesktopStyles = buildTypographyStyle("tooltipTextDesktop");
export const TooltipTextMobileStyles = buildTypographyStyle("tooltipTextMobile");
export const StepperItemTextStyles = buildTypographyStyle("stepperItemText");
export const StepperItemTextHighlightedStyles = buildTypographyStyle("stepperItemTextHighlighted");
export const InputLabelTextDesktopStyles = buildTypographyStyle("inputLabelTextDesktop");
export const InputLabelTextMobileStyles = buildTypographyStyle("inputLabelTextMobile");
export const InputValueTextDesktopStyles = buildTypographyStyle("inputValueTextDesktop");
export const InputValueTextMobileStyles = buildTypographyStyle("inputValueTextMobile");
export const InputValueCaptionDesktopStyles = buildTypographyStyle("inputValueCaptionDesktop");
export const InputValueCaptionMobileStyles = buildTypographyStyle("inputValueCaptionMobile");
export const ErrorPopupTextDesktopStyles = buildTypographyStyle("errorPopupTextDesktop");
export const ErrorPopupTextMobileStyles = buildTypographyStyle("errorPopupTextMobile");
export const CheckboxLabelStyles = buildTypographyStyle("checkboxLabel");
export const UnderlinedLinkTextStyles = buildTypographyStyle("underlinedLinkText");
export const BottomSingleTextDesktopStyles = buildTypographyStyle("bottomSingleTextDesktop");
export const BottomSingleTextMobileStyles = buildTypographyStyle("bottomSingleTextMobile");
export const SummaryFormHeaderTextStyles = buildTypographyStyle("summaryFormHeaderText");
export const SummaryFormItemLabelTextDesktopStyles = buildTypographyStyle("summaryFormItemLabelTextDesktop");
export const SummaryFormItemLabelTextMobileStyles = buildTypographyStyle("summaryFormItemLabelTextMobile");
export const SummaryFormItemValueTextStyles = buildTypographyStyle("summaryFormItemValueText");
export const ActivateCouponTitleDesktopStyles = buildTypographyStyle("ActivateCouponTitleDesktop");
export const ActivateCouponTitleMobileStyles = buildTypographyStyle("ActivateCouponTitleMobile");
export const ActivateCouponDescriptionDesktopStyles = buildTypographyStyle("ActivateCouponDescriptionDesktop");
export const ActivateCouponDescriptionMobileStyles = buildTypographyStyle("ActivateCouponDescriptionMobile");
export const H2DesktopStyles = buildTypographyStyle("h2Desktop");
export const H2MobileStyles = buildTypographyStyle("h2Mobile");
export const H2HighlightedStyles = buildTypographyStyle("h2Highlighted");
export const H3PreFooterStyles = buildTypographyStyle("h3PreFooter");
export const HeroTitleStyles = buildTypographyStyle("heroTitle");
export const HeroDescriptionStyles = buildTypographyStyle("heroDescription");
export const HeaderButtonStyles = buildTypographyStyle("headerbutton");

// declaration of styled components
export const Text = styled.div`${TextStyles}`;
export const HeroTitle = styled.div`${HeroTitleStyles}`;
export const HeroDescription = styled.div`${HeroDescriptionStyles}`;
export const H1Desktop = styled.div`${H1DesktopStyles}`;
export const H1Mobile = styled.div`${H1MobileStyles}`;
export const TextHighlighted = styled.div`${TextHighlightedStyles}`;
export const ButtonTextDesktop = styled.div`${ButtonTextDesktopStyles}`;
export const ButtonTextMobile = styled.div`${ButtonTextMobileStyles}`;
export const ButtonPrimaryText = styled.div`${ButtonPrimaryTextStyles}`;
export const ButtonSecondaryText = styled.div`${ButtonSecondaryTextStyles}`;
export const TooltipTextDesktop = styled.div`${TooltipTextDesktopStyles}`;
export const TooltipTextMobile = styled.div`${TooltipTextMobileStyles}`;
export const StepperItemText = styled.div`${StepperItemTextStyles}`;
export const StepperItemTextHighlighted = styled.div`${StepperItemTextHighlightedStyles}`;
export const InputLabelTextDesktop = styled.div`${InputLabelTextDesktopStyles}`;
export const InputLabelTextMobile = styled.div`${InputLabelTextMobileStyles}`;
export const InputValueTextDesktop = styled.div`${InputValueTextDesktopStyles}`;
export const InputValueTextMobile = styled.div`${InputValueTextMobileStyles}`;
export const InputValueCaptionDesktop = styled.div`${InputValueCaptionDesktopStyles}`;
export const InputValueCaptionMobile = styled.div`${InputValueCaptionMobileStyles}`;
export const ErrorPopupTextDesktop = styled.div`${ErrorPopupTextDesktopStyles}`;
export const ErrorPopupTextMobile = styled.div`${ErrorPopupTextMobileStyles}`;
export const CheckboxLabel = styled.div`${CheckboxLabelStyles}`;
export const UnderlinedLinkText = styled.div`${UnderlinedLinkTextStyles}`;
export const BottomSingleTextDesktop = styled.div`${BottomSingleTextDesktopStyles}`;
export const BottomSingleTextMobile = styled.div`${BottomSingleTextMobileStyles}`;
export const SummaryFormHeaderText = styled.div`${SummaryFormHeaderTextStyles}`;
export const SummaryFormItemLabelTextDesktop = styled.div`${SummaryFormItemLabelTextDesktopStyles}`;
export const SummaryFormItemLabelTextMobile = styled.div`${SummaryFormItemLabelTextMobileStyles}`;
export const SummaryFormItemValueText = styled.div`${SummaryFormItemValueTextStyles}`;
export const H2Desktop = styled.div`${H2DesktopStyles}`;
export const H2Mobile = styled.div`${H2MobileStyles}`;
export const H2Highlighted = styled.div`${H2HighlightedStyles}`;
export const H3PreFooter = styled.div`${H3PreFooterStyles}`;

// legacy styles
export const H1CenterDesktopStyles = buildTypographyStyle("h1CenterDesktop");
export const H2Styles = buildTypographyStyle("h2");
export const H3Styles = buildTypographyStyle("h3");
export const BodyStyles = buildTypographyStyle("body");
export const LabelStyles = buildTypographyStyle("label");
export const H1CenterDesktop = styled.div`${H1CenterDesktopStyles}`;
export const H2 = styled.div`${H2Styles};`;
export const H3 = styled.div`${H3Styles};`;
export const Body = styled.div`${BodyStyles};`;
export const Label = styled.div`${LabelStyles};`;


export default {
  Text,
  HeroTitle,
  HeroDescription,
  H1Desktop,
  H1Mobile,
  TextHighlighted,
  ButtonTextDesktop,
  ButtonTextMobile,
  ButtonPrimaryText,
  ButtonSecondaryText,
  TooltipTextDesktop,
  TooltipTextMobile,
  StepperItemText,
  StepperItemTextHighlighted,
  InputLabelTextDesktop,
  InputLabelTextMobile,
  InputValueTextDesktop,
  InputValueTextMobile,
  InputValueCaptionDesktop,
  InputValueCaptionMobile,
	ErrorPopupTextDesktop,
  ErrorPopupTextMobile,
  CheckboxLabel,
  UnderlinedLinkText,
  BottomSingleTextDesktop,
  BottomSingleTextMobile,
  SummaryFormHeaderText,
  SummaryFormItemLabelTextDesktop,
  SummaryFormItemLabelTextMobile,
  SummaryFormItemValueText,
  H2Desktop,
  H2Mobile,
  H2Highlighted,
  H3PreFooter,

  // legacy
  H1CenterDesktop,
  H2,
  H3,
  Body,
  Label,
};
