import React from "react";
import { Vehicles } from "../../../pages/questionnaire/configuration/vehicles";

export type QuestionnaireDate = {
  firstStepCompleted: boolean;
  secondStepCompleted: boolean;
  thirdStepCompleted: boolean;
  summaryStepCompleted: boolean;
  vehicle: keyof typeof Vehicles;
  vehicleName: string;
};

type QuestionnaireContext = QuestionnaireDate & {
  saveQuestionnaireInfo(modifiedData: QuestionnaireDate): void;
};

export const initialQuestionnaireData: QuestionnaireDate = {
  firstStepCompleted: false,
  secondStepCompleted: false,
  thirdStepCompleted: false,
  summaryStepCompleted: false,
  vehicle: Vehicles.gyropode,
  vehicleName: 'Gyropode'
};

export const QuestionnaireContext = React.createContext<QuestionnaireContext>({
  ...initialQuestionnaireData,
  saveQuestionnaireInfo(modifiedData: QuestionnaireDate): QuestionnaireDate {
    return modifiedData;
  },
});

export const QuestionnaireContextProvider = QuestionnaireContext.Provider;
