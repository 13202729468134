import React, { FC } from "react";
import { Vehicles } from "../../../questionnaire/configuration/vehicles";
import { TransportCardContainer, TransportCardDescription, TransportCardTitle, TransportImage } from "./elements";

export type TransportCardProps = {
  icon: Vehicles;
  name: string;
  description: string;
};

const TransportCard: FC<TransportCardProps> = ({ icon, name, description }) => {
  return (
    <TransportCardContainer>
      <TransportImage icon={icon} />
      <TransportCardTitle>
        {name}
      </TransportCardTitle>
      <TransportCardDescription>
        {description}
      </TransportCardDescription>
    </TransportCardContainer>
  );
};

export default TransportCard;
