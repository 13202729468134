import styled from "styled-components";
import { StyledThemePropsType } from "../../../config/theme/index";
import { H2DesktopStyles } from "../../../shared/typography/index";

export const ContactMethodWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
`;

export const ContactMethodIcon = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-right: 18px;
`;

export const ContactMethodText = styled.div`
  ${H2DesktopStyles};
  color: ${(props: StyledThemePropsType) => props.theme.colors.textStyleMain};
`;

