import * as ReactGA from "react-ga";
import { useLocation } from "react-router-dom";
import React, { FunctionComponent } from "react";

ReactGA.initialize("UA-179165931-2");

const GoogleAnalytics: FunctionComponent = (props) => {
  const location = useLocation();
  ReactGA.pageview(location.pathname);

  return <>{props.children}</>;
};

export default GoogleAnalytics;
