import React from "react";
import { FeatureWrapper, FeatureText, FeatureTextProps, FeatureWrapperProps } from "./elements";
import { CustomIcon } from "../../../../../shared/elements";

type FeatureProps = FeatureTextProps &
  FeatureWrapperProps & {
    text: string;
    isPremiumTariff?: boolean;
  };

export const Feature: React.FC<FeatureProps> = ({ active, text, isLast, isPremiumTariff }) => {
  return (
    <FeatureWrapper isLast={isLast}>
      <CustomIcon
        width="14"
        height="10"
        icon="check"
        iconColor={active ? "primaryLight" : "container"}
        style={{
          marginTop: 9,
        }}
      />
      <FeatureText active={active}>{text}</FeatureText>
    </FeatureWrapper>
  );
};
