import { media } from "styled-bootstrap-grid";
import styled from "styled-components";
import { StyledThemePropsType } from "../../../config/theme/index";
import { DefaultFlexStyles } from "../../../shared/styles/index";
import { BottomSingleTextMobileStyles, H1MobileStyles, H2MobileStyles } from "../../../shared/typography/index";

const ContentWrapper = styled.div`
  ${DefaultFlexStyles};
  width: 100%;
  margin: 16px 0 11px;
  background-color: ${(props: StyledThemePropsType) => props.theme.colors.disabled};
  border-radius: 3px;
  
  ${media.lg`
    padding: 40px 85px 50px;
  `};
  
  ${media.xl`
    padding: 40px 190px 50px;
  `}
`;

const MainInfoWrapper = styled.div`
  border-radius: 5px;
  background-color: ${(props: StyledThemePropsType) => props.theme.colors.white};
  box-shadow: 0 2px 34px 0 rgba(29, 37, 45, 0.15);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 320px;
  padding: 17px 13px 26px;
  
	${media.lg`
    box-shadow: unset;
    padding: 33px 44px;
    max-width: 480px;
  `}
  ${media.xl`
    box-shadow: unset;
    padding: 33px 44px;
  `}
`;

const OrderTariff = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 50.01%;
  margin: 0 auto 7px;
  
  ${media.lg`
    margin-bottom: 32px;
    width: 100%;
    align-items: center;
  `};
`;

const TariffTitle = styled.div`
  ${H2MobileStyles};
  color: ${(props: StyledThemePropsType) => props.theme.colors.primaryText};
  font-weight: bold;
  line-height: 2.5;
  ${media.lg`
    line-height: normal;
  `};
  margin-top: 7px;
`;

const OrderDetails = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
`;

const SingleDetailWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  
  ${media.xl`
    justify-content: flex-start;
    width: 50%;
  `}
`;

const PolicyDescription = styled.div`
  width: 100%;
  ${BottomSingleTextMobileStyles};
  font-weight: normal;
  ${media.lg`
    ${H1MobileStyles};
    font-weight: normal;
  `};
  color: ${(props: StyledThemePropsType) => props.theme.colors.textStyleFirst};
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-self: baseline;
  width: 280px;
  margin: 15px auto 0;
  
  ${media.xl`
    width: 135px;
    margin-top: 44px;
  `};
`;

const TopMarginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 100px;
`;

const SuccessTitle = styled.div` 
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
	color: ${(props: StyledThemePropsType) => props.theme.colors.darkText};
	
	${media.lg`
		font-size: 24px;
		line-height: 30px;
	`};
`;

const SuccesDescription = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: ${(props: StyledThemePropsType) => props.theme.colors.darkText};
  margin: 16px 0 24px;

  ${media.lg`
		font-size: 16px;
	`};
`;

const SuccessWrapper = styled.div`
	display: flex;
	justify-content: center;
	flex-direction: column;
	height: 100%;
`;


export {
  ContentWrapper,
  MainInfoWrapper,
  OrderTariff,
  OrderDetails,
  SingleDetailWrapper,
  PolicyDescription,
  ButtonContainer,
  TariffTitle,
  TopMarginWrapper,
  SuccessTitle,
  SuccesDescription,
  SuccessWrapper,
};
