import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Logo } from "../../shared/elements/index";
import { HeaderContainer } from "../questionnaire/elements";

const VoucherActivationHeader = () => {
  const { push } = useHistory();
  const goBack = useCallback(() => push("/"), [push]);

  return (
    <HeaderContainer>
      <Logo showGoBack={true} goBack={goBack} />
    </HeaderContainer>
  );
};

export default VoucherActivationHeader;
