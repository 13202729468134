export const DATE_FORMAT = "P"
export const char = /[0-9a-zA-Z]/;
export const MASKED = [
	char,
	char,
	char,
	char,
	"-",
	char,
	char,
	char,
	char,
	"-",
	char,
	char,
	char,
	char,
	"-",
	char,
	char,
	char,
	char,
];
export const INITIAL_INPUT_STRING = "____-____-____-____";