import React, { useCallback, useContext, useLayoutEffect } from "react";
import { useHistory } from "react-router-dom";
import Footer from "../../../shared/elements/Footer";
import { Button, Logo } from "../../../shared/elements/index";
import Page, { Container, PageContainer } from "../../../shared/layout/Page/index";
import { RedirectionContext } from "../../../store/context/subscription/redirection";
import {
  ButtonContainer,
  ContentWrapper,
  MainInfoWrapper,
  SuccessTitle,
  SuccesDescription,
  SuccessWrapper,
} from "./elements";

const Success = () => {
  useLayoutEffect(() => window.scrollTo({ top: 0, behavior: "smooth" }), []);
  const { push } = useHistory();
  const back = useCallback(() => push("/subscription/redirection"), [push]);

  const { clearPersistedStorage } = useContext(RedirectionContext);

  const redirect = useCallback(() => {
    clearPersistedStorage();
    push("/");
  }, [push, clearPersistedStorage]);

  return (
    <PageContainer>
      <Logo showGoBack={true} goBack={back} />
      <Page fullPage>
        <SuccessWrapper>
          <Container>
            <ContentWrapper>
              <MainInfoWrapper>
                <SuccessTitle>
                  Merci d'avoir participé au test de notre tout nouveau parcours d'assurance !
                </SuccessTitle>
                <SuccesDescription>
                  L'équipe Owen va vous recontacter dans quelques jours pour finaliser votre souscription et vous
                  faire bénéficier en exclusivité de notre assurance.{" "}
                </SuccesDescription>
              </MainInfoWrapper>
            </ContentWrapper>
            <ButtonContainer>
              <Button fullWidth={true} variant="dark" size="m" active={true} disabled={false} onClick={redirect}>
                RETOUR
              </Button>
            </ButtonContainer>
          </Container>
        </SuccessWrapper>
      </Page>
      <Footer />
    </PageContainer>
  );
};

export default Success;
