import * as yup from "yup";

const dateChar = /[0-9]/;
export const dateMask = [ dateChar, dateChar, "/", dateChar, dateChar, "/", dateChar, dateChar, dateChar, dateChar ];

const firstName = yup
  .string()
  .required()
;

const lastName = yup
  .string()
  .required()
;

const day = yup
  .date()
  .required()
;

const month = yup
.date()
.required()
;

const year = yup
.date()
.required()
;


const city = yup
  .string()
	.required()
;

const country = yup
  .string()
	.required()
;

const gender = yup
  .string()
  .required()
;


export const driverSchema = yup.object({ firstName, lastName, city, country, day, month, year, gender });

export const driversSchema = yup.array().of(driverSchema);
