import { media } from "styled-bootstrap-grid";
import styled, { css } from "styled-components";
import { StyledThemePropsType } from "../../../../config/theme/index";
import { TextFieldBottomMarginStyles } from "../../../../shared/elements/TextField/elements";
import { DefaultFlexStyles } from "../../../../shared/styles/index";
import { InputLabelTextMobileStyles, LabelStyles } from "../../../../shared/typography/index";

const FormContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: ${(props: StyledThemePropsType) => props.theme.colors.grayBackground};
  padding: 25px 15px 0;
  width: 100%;
  margin-bottom: 20px;

  ${media.lg`
		padding: 50px 60px 25px;
  `};

  ${media.xl`
    border-radius: 3px;
    margin-bottom: 40px;
    padding: 0 43px 0;
    width: 491px;
  `};
`;

type HorizontalWrapperProps = {
  controlMargin?: boolean;
};
const HorizontalWrapper = styled.div<HorizontalWrapperProps>`
  ${TextFieldBottomMarginStyles};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ controlMargin }: HorizontalWrapperProps) => (controlMargin ? "28px" : "0px")};
  ${media.lg`
			flex-direction: row;
			height: initial;
  `};
`;

const FixedWrapper = styled.div<HorizontalWrapperProps>`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  ${({ controlMargin }: HorizontalWrapperProps) => css`
    ${media.lg`
				margin-bottom: ${controlMargin ? "-20px" : "-10px"};
			`};
  `};

  ${media.xl`
		margin-bottom: 0;
  `};
`;

type CloseButtonProps = {
  onClick(): void;
};
const CloseButton = styled.button<CloseButtonProps>`
  ${DefaultFlexStyles};
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 5px;
  width: 18px;
  height: 18px;
  padding: 4px;
  border-radius: 10px;
  border: solid 0.9px ${(props: StyledThemePropsType) => props.theme.colors.closeButtonBackground};
  background-color: ${(props: StyledThemePropsType) => props.theme.colors.closeButtonBackground};
  outline: none;
  ${media.lg`
		right: 65px;
		top: 20px;
	`};
  ${media.xl`
		right: 43px;
		top: -22px;
  `};
`;

const DateLabel = styled.p`
  margin-bottom: 5px;
  ${InputLabelTextMobileStyles};
  color: ${(props: StyledThemePropsType) => props.theme.colors.darkColor};
  text-transform: uppercase;

  ${media.lg`
    ${LabelStyles};
  `};
`;

const DatePickerWrapper = styled.div`
  width: 47px;
  ${media.lg`
		width: 90px;
  `};
`;
const MonthPickerWrapper = styled.div`
  width: 135px;
  ${media.lg`
		width: 167px;
  `};
`;
const YearPickerWrapper = styled.div`
  width: 65px;
  ${media.lg`
		width: 120px;
  `};
`;
const TextGeographyWrapper = styled.div`
  width: 100%;
  margin-bottom: 28px;
  ${media.lg`
		width: 190px;
		margin-bottom: 0;
	`};
`;
const MessageWrapper = styled.div`
  width: 100%;
  ${media.lg`
		margin-top: -30px;
	`};
  ${media.xl`
		margin-top: 0;
	`};
`;

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 28px;
`;

export {
  FormContainer,
  HorizontalWrapper,
  FixedWrapper,
  CloseButton,
  DateLabel,
  DatePickerWrapper,
  MonthPickerWrapper,
  YearPickerWrapper,
  TextGeographyWrapper,
  MessageWrapper,
  ErrorContainer,
};
