import * as yup from "yup";

const numberChar = /[0-9]/;

const phoneRegExp = /(\d{2}\s){4}\d{2}/;

export const phoneMask = [
  numberChar,
  numberChar,
  " ",
  numberChar,
  numberChar,
  " ",
  numberChar,
  numberChar,
  " ",
  numberChar,
  numberChar,
  " ",
  numberChar,
  numberChar,
];

const address = yup.string().required("Merci de remplir votre adresse postale");
const information = yup.string();
const email = yup.string().email(`Merci de renseigner votre email`).required(`Merci de renseigner votre email`);
const phone = yup
  .string()
  .matches(phoneRegExp, "Le numéro de téléphone saisi est invalide, merci de le corriger")
  .required("Merci de renseigner votre numéro de téléphone mobile");
const postalCode = yup
  .string()
  .min(5, "Postal Code should be 5 symbols length.")
  .max(5, "Postal Code should be 5 symbols length.")
  .required("Le code postal est manquant");
const city = yup.string().required();
const subscription = yup.boolean();
export const addressSchema = yup.object({
  address,
  information,
  email,
  phone,
  postalCode,
  city,
  subscription,
});
