import React from "react";
import { CompletedStep } from "../../index";

export type SummaryInfoData = CompletedStep;

type SummaryContext = SummaryInfoData & {
  completeSummaryInfo(modifiedDate: SummaryInfoData): void;
};

export const SummaryContext = React.createContext<SummaryContext>({
  completed: false,
  completeSummaryInfo(modifiedDate: SummaryInfoData): SummaryInfoData {
    return modifiedDate;
  },
});

export const SummaryContextProvider = SummaryContext.Provider;
