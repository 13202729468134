import gql from "graphql-tag";

const GET_QUESTIONS = gql`
  query getQuestions {
    questions {
      id
      tooltip
      questionText
      validationRequired
      options {
        id
        text
        image
      }
    }
  }
`;

export default GET_QUESTIONS;
