// import { ApolloLink, HttpLink, InMemoryCache } from "apollo-boost";
import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from "@apollo/client";

const cache = new InMemoryCache();

const env = {
	dev: "https://api.code.store/a4a73a0a29ce4bf39c1114c94fb5b7fb/graphql",
	staging: "https://assurlink-external-staging.herokuapp.com/graphql",
	prod: "https://assurlink.herokuapp.com/graphql"
}

const link = new HttpLink({ uri: env.prod });

const client = new ApolloClient({
	cache,
	name: "AssurLink",
	link: ApolloLink.from([link]),
});

export default client;
