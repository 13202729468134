import React from "react";
import { Civility } from "../../../api/interfaces";
import { Driver } from "../../../pages/subscription/drivers/mocks";
import { CompletedStep } from "../../index";


export type DriversInfoData = CompletedStep & {
  drivers: Driver[];
  driversResponsibilityPolicy: boolean;
};

type DriversContext = DriversInfoData & {
  saveDriversInfo(modifiedData: DriversInfoData): void;
};

export const initialPrimaryDriver: Driver = {
  key: 1,
  city: "",
  country: "",
  year: undefined,
  month: undefined,
  day: undefined,
  firstName: "",
  lastName: "",
  gender: Civility.Mr,
  title: "primary",
};

export const initialDriversInfoData: DriversInfoData = {
  completed: false,
  drivers: [initialPrimaryDriver],
  driversResponsibilityPolicy: false,
};

export const DriversContext = React.createContext<DriversContext>({
  ...initialDriversInfoData,
  saveDriversInfo(modifiedData: DriversInfoData): DriversInfoData {
    return modifiedData;
  },
});

export const DriversContextProvider = DriversContext.Provider;
