import React from "react";
import { Header } from "../../shared/elements";
import Footer from "../../shared/elements/Footer/index";
import FooterMessageBlock from "../../shared/elements/FooterMessageBlock/index";
import Page, { Container, PageContainer } from "../../shared/layout/Page/index";
import ContactMethod from "./ContactMethod/index";
import {
  ContactImage,
  ContactImageTitle,
  ContactInfoWrapper,
  ContactLink,
  ContactText,
  LeftContactInfo,
  QuestionBlock,
  QuestionCards,
  QuestionText,
  RightContactInfo,
  RightSideTitle,
  TopImageBlock,
  TransparentTextBlock,
} from "./elements";
import QuestionCard from "./QuestionCard/index";

const Contact = () => {
  return (
    <PageContainer>
      <Header />
      <TopImageBlock>
        <TransparentTextBlock>Contactez l’équipe Owen</TransparentTextBlock>
      </TopImageBlock>
      <Page>
        <Container>
          <ContactInfoWrapper>
            <LeftContactInfo>
              <ContactImageTitle>Accueil / Contact</ContactImageTitle>
              <ContactImage />
            </LeftContactInfo>
            <RightContactInfo>
              <RightSideTitle>Vous pouvez nous contacter du lundi au samedi de 8h à 19h.</RightSideTitle>
              <ContactMethod icon="mail" text="Par mail" />
              <ContactText>contact@getowen.eu</ContactText>
              <ContactMethod icon="chat" text="Par chat" />
              <ContactLink href="#replain-link">Écrivez-nous et nous vous répondons en quelques minutes</ContactLink>
            </RightContactInfo>
          </ContactInfoWrapper>
        </Container>
      </Page>
      <QuestionBlock>
        <QuestionText>vous avez une question ?</QuestionText>
        <QuestionCards>
          <QuestionCard
            title="En savoir plus sur l’assurance"
            description="Découvrez les différentes offres et garanties Owen"
            buttonText="découvrir"
            onClick={() => console.log("redirection")}
          />
          <QuestionCard
            title="Notre FAQ"
            description="Toutes les réponses aux questions que vous vous posez sur votre assurance"
            buttonText="Consulter"
            onClick={() => console.log("redirection")}
          />
        </QuestionCards>
      </QuestionBlock>
      <FooterMessageBlock />
      <Footer />
    </PageContainer>
  );
};

export default Contact;
