import React from "react";
import { QuestionnaireStep2Options } from "../types";

export const SecondStepError = (redirect?: () => void, onResetError?: () => void) => {
  return <span>Malheureusement nous n'assurons que les véhicules homologués et conformes.</span>;
};
export const ThirdStepError = (redirect?: () => void, onResetError?: () => void) => {
  return (
    <span>
      Malheureusement nous n'assurons que les véhicules utilisés dans un cadre strictement personnel et privé (y compris
      trajet domicile - travail). Si vous utilisez votre véhicule pour un usage professionnel, nous ne pouvons répondre
      à votre besoin.
    </span>
  );
};

export const FifthStepError = () => {
  return <span>Vous devez certifier vos réponses avant de poursuivre.</span>;
};

export const dialogQuestionsOptions: QuestionnaireStep2Options[] = [
  {
    id: 1,
    name: "Oui",
  },
  {
    id: 2,
    name: "Non",
  },
];
