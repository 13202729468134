import { media } from "styled-bootstrap-grid";
import styled from "styled-components";
import { StyledThemePropsType } from "../../config/theme";
import { HexToRgba } from "../../shared/helpers";
import {
  BottomSingleTextDesktopStyles,
  H1CenterDesktopStyles,
  H2DesktopStyles,
  H3PreFooterStyles,
  HeroDescriptionStyles,
} from "../../shared/typography/index";
import home from "../../shared/images/home.jpg";

export const MainWrapper = styled.div`
  width: 100%;
  height: 407px;
  background: url(${home}) no-repeat center;
  background-size: cover;
  padding: 139px 14px 0;
  margin-top: 60px;

  ${media.lg`
    margin-top: 80px;
    height: 512px;
    padding: 168px 140px 182px;
  `};
`;

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const TextWrapper = styled.div`
  width: 280px;
  height: 203px;
  background-color: ${(props: StyledThemePropsType) => HexToRgba(props.theme.colors.white, 0.5)};
  border-radius: 5px;
  padding: 25px 14px;
  margin: 0 auto;

  ${media.lg`
    background-color: transparent;
    padding: 0;
    width: 100%;
    margin: 0;
  `};
`;

export const MainText = styled.div`
  ${H1CenterDesktopStyles};
  font-weight: bold;
  width: 239px;
  line-height: 34px;
  font-size: 30px;

  ${media.lg`
    width: 581px;
    ${H1CenterDesktopStyles};
  `};
`;

export const Description = styled.div`
  ${HeroDescriptionStyles};
  font-weight: bold;
  width: 264px;
  margin-top: 12px;
  font-size: 15px;
  line-height: 19px;

  ${media.lg`
    margin-top: 10px;
    width: 512px;
    ${HeroDescriptionStyles};
  `};
`;

export const DetailsGroupsBackground = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: ${(props: StyledThemePropsType) => props.theme.colors.disabled};
  margin-bottom: 150px;
  ${media.xl`
    margin-bottom: 0px;
  `};
`;

export const DetailsGroupsContainer = styled.div`
  width: 1440px;
  padding: 47px 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  ${media.xl`
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 65px 137px 75px;
  `};
`;

export const InterestsTitle = styled.div`
  text-transform: uppercase;
  text-align: center;
  ${H3PreFooterStyles};
  padding: 56px 22px 36px;
  ${media.lg`
    text-align: left;
    ${H2DesktopStyles};
    padding: 56px 0;
  `};
`;

export const InterestsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props: StyledThemePropsType) => props.theme.colors.white};
  width: 100%;
`;

export const InterestsContent = styled.div`
  width: 280px;
  padding: 0 0 42px;

  ${media.lg`
    width: 750px;
  `};

  ${media.xl`
    width: 1400px;
    padding: 0 137px;
  `};
`;

export const TransportSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: ${(props: StyledThemePropsType) => props.theme.colors.backgroundLight};
  padding: 80px 20px 60px;

  ${media.lg`
    width: 100%;
    padding: 80px 140px 70px;
  `};
`;

export const TransportTitle = styled.div`
  text-transform: uppercase;
  text-align: center;
  ${H3PreFooterStyles};
  ${media.lg`
    text-align: left;
    ${H2DesktopStyles};
  `};
`;

export const TransportText = styled.div`
  ${BottomSingleTextDesktopStyles};
  line-height: 1.69;
  font-weight: normal;
  color: ${(props: StyledThemePropsType) => props.theme.colors.textStyleMain};
  width: 280px;
  text-align: center;
  margin: 10px 0;

  ${media.lg`
    width: 540px;
    ${HeroDescriptionStyles};
    font-weight: normal;
    margin: 10px 0 20px;
  `};
  ${media.xl`
    width: 780px;
    ${HeroDescriptionStyles};
    font-weight: normal;
    margin: 10px 0 40px;
  `};
`;

export const TransportSliderWrapper = styled.div`
  width: 280px;

  ${media.lg`
    width: 750px;
  `};
  ${media.xl`
    width: 1160px;
  `};
`;

export const InformationLink = styled.a`
  text-decoration: none;
  color: inherit;
  font-size: 10px;
`;

export const EmptyContainer = styled.div`
  height: 100px;
  width: 100%;
  margin-bottom: 70px;
  ${media.lg`
   margin-bottom: 0px;
  `};
`;
