import React from "react";
import Slider from "react-slick";
import {
  PlansSectionWrapper,
  PlansSectionTitle,
  PlansSectionSubTitle,
  PlansWrapper,
  PlansFeaturesWrapperDesktop,
  PlansFeaturesTitle,
  PlansFeatures,
  PlanFeature,
  PlanCardsWrapper,
  MobileSliderWrapper,
  CustomLinkWrapper,
  LinksWrraper,
} from "./elements";
import { plansSliderSettings } from "../sliderSettings";
import CardDesktop from "./PlanCardDesktop";
import CardMobile from "./PlanCardMobile";
import { CustomLink } from "../../../../shared/elements";
import { ExternalLinks } from "../../../../config/variables";

const PlansSection: React.FC = () => {
  const href = "https://docs.assur-link.com/wp-content/uploads/2021/04/DG-EDPM-EQEDM0841C-042021.pdf";
  const linksWrraper = (
    <>
      <CustomLink icon font="10px" href={href} target="_blank">
        CONSULTER LES DISPOSITIONS GÉNÉRALES
      </CustomLink>
      <CustomLink icon font="10px" href={ExternalLinks.WORDPRESS_INFORMATION_LINK} target="_blank">
        VOIR LA FICHE INFORMATION PRODUIT
      </CustomLink>
    </>
  );
  return (
    <PlansSectionWrapper>
      <PlansSectionTitle>2 formules pour vous couvrir</PlansSectionTitle>
      <PlansSectionSubTitle>
        Propriétaires d'Engins de Déplacement Personnels Motorisés, vos déplacements en ville sont devenus plus faciles,
        mais une simple faute d’inattention suffit pour provoquer des accidents. Alors, pour rester serein, le mieux,
        c’est de vous protéger et de protéger les autres.
      </PlansSectionSubTitle>
      <PlansWrapper>
        <PlansFeaturesWrapperDesktop>
          <PlansFeaturesTitle>Les garanties</PlansFeaturesTitle>
          <PlansFeatures>
            <PlanFeature>Responsabilité civile</PlanFeature>
            <PlanFeature>Garantie Défense pénale et Recours suite à accident</PlanFeature>
            <PlanFeature>Garantie Protection Corporelle du Conducteur</PlanFeature>
          </PlansFeatures>
          <CustomLinkWrapper>{linksWrraper}</CustomLinkWrapper>
        </PlansFeaturesWrapperDesktop>
        <PlanCardsWrapper>
          <CardDesktop
            key="SIMPLE"
            tariffName="SIMPLE"
            checks={[true, true, false]}
            buttonText="Je m’assure pour 7,50 € / mois"
            price={7.5}
            wholePrice={84.99}
            url="/questionnaire"
          />
          <CardDesktop
            key="PREMIUM"
            tariffName="PREMIUM"
            checks={[true, true, true]}
            buttonText="Je m’assure pour 11,20 € / mois"
            price={11.2}
            wholePrice={129.39}
            url="/questionnaire"
          />
        </PlanCardsWrapper>
      </PlansWrapper>
      <MobileSliderWrapper>
        <Slider {...plansSliderSettings}>
          <CardMobile
            tariffName="SIMPLE"
            checks={[true, true, true, false]}
            buttonText="Je m’assure pour 7,50 € / mois"
            price={7.5}
            wholePrice={84.99}
            url="/questionnaire"
          />
          <CardMobile
            checks={[true, true, true, true]}
            tariffName="PREMIUM"
            buttonText="Je m’assure pour 11,20 € / mois"
            price={11.2}
            wholePrice={129.9}
            url="/questionnaire"
          />
        </Slider>
        <LinksWrraper>{linksWrraper}</LinksWrraper>
      </MobileSliderWrapper>
    </PlansSectionWrapper>
  );
};

export default PlansSection;
