import { media } from "styled-bootstrap-grid";
import styled, { css } from "styled-components";
import { StyledThemePropsType } from "../../../config/theme/index";
import { SummaryFormItemLabelTextMobileStyles, UnderlinedLinkTextStyles } from "../../../shared/typography/index";
import AddIconMobile from "../../../shared/icons/defaultTheme/actions/add.svg";
import AddIconDesktop from "../../../shared/icons/defaultTheme/actions/add_desktop.svg";
import Checkmark from "../../../shared/icons/defaultTheme/statuses/success.svg";

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  width: 100%;
  margin-top: 28px;

  ${media.lg`
    margin-top: 40px;
    width: 212px;
    justify-content: flex-start;
  `};
`;

const ContentWrapper = styled.div`
  //TODO: revisit
  //padding: 0 5px;
  border-radius: 3px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-top: 16px;
  background-color: transparent;
  padding: 0;
  position: relative;

  ${media.xl`
    background-color: #EDEDEF;
    padding: 56px 0 20px;
  `};
`;

type AddDriverWrapperProps = {
  firstPosition: boolean;
};

const AddDriverWrapper = styled.button<AddDriverWrapperProps>`
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  margin: 0;
  padding: 30px 14px;
  box-shadow: 0 2px 34px 0 rgba(29, 37, 45, 0.15);
  border: solid 0.5px ${(props: StyledThemePropsType) => props.theme.colors.borderLight};
  background-color: ${(props: StyledThemePropsType) => props.theme.colors.white};
  border-radius: 12px;
  height: 120px;
	outline: none;
	${(props: AddDriverWrapperProps) => css `
		${media.lg`
			width: auto;
			height: initial;
			flex-direction: row;
			position: ${props.firstPosition ? 'absolute' : 'relative'};
			left: ${props.firstPosition ? '65px' : '0'};
			bottom: ${props.firstPosition ? '65px' : '0'};
			padding: ${props.firstPosition ? '0' : '0 0 0 60px'};
			margin-top: ${props.firstPosition ? '0' : '-60px'};
			text-decoration: underline;
			box-shadow: none;
			border: none;
			background-color: transparent;
			border-radius: none;
		`};

		${media.xl`
			left: ${props.firstPosition ? '42px' : '0'};
			bottom: ${props.firstPosition ? '32px' : '0'};
			padding-left: ${props.firstPosition ? '0' : '43px'};
			margin-top: ${props.firstPosition ? '0' : '-40px'};
		`}
	`}
`;

const AddButtonIcon = styled.div`
  width: 30px;
  height: 30px;
  background: url(${AddIconMobile});
  background-size: contain;
  :active {
    background: url(${AddIconDesktop});
    background-size: contain;
  }

  ${media.lg`
    background: url(${AddIconMobile});
    width: 16px;
    height: 16px;
    background-size: contain;
    margin-right: 4px;
    :active {
    background: url(${AddIconDesktop});
    background-size: contain;
  }
  `}
`;

const AddButtonText = styled.div`
  ${SummaryFormItemLabelTextMobileStyles};
  color: ${(props: StyledThemePropsType) => props.theme.colors.primaryText};
  font-size: 11px;
  line-height: 25px;
  margin-top: 3px;
  text-transform: uppercase;
  white-space: nowrap;

  ${media.lg`
    ${UnderlinedLinkTextStyles};
    margin: 0;
  `};
`;

const DriversMessage = styled.div`
  border-radius: 2px;
  background-color: ${(props: StyledThemePropsType) => props.theme.colors.secondary};
  width: 280px;
  height: 86px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  padding: 14px 5px;

  ${media.lg`
    padding: 14px 11px;
    margin: 0;
    width: 100%;
    align-items: center;
  `}

  ${media.xl`
    margin: 0 0 14px 43px;
    width: 520px;
    height: 64px;
  `}
`;

const DriversMessageIcon = styled.div`
  width: 30px;
  height: 30px;
  background: url(${Checkmark});
`;

const DriverMessageText = styled.div`
  font-family: Arial;
  font-size: 18px;
  font-weight: normal;
  margin-left: 13px;
  color: ${(props: StyledThemePropsType) => props.theme.colors.primaryText};
  width: 225px;
  line-height: 29px;

  ${media.lg`
    width: initial;
  `}
`;

const ElementsWrapper = styled.div`
  display: flex;
	flex-direction: column;
	width: 100%;
  ${media.xl`
		flex-direction: row;
	`};
`;

const MessageWrapper = styled.div`
  width: 280px;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 101;
  transform: translate(-50%, -50%);

  ${media.lg`
		position: relative;
		top: 0;
		left: 0;
		z-index: 0;
		transform: translate(0);
		width: 476px;
		
		display: flex;
		align-items: flex-end;
	`};

  ${media.xl`
		margin-left: 28px;
	`};
`;

const MessageWrapperForAge = styled(MessageWrapper)`
	${media.xl`
		width: 735px;
	`};
`;

const HiddenWrapper = styled.div`
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background-color: #000000;
  z-index: 100;
  ${media.lg`
		display: none;
	`};
`;

const VerticalDriversBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${media.lg`
		margin-bottom: 30px;
	`};
  ${media.xl`
		width: auto;
		margin-bottom: 0;
	`};
`;

export {
  ButtonContainer,
  ContentWrapper,
  AddDriverWrapper,
  AddButtonText,
  AddButtonIcon,
  DriversMessage,
  DriversMessageIcon,
  DriverMessageText,
  ElementsWrapper,
  MessageWrapper,
  HiddenWrapper,
  VerticalDriversBlock,
  MessageWrapperForAge,
};
