import { InsurancePricingPlanDiscount, InsurancePricingPlanDiscountType } from "../../api/interfaces";

const createDiscountString = ({ type, percentage, initialPayment, discountedMonths, isPrepaid }: InsurancePricingPlanDiscount, monthlyPrice: number, yearlyPrice: number): string => {
	let discountDetails: string;
	switch (type) {
		case InsurancePricingPlanDiscountType.PERCENTAGE:
			discountDetails = `12 mois avec ${percentage}% de réduction, soit ${(
				(yearlyPrice - initialPayment).toFixed(2).replace(".", ",")
			)} € d’économie`;
			break;
		case InsurancePricingPlanDiscountType.MONTHS:
			if (isPrepaid) {
				discountDetails = `${discountedMonths} mois issus du coupon prépayé de ${monthlyPrice.toFixed(2).replace(".", ",")} €, puis ${monthlyPrice.toFixed(2).replace(".", ",")} € /mois pour les ${12 - discountedMonths
					} mois restants`;
			} else if (initialPayment === 0) {
				discountDetails = `${discountedMonths} mois gratuits issus du coupon, puis ${12 - discountedMonths
					} mois à ${monthlyPrice.toFixed(2).replace(".", ",")} €`;
			} else if (initialPayment !== 0) {
				discountDetails = `${initialPayment.toFixed(2).replace(".", ",")} € les ${discountedMonths
					} mois issus du coupon, puis ${monthlyPrice.toFixed(2).replace(".", ",")} € /mois pour les ${12 - discountedMonths} mois restants`;
			}
	}
	return discountDetails;
}

export default createDiscountString;
