import React, { useContext } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { RedirectionContext } from "../../store/context/subscription/redirection";
import Success from "./success/index";

const Finish = () => {
  const { completed } = useContext(RedirectionContext);

  return (
    <Switch>
      {completed ?
        <Route path={"/finish"} component={Success} />
        :
        <Redirect to={"/"} />
      }
    </Switch>
  );
};

export default Finish;
