import React, { FunctionComponent, useCallback } from "react";
import {
  initialQuestionnaireData,
  QuestionnaireDate,
  QuestionnaireContextProvider,
} from "./context/questionnaire/questionnaire";
import { AddressContextProvider, AddressInfoData, initialAddressInfoData } from "./context/subscription/address";
import { DriversContextProvider, DriversInfoData, initialDriversInfoData } from "./context/subscription/drivers";
import {
  alternativeInsuranceInfoData,
  initialInsuranceInfoData,
  InsuranceContextProvider,
  InsuranceInfoData,
} from "./context/subscription/insurance";
import { initialPaymentInfoData, PaymentContextProvider, PaymentInfoData } from "./context/subscription/payment";
import {
  initialRedirectionInfoData,
  RedirectionContextProvider,
  RedirectionInfoData,
} from "./context/subscription/redirection";
import { SummaryContextProvider, SummaryInfoData } from "./context/subscription/summary";
import {
  initialTransportInfoData,
  PersonalTransportContextProvider,
  PersonalTransportInfoData,
} from "./context/subscription/transport";
import usePersistedState from "./persistedStorage/index";

export type CompletedStep = {
  completed: boolean;
};

const Store: FunctionComponent = (props) => {
  /**
   *  QUESTIONNAIRE INFO PART
   */
  const [questionnaireInfo, setQuestionnaireInfo] = usePersistedState<QuestionnaireDate>("questionnaire", { ...initialQuestionnaireData });
  const saveQuestionnaireInfo = useCallback((modifiedData: QuestionnaireDate) => {
    setQuestionnaireInfo({ ...modifiedData });
  }, [setQuestionnaireInfo]);

  /**
   *  TRANSPORT INFO PART
   */
  const [transportInfo, setPersonalTransportInfo] = usePersistedState<PersonalTransportInfoData>(
    "transport",
    { ...initialTransportInfoData, completed: false },
  );
  const savePersonalTransportInfo = useCallback((modifiedData: PersonalTransportInfoData) => {
    setPersonalTransportInfo({ ...modifiedData });
  }, [setPersonalTransportInfo]);

  /**
   * ADDRESS INFO PART
   */
  const [addressInfo, setAddressInfo] = usePersistedState<AddressInfoData>(
    "address",
    { ...initialAddressInfoData, completed: false },
  );
  const saveAddressInfo = useCallback((modifiedData: AddressInfoData) => {
    setAddressInfo({ ...modifiedData });
  }, [setAddressInfo]);

  /**
   * DRIVERS INFO PART
   */
  const [driversInfo, setDriversInfo] = usePersistedState<DriversInfoData>(
    "drivers",
    { ...initialDriversInfoData },
  );
  const saveDriversInfo = useCallback((modifiedData: DriversInfoData) => {
    setDriversInfo({ ...modifiedData });
  }, [setDriversInfo]);

  /**
   * INSURANCE INFO PART
   */
  const [insuranceInfo, setInsuranceInfo] = usePersistedState<InsuranceInfoData>(
    "insurance",
    { regular: { ...initialInsuranceInfoData }, premium: { ...alternativeInsuranceInfoData }, completed: false },
  );
  const saveInsuranceInfo = useCallback((modifiedData: InsuranceInfoData) => {
    setInsuranceInfo({ ...modifiedData });
  }, [setInsuranceInfo]);

  /**
   * PAYMENT INFO PART
   */
    // TODO: Set [username] value depends on primary driver's info.
  const [paymentInfo, setPaymentInfo] = usePersistedState<PaymentInfoData>(
    "payment",
    { ...initialPaymentInfoData, completed: false },
    );
  const savePaymentInfo = useCallback((modifiedData: PaymentInfoData) => {
    setPaymentInfo({ ...modifiedData });
  }, [setPaymentInfo]);

  /**
   * SUMMARY INFO PART
   */
  const [summaryInfo, setSummaryInfo] = usePersistedState<SummaryInfoData>("summary", { completed: false });
  const completeSummaryInfo = useCallback((modifiedDate: SummaryInfoData) => {
    setSummaryInfo({ ...modifiedDate });
  }, [setSummaryInfo]);

  /**
   * REDIRECTION INFO PART
   */
  const [redirectionInfo, setRedirectionInfo] = usePersistedState<RedirectionInfoData>(
    "redirection",
    { ...initialRedirectionInfoData, completed: false, redirectionURL: "" },
  );
  const saveRedirectionInfo = useCallback((modifiedData: RedirectionInfoData) => {
    setRedirectionInfo({ ...modifiedData });
  }, [setRedirectionInfo]);

  /**
   * CLEARING PERSISTED STORAGE
   */
  const clearPersistedStorage = useCallback(() => {
    localStorage.clear();

    setQuestionnaireInfo({ ...initialQuestionnaireData });
    setPersonalTransportInfo({ ...initialTransportInfoData, completed: false });
    setAddressInfo({ ...initialAddressInfoData, completed: false });
    setDriversInfo({ ...initialDriversInfoData });
    setInsuranceInfo({
      regular: { ...initialInsuranceInfoData },
      premium: { ...alternativeInsuranceInfoData },
      completed: false,
    });
    setPaymentInfo({ ...initialPaymentInfoData, completed: false });
    setSummaryInfo({ completed: false });
    setRedirectionInfo({ ...initialRedirectionInfoData, completed: false, redirectionURL: "" });
  }, [setAddressInfo, setDriversInfo, setInsuranceInfo, setPaymentInfo, setPersonalTransportInfo, setQuestionnaireInfo, setRedirectionInfo, setSummaryInfo]);

  return (
    <QuestionnaireContextProvider value={{ ...questionnaireInfo, saveQuestionnaireInfo }}>
      <InsuranceContextProvider value={{ ...insuranceInfo, saveInsuranceInfo }}>
        <PersonalTransportContextProvider value={{ ...transportInfo, savePersonalTransportInfo }}>
          <DriversContextProvider value={{ ...driversInfo, saveDriversInfo }}>
            <AddressContextProvider value={{ ...addressInfo, saveAddressInfo }}>
              <PaymentContextProvider value={{ ...paymentInfo, savePaymentInfo }}>
                <SummaryContextProvider value={{ ...summaryInfo, completeSummaryInfo }}>
                  <RedirectionContextProvider value={{ ...redirectionInfo, saveRedirectionInfo, clearPersistedStorage }}>
                    {props.children}
                  </RedirectionContextProvider>
                </SummaryContextProvider>
              </PaymentContextProvider>
            </AddressContextProvider>
          </DriversContextProvider>
        </PersonalTransportContextProvider>
      </InsuranceContextProvider>
    </QuestionnaireContextProvider>
  );
};

export default Store;
