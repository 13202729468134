import { Civility } from "../../../api/interfaces";
import { DriverNameTitle } from "./driver/index";

export type Driver = DriverNameTitle & {
  key: number;
  firstName: string;
  lastName: string;
  year: Date;
  month: Date;
  day: Date;
  city?: string;
  country?: string;
  gender?: Civility;
};

export const driversArray: Driver[] = [
  {
    key: 1,
    title: "primary",
    firstName: "",
    lastName: "",
    city: "",
    country: "",
    year: undefined,
    month: undefined,
    day: undefined,
    gender: Civility.Mr, 
  }
];

export const newSecondaryDriver: Driver = {
  key: 2,
  title: "secondary",
  firstName: "",
  lastName: "",
  city: "",
  country: "",
  year: undefined,
  month: undefined,
  day: undefined,
};
