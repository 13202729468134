import * as yup from "yup";
import ibanValidator from "iban";

const ibanFirstLetter = /[f,F]/;
const ibanSecondLetter = /[r,R]/;
const ibanNumber = /[0-9,a-z,A-Z]/;

export const ibanMask = [
  ibanFirstLetter, ibanSecondLetter, ibanNumber, ibanNumber, " ",
  ibanNumber, ibanNumber, ibanNumber, ibanNumber, " ",
  ibanNumber, ibanNumber, ibanNumber, ibanNumber, " ",
  ibanNumber, ibanNumber, ibanNumber, ibanNumber, " ",
  ibanNumber, ibanNumber, ibanNumber, ibanNumber, " ",
  ibanNumber, ibanNumber, ibanNumber, ibanNumber, " ",
  ibanNumber, ibanNumber, ibanNumber,
];

// TODO: Apply Date validation.
const date = yup
  .date()
  ;

const userName = yup
  .string()
  .required()
  ;

const bankingСonsent = yup
  .boolean()
  .equals([true])
  ;

const iban = yup
  .string()
  .matches(/^FR[0-9,A-Z]{25}$/)
  .test('iban', 'Iban is incorrect', (code) => ibanValidator.isValid(code))
  ;

export const paymentSchema = yup.object({ userName, iban, date, bankingСonsent });
