import React, { FC } from "react";
import { LabelWithDescription } from "../../../../shared/elements/index";
import { formatStoredDate } from "../../../../shared/helpers/index";
import {
  ModifierButton,
  PaymentBlockTitle,
  PaymentInfoBlock,
  PaymentInfoBlockBackgroundForTransport,
  PropertyWidthWrapper,
  SeveralPropertiesContainer,
} from "../elements";

type AddressInfoProps = {
  firstName: string;
  lastName: string;
  country: string;
  date: Date;
  address: string;
  email: string;
  phone: number | string;
  modify(): void;
};

const AddressInfo: FC<AddressInfoProps> = ({ firstName, lastName, date, address, country, email, phone, modify }) => {
	const phoneNumber:string = phone.toString().replace(/\s/g, "").match(/.{1,2}/g).join(' ');
  return (
    <PaymentInfoBlockBackgroundForTransport>
      <PaymentInfoBlock overflow="visible">
        <PaymentBlockTitle>VOS DONNÉES PERSONNELLES</PaymentBlockTitle>
        <SeveralPropertiesContainer>
          <PropertyWidthWrapper width={156}>
            <LabelWithDescription label="PRENOM" description={firstName} />
          </PropertyWidthWrapper>
          <PropertyWidthWrapper width={185}>
            <LabelWithDescription label="NOM" description={lastName.toUpperCase()} />
          </PropertyWidthWrapper>
        </SeveralPropertiesContainer>
				<SeveralPropertiesContainer>
				<PropertyWidthWrapper width={156}>
        <LabelWithDescription label="Date de naissance" description={formatStoredDate(new Date(date))} />
				</PropertyWidthWrapper>
				<PropertyWidthWrapper width={185}>
            <LabelWithDescription label="Pays de naissance" description={country} />
          </PropertyWidthWrapper>
				</SeveralPropertiesContainer>
        <LabelWithDescription label="Adresse" description={address} />
        <SeveralPropertiesContainer>
          <PropertyWidthWrapper width={156}>
            <LabelWithDescription label="Téléphone" description={phoneNumber} />
          </PropertyWidthWrapper>
          <PropertyWidthWrapper width={185}>
            <LabelWithDescription label="Email" description={email} />
          </PropertyWidthWrapper>
        </SeveralPropertiesContainer>
        <ModifierButton onClick={modify}>MODIFIER</ModifierButton>
      </PaymentInfoBlock>
    </PaymentInfoBlockBackgroundForTransport>
  );
};

export default AddressInfo;
