import { Col, media, Row, Container } from "styled-bootstrap-grid";
import styled, { css } from "styled-components";
import { StyledThemePropsType } from "../../../config/theme";

type PageProps = {
  fullPage?: boolean;
};

const FlexColumn = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const PageContainer = styled.div`
  min-height: 100vh;
  ${FlexColumn};
  background-color: ${(props: StyledThemePropsType) => props.theme.colors.white};
  position: relative;
`;

export const Page = styled.div<PageProps>`
  height: ${({ fullPage }: PageProps) => (fullPage ? "100vh" : "100%")};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 140px;
  ${media.xs`
    padding: 0 15px;
		overflow: scroll;
  `};
  ${media.sm`
    padding: 0 15px;
		overflow: scroll;
  `};
  ${media.lg`
    padding: 0 115px;
    margin: 0px;
		overflow: auto;
  `};
  ${media.xl`
    padding: 0 220px;
    margin: 0px;
		overflow: auto;
  `};
`;

export { Row, Col, Container };
export default Page;
