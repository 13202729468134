import { media } from "styled-bootstrap-grid";
import styled from "styled-components";
import { StyledThemePropsType } from "../../../config/theme";

export type IsSelectedProps = {
  isSelected: boolean;
};

const VehicleCardText = styled.p<IsSelectedProps>`
  display: block;
  text-align: center;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.19px;
  color: ${(props: IsSelectedProps & StyledThemePropsType) => {
    return props.isSelected ? props.theme.colors.primaryText : props.theme.colors.textStyleMain;
  }};
  ${media.lg`
    display: none;
  `}
`;

export const VehicleCardTextDesktop = styled.p<IsSelectedProps>`
  display: none;
  text-align: center;
  width: 90%;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.19px;
  color: ${(props: IsSelectedProps & StyledThemePropsType) => {
    return props.isSelected ? props.theme.colors.primaryText : props.theme.colors.textStyleMain;
  }};

  ${media.lg`
    display: inline-block;
    margin: 0 0 20px 0;
  `}
`;

const VehicleIconCard = styled.div<IsSelectedProps>`
  width: 210px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-shadow: 0 3px 34px 0 rgba(29, 37, 45, 0.15);
  border-radius: 5.6px;
  margin: 0 0 31px 0;
  border: solid 1.9px
    ${(props: IsSelectedProps & StyledThemePropsType) => {
      return props.isSelected ? props.theme.colors.primaryText : "transparent";
    }};
  background-color: ${(props: IsSelectedProps & StyledThemePropsType) => {
    return props.isSelected ? props.theme.colors.secondary : props.theme.colors.white;
  }};

  ${media.lg`
    box-shadow: initial;
    width: 150px;
    height: 200px;
  `};

  &:hover {
    border: solid 1.9px ${(props: IsSelectedProps & StyledThemePropsType) => props.theme.colors.primaryText};
    background-color: ${(props: IsSelectedProps & StyledThemePropsType) => props.theme.colors.secondary};
  }
`;

const VehicleCardStyle = styled.div`
  min-width: 200px;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0 6px;
  padding-top: 20px;

  & ${VehicleIconCard}:hover + ${VehicleCardTextDesktop} {
    color: ${(props: StyledThemePropsType) => props.theme.colors.primaryText};
  }

  ${media.lg`
    min-width: 150px;
  `};
`;

const SliderContainer = styled.div`
  max-width: 1000px;
  margin-top: 0;

  ${media.md`
    max-width: 290px;
    padding-bottom: 15px;
  `};

  ${media.lg`
    margin-top: 30px;
    max-width: 530px;
    padding-bottom: 40px;
  `};

  ${media.xl`
    max-width: 966px;
  `};
`;

export { VehicleCardText, VehicleIconCard, VehicleCardStyle, SliderContainer };
