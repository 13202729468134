import { Vehicles } from "../../../questionnaire/configuration/vehicles";
import { TransportCardProps } from "./TransportCard";

export const homeTransportSlides: TransportCardProps[] = [
  {
    icon: Vehicles.trottinette,
    name: "Trottinette électrique",
    description: "Des modèles pour tous les goûts et tous les types de conduite, la trottinette est la vedette des EDPM",
  },
  {
    icon: Vehicles.hoverboard,
    name: "Hoverboard",
    description: "Une planche à deux roues, on s’incline un peu et on file",
  },
  {
    icon: Vehicles.gyroroue,
    name: "Gyroroue",
    description: "Une roue, une position debout et c’est parti pour une navigation ultra simple",
  },
  {
    icon: Vehicles.onewheel,
    name: "Onewheel",
    description: "Une grosse roue au centre d’une planche stable pour des émotions fortes et contrôlées",
  },
  {
    icon: Vehicles.skateboard,
    name: "Skateboard électrique",
    description: "Une planche à roulette classique sauf que maintenant une ascension ne lui fait plus peur",
	},
];
