import { Settings } from "react-slick";
import React from "react";
import styled from "styled-components";
import Icons from "../../../shared/icons/defaultTheme";
import { DefinedIcons } from "../../../shared/types/index";


type ArrowProps = {
  icon: DefinedIcons;
};

const Arrow = styled.div<ArrowProps>`
  background: url(${(props: ArrowProps) => Icons[props.icon]}) no-repeat center;
  background-size: contain;
  width: 32px;
  height: 32px;
  
  &:hover {
    background-color: transparent;
  }
`;

const prevArrow = () => (<Arrow icon="leftArrow" />);
const nextArrow = () => (<Arrow icon="rightArrow" />);

export const transportSliderSettings: Settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  centerPadding: "0",
  arrows: false,
  prevArrow: prevArrow(),
  nextArrow: nextArrow(),
  responsive: [
    {
      breakpoint: 1440,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
        centerPadding: "0",
        dots: true
      }
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '0',
        dots: true,
        infinite: false
      }
    },
  ],
};

export const plansSliderSettings: Settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  centerPadding: "0",
  arrows: false,
  responsive: [
    {
      breakpoint: 1439,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        centerMode: false,
        centerPadding: '0',
        dots: true,
        infinite: false
      }
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '0',
        dots: true,
        infinite: false
      }
    },
  ]
};

export const interestsSliderSettings: Settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  centerPadding: "0",
  arrows: false,
  responsive: [
    {
      breakpoint: 1470,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: false,
        centerMode: false,
        centerPadding: '0',
        dots: true
      },
    },
    {
      breakpoint: 1180,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: false,
        centerPadding: '0',
        dots: true,
      },
    },
    {
      // TODO: Fix horizontal scroll from 768px to 817px.
      breakpoint: 820,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '0',
        dots: true,
      },
    },
  ],
};
