import React from "react";
import Select, { ValueType, ActionMeta } from "react-select";
import { LabelContainer, WrapperWithoutPaddings, HelpText } from "../TextField/elements";
import { DropdownWrapper } from "./element";
import { styles, upperCaseStyles } from "./dropdownStyles";

export type DropdownOption = {
  value: any;
  label: string;
};

interface DropdownProps {
  label: string;
  placeholder: string;
  options: DropdownOption[];
  value: ValueType<any>;
  error?: boolean;
  helpText?: string;
  onChange(value: ValueType<any>, actionMeta: ActionMeta<any>): void;
  isDisabled: boolean;
  upperCaseOptions?: boolean;
}

const Dropdown: React.FC<DropdownProps> = ({
  label,
  options,
  onChange,
  placeholder,
  value,
  error,
  helpText,
  isDisabled,
  upperCaseOptions
}) => {
  const dropdownStyles = upperCaseOptions ? upperCaseStyles : styles;

  return (
    <DropdownWrapper>
      <LabelContainer>{label}</LabelContainer>
      <WrapperWithoutPaddings error={error}>
        <Select
          isDisabled={isDisabled}
          onChange={onChange}
          isSearchable={true}
          options={options}
          placeholder={placeholder}
          value={value ? value : null}
          styles={dropdownStyles}
        />
      </WrapperWithoutPaddings>
      {helpText && <HelpText error={error}>{helpText}</HelpText>}
    </DropdownWrapper>
  );
};

export default Dropdown;
