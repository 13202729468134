import gql from "graphql-tag";

const GET_PRODUCTS = gql`
  query getProducts {
    products {
      id
      name
      brands {
        id
        name
      }
    }
  }
`;

export default GET_PRODUCTS;
