import React, { useCallback, useContext, useLayoutEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { format, isAfter } from "date-fns";
import { Button, CustomCheckBox, CustomIcon, Logo, Message, Stepper } from "../../../shared/elements/index";
import { stepperMock } from "../../../shared/elements/Stepper/mock";
import createFullDriversBirthday from "../../../shared/helpers/fullDriversBirthday";
import createDiscountString from "../../../shared/helpers/createDiscountString";
import { useRedirection } from "../../../shared/helpers/useRedirection";
import { Container } from "../../../shared/layout/Page/";
import Page, { PageContainer } from "../../../shared/layout/Page/index";
import { AddressContext, AddressInfoFields } from "../../../store/context/subscription/address";
import { DriversContext } from "../../../store/context/subscription/drivers";
import { InsuranceContext } from "../../../store/context/subscription/insurance";
import { PaymentContext, PaymentFrequency } from "../../../store/context/subscription/payment";
import { SummaryContext } from "../../../store/context/subscription/summary";
import { PersonalTransportContext } from "../../../store/context/subscription/transport";
import { ErrorMessage } from "../../questionnaire/Step3";
import { Driver } from "../drivers/mocks";
import { QuizStepTitle, QuizStepWrapper } from "../elements";
import { InsuranceTariff } from "../insurance/TariffCard/index";
import { PaymentInfoFields } from "../payment/index";
import { CheckboxWrapper, CustomSpan, PolicyText } from "../transport/elements";
import { BasicTransportInfo } from "../transport/index";
import AddressInfo from "./addressInfo/index";
import {
  ElementsWrapper,
  HiddenWrapper,
  MessageWrapper,
  PaymentContent,
  SubmitButtonContainer,
  TwoBLocksColumn,
  SummaryWrapperText,
  SummaryTitle,
  SummaryTitleBold,
  SummaryText,
} from "./elements";
import InsuranceInfo, { CouponDetails } from "./insuranceInfo/index";
import PaymentInfo from "./paymentInfo/index";
import TransportInfo from "./transportInfo/index";
import Footer from "../../../shared/elements/Footer";
import {
  RequestedInsurancePlanType,
  SubmitMutationInput,
  SubmitMutationResult,
  SubmitMutationVariables,
} from "../../../api/interfaces";
import SUBMIT from "../../../api/mutations/submit";
import { useMutation } from "@apollo/client";
import { RedirectionFields } from "../redirection";
import { RedirectionContext } from "../../../store/context/subscription/redirection";

const Summary = () => {
  useLayoutEffect(() => window.scrollTo({ top: 0, behavior: "smooth" }), []);
  /**
   * Logic related to the routing actions.
   */
  const { push } = useHistory();
  const [transportRoute] = useRedirection({ currentRoute: "summary", nextRoute: "transport" });
  const [addressRoute] = useRedirection({ currentRoute: "summary", nextRoute: "address" });
  const [insuranceRoute] = useRedirection({ currentRoute: "summary", nextRoute: "insurance" });
  const [paymentRoute] = useRedirection({ currentRoute: "summary", nextRoute: "payment" });
  const [redirectionRoute, prevRoute] = useRedirection({
    currentRoute: "summary",
    nextRoute: "finish",
    prevRoute: "payment",
  });
  const back = useCallback(() => push(prevRoute), [push, prevRoute]);
  const modifyPreviousSteps = useCallback((route: string) => () => push(route), [push]);

  /**
   * Logic related to the storing page-data in the state.
   */

  const { type, brand, model, serial, typeName, brandName, speedLimitPolicy } = useContext(PersonalTransportContext);
  const [transportState] = useState<BasicTransportInfo>({
    type,
    brand,
    typeName,
    brandName,
    model,
    serial,
    speedLimitPolicy,
  });
  const { drivers } = useContext(DriversContext);
  const [waitingForSubmit, setWaitingForSubmit] = useState(false);
  const [driversState] = useState<Driver[]>([...drivers]);
  const { address, city, email, information, phone, postalCode, subscription } = useContext(AddressContext);
  const [addressState] = useState<AddressInfoFields>({
    address,
    city,
    email,
    information,
    phone,
    postalCode,
    subscription,
  });
  const { regular, premium } = useContext(InsuranceContext);
  const insuranceState: InsuranceTariff = regular.selected ? regular : premium;
  const {
    bankingСonsent,
    iban,
    startDate,
    tariff,
    userName,
    frequency,
    discount,
    voucher,
    formula,
    savePaymentInfo,
  } = useContext(PaymentContext);
  const [paymentState] = useState<PaymentInfoFields>({
    bankingСonsent,
    iban,
    startDate,
    tariff,
    userName,
    frequency,
    discount,
    voucher,
    formula,
  });
  const prixDescription =
    frequency === PaymentFrequency.yearly
      ? `${insuranceState.yearlyPrice.toFixed(2).replace(".", ",")} € / année`
      : `${insuranceState.monthlyPrice.toFixed(2).replace(".", ",")} € / mois`;

  /**
   * Logic related to the API requests.
   */
  const formattedDriversArray = drivers.map(({ firstName, lastName, city: birthPlace, day, month, year }) => {
    const fullDateString = createFullDriversBirthday(day, month, year);
    return { firstName, lastName, birthPlace, birthDay: fullDateString };
  });

  const [error, setError] = useState<ErrorMessage>({
    display: false,
    message: "Vous devez attester sur l’honneur avant de poursuivre .",
  });
  const hideErrorMessage = useCallback(() => setError({ ...error, display: false }), [error]);
  const showErrorComponent = error.display && !!error.message;

  const [checkbox, setCheckbox] = useState(false);
  const [checkboxFlag, setCheckboxFlag] = useState(true);

  const controlCheckBox = useCallback(() => {
    setCheckbox(!checkbox);
    setCheckboxFlag(true);
    setError({ display: false, message: error.message });
  }, [error, setCheckboxFlag, checkbox]);

  const [redirectionFields] = useState<RedirectionFields>();

  // const { drivers } = useContext(DriversContext);
  // const { address, city, email, information, phone, postalCode, subscription } = useContext(AddressContext);
  // const { regular, premium } = useContext(InsuranceContext);
  // const insuranceState: InsuranceTariff = regular.selected ? regular : premium;
  // const {
  //   bankingСonsent,
  //   iban,
  //   startDate,
  //   tariff,
  //   userName,
  //   frequency,
  //   discount,
  //   voucher,
  //   formula,
  //   savePaymentInfo,
  // } = useContext(PaymentContext);
  // const [paymentState] = useState<PaymentInfoFields>({
  //   bankingСonsent,
  //   iban,
  //   startDate,
  //   tariff,
  //   userName,
  //   frequency,
  //   discount,
  //   voucher,
  //   formula,
  // });
  const { completeSummaryInfo } = useContext(SummaryContext);
  const { saveRedirectionInfo } = useContext(RedirectionContext);

  const [submitMutation] = useMutation<SubmitMutationResult, SubmitMutationVariables>(SUBMIT);

  const planId: number = +insuranceState.planId;
  const paymentType =
    paymentState.frequency === PaymentFrequency.monthly
      ? RequestedInsurancePlanType.MONTHLY
      : RequestedInsurancePlanType.YEARLY;

  const mutationInput: SubmitMutationInput = {
    email,
    city,
    address,
    information,
    voucher,
    planId,
    productSerial: serial,
    productModel: model,
    productTypeId: Number(type),
    productBrandId: Number(brand),
    type: paymentType,
    drivers: drivers.map(({ firstName, lastName, city: birthPlace, day, month, year }) => {
      const fullDateString = format(new Date(createFullDriversBirthday(day, month, year)), "MM/dd/yyyy");
      return { firstName, lastName, birthPlace, birthDay: fullDateString };
    }),
    accountOwner: userName,
    mobile: phone.toString(),
    firstName: drivers[0].firstName,
    lastName: drivers[0].lastName,
    postalCode: postalCode.toString(),
    accountIBAN: iban,
    newsletterSubscribe: subscription,
    effectiveDate: startDate.toString(),
    dateOfBirth: format(
      new Date(createFullDriversBirthday(drivers[0].day, drivers[0].month, drivers[0].year)),
      "MM/dd/yyyy"
    ),
    cityOfBirth: drivers[0].city,
    nativeCountry: drivers[0].country,
    country: drivers[0].country,
    civility: drivers[0].gender,
    formule: formula,
  };

  const validationFailure = useCallback(() => {
    completeSummaryInfo({ completed: false });
    savePaymentInfo({
      bankingСonsent,
      iban,
      tariff,
      userName,
      frequency,
      discount,
      voucher,
      startDate: new Date(),
      completed: false,
      formula,
    });
    push("/payment");
  }, [
    completeSummaryInfo,
    savePaymentInfo,
    bankingСonsent,
    iban,
    tariff,
    userName,
    frequency,
    discount,
    voucher,
    formula,
    push,
  ]);

  const submit = useCallback(async () => {
    // TODO: Add validation.
    const validationPassed: boolean = !isAfter(startDate, new Date());
    if (validationPassed) {
      setWaitingForSubmit(true);
      await submitMutation({
        variables: {
          input: mutationInput,
        },
      })
        .then(({ data: { submit: submitData } }) => {
          // TODO: API response
          if (submitData.redirect) {
            completeSummaryInfo({ completed: true });
            saveRedirectionInfo({ ...redirectionFields, completed: true, redirectionURL: submitData.redirect });
            // TODO: for staging env
            window.open(`https://${submitData.redirect}`, "_self");
            // TODO: for prod env
            //push("/finish");
          }
        })
        .catch((e) => {
          const gqlError = e.graphQLErrors ? e.graphQLErrors[0] : undefined;
          const message = gqlError?.extensions?.error;
          const validationMessage = e.message
            ? e.message
            : "Votre coupon a déjà été utilisé ou n’est plus valide, pour plus d’information rendez-vous sur notre FAQ.";
          alert(`${message ? message : validationMessage}`);
        });
    } else {
      validationFailure();
    }
  }, [
    startDate,
    submitMutation,
    mutationInput,
    completeSummaryInfo,
    saveRedirectionInfo,
    redirectionFields,
    validationFailure,
  ]);

  const submitSummaryPage = useCallback(() => {
    if (waitingForSubmit) return;
    if (!checkbox) {
      setCheckboxFlag(false);
      setError({ display: true, message: error.message });
    } else {
      setCheckboxFlag(true);
      setError({ ...error, display: false });
      completeSummaryInfo({ completed: true });
      // push(redirectionRoute);
      // dig here
      submit();
    }
  }, [waitingForSubmit, checkbox, error, completeSummaryInfo, submit]);

  /**
   * General logic and variables needed to define some render settings.
   */
  const coupon = !!discount;
  const couponDetailsObject: CouponDetails = {
    title: "AVANTAGE COUPON",
    priceDescription: insuranceState.discount
      ? createDiscountString(insuranceState.discount, insuranceState.monthlyPrice, insuranceState.yearlyPrice)
      : "",
  };
  return (
    <PageContainer>
      <Logo showGoBack={true} goBack={back} />
      <Stepper steps={stepperMock.steps} currentlySelected={3} />
      <Page>
        <Container>
          <QuizStepWrapper>
            <QuizStepTitle>
              Récapitulons votre besoin avant de finaliser votre souscription à l’assurance Responsabilité Civile EDPM
              OWEN
            </QuizStepTitle>
            <PaymentContent>
              <TwoBLocksColumn>
                <InsuranceInfo
                  tariffName={insuranceState.title}
                  priceDescription={prixDescription}
                  startDate={startDate}
                  showCoupon={coupon}
                  couponDetails={couponDetailsObject}
                  modify={modifyPreviousSteps(insuranceRoute)}
                />
                <TransportInfo
                  transport={transportState}
                  driversArray={driversState}
                  modify={modifyPreviousSteps(transportRoute)}
                />
              </TwoBLocksColumn>
              <TwoBLocksColumn>
                <AddressInfo
                  firstName={driversState[0].firstName}
                  lastName={driversState[0].lastName}
                  date={formattedDriversArray[0].birthDay}
                  address={`${addressState.address}, ${addressState.postalCode}, ${addressState.city}`}
                  country={driversState[0].country}
                  email={addressState.email}
                  phone={addressState.phone}
                  modify={modifyPreviousSteps(addressRoute)}
                />
                <PaymentInfo
                  userName={paymentState.userName}
                  iban={paymentState.iban}
                  frequency={paymentState.frequency}
                  modify={modifyPreviousSteps(paymentRoute)}
                />
              </TwoBLocksColumn>
            </PaymentContent>
            <CheckboxWrapper>
              <label>
                <CustomCheckBox disabled={false} checked={checkbox} onChange={controlCheckBox} />
                <CustomSpan error={!checkboxFlag} />
              </label>
              <PolicyText onClick={controlCheckBox} error={!checkboxFlag}>
                Je certifie exactes les informations déclarées ci-dessus. En cas de fausses déclarations, je m'expose à
                des sanctions, à savoir la nullité du contrat ou la réduction des indemnités
              </PolicyText>
            </CheckboxWrapper>
            <ElementsWrapper>
              <SubmitButtonContainer>
                <Button
                  fullWidth={true}
                  variant="dark"
                  size="l"
                  active={checkbox}
                  disabled={false}
                  onClick={submitSummaryPage}
                >
                  Finaliser la souscription
                  {waitingForSubmit ? (
                    <CustomIcon iconColor="white" icon="loader" width="20" height="20" margin="0  0 0 10px" />
                  ) : null}
                </Button>
              </SubmitButtonContainer>
              {showErrorComponent ? (
                <>
                  <HiddenWrapper />
                  <MessageWrapper>
                    <Message
                      close={hideErrorMessage}
                      icon="error"
                      iconColor="error"
                      backgroundColor="errorBackground"
                      textColor="error"
                      message={error.message}
                    />
                  </MessageWrapper>
                </>
              ) : null}
            </ElementsWrapper>
            <SummaryWrapperText>
              <SummaryTitle>
                <SummaryTitleBold>INFORMATIONS A CARACTERE COMMERCIAL SUR LE CONTRAT PROPOSE</SummaryTitleBold> (article
                L112-2-1 III du code des assurances) :
              </SummaryTitle>
              <SummaryText>
                L'assureur du contrat est L'EQUITE, SA au capital de 26.469.320 €, Entreprise régie par le code des
                assurances, RCS Paris 572084697, 2 rue Pillet Will 75009 PARIS, Société appartenant au Groupe Generali,
                immatriculé au registre italien des groupes d'assurance sous le numéro 026 - Société soumise au contrôle
                de l'ACPR 4 place de Budapest - CS 92459 - 75436 Paris Cedex 09. <br /> <br />
                Le montant total de la prime du contrat pour la première année d'assurance est indiqué ci-dessus. Le
                contrat proposé est d'une durée minimale de 1 an avec tacite reconduction annuelle sous réserve du
                paiement de la cotisation. Les garanties, exclusions, et autres conditions contractuelles du contrat
                proposé, figurent aux dispositions générales et particulières du contrat. Les informations sur le
                contrat proposé sont valables pendant la durée du parcours de souscription jusqu’à signature. <br />
                <br /> Le contrat sera conclu par signature électronique après acceptation des Conditions Générales
                d'Utilisation de la signature électronique par le souscripteur. La prime est payable par prélèvement sur
                votre compte bancaire. La souscription du contrat RC EDPM ne vous permet pas de bénéficier d'un délai de
                renonciation. <br />
                <br /> Les relations précontractuelles et contractuelles sont régies par le droit français et notamment,
                le code des assurances, et seront en langue française. Les modalités d'examen de vos éventuelles
                réclamations et les cas et conditions dans lesquels vous pouvez saisir le service de Médiation des
                assurances ou la plateforme de règlement en ligne des litiges, sont précisées dans les Dispositions
                Générales du contrat.
              </SummaryText>
            </SummaryWrapperText>
          </QuizStepWrapper>
        </Container>
      </Page>
      <Footer />
    </PageContainer>
  );
};

export default Summary;
