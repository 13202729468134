import styled from "styled-components";
import { StyledThemePropsType } from "../../../config/theme/index";
import { media } from "styled-bootstrap-grid";
import { InputLabelTextMobileStyles, LabelStyles } from "../../typography/index";
import { InputStyles, WrapperDisabledDatePicker, WrapperError, WrapperProps, WrapperStyles } from "../TextField/elements";
import { DefaultFlexStyles } from "../../styles/index";

export type DatepickerWrapperProps = {
	disabled?:boolean;
}

export const DatepickerWrapper = styled.div<DatepickerWrapperProps>`
  position: relative;
`;

export const LabelContainer = styled.div`
  margin-bottom: 5px;
  ${InputLabelTextMobileStyles};
  color: ${(props: StyledThemePropsType) => props.theme.colors.primaryText};
  text-transform: uppercase;

  ${media.lg`
    ${LabelStyles};
  `};
`;

export const DatepickerInput = styled.input<WrapperProps>`
  ${InputStyles}
  ${WrapperStyles};
  width: 100%;
  height: 50px;

  ${(props: WrapperProps & StyledThemePropsType & DatepickerWrapperProps) => {
    if (props.error) {
      return WrapperError;
    }
    if (props.disabled) {
			return WrapperDisabledDatePicker;
			
    }
  }};
`;

export const DatepickerIcon = styled.div`
  width: 25px;
  height: 25px;
  position: absolute;
  background: url(${(props: StyledThemePropsType) => props.theme.icons.calendar}) center no-repeat;
  top: 27px;
  right: 15px;
  pointer-events: none;
`;

export const CustomDropDownIcon = styled.div`
  ${DefaultFlexStyles};
  position: absolute;
  right: 0;
  top: 0;
  transform: rotate(90deg);
  width: 50px;
  height: 50px;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  background-color: ${(props: StyledThemePropsType) => props.theme.colors.dropDownColor};
`;
