import { css } from "styled-components";

const DefaultFlexStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DefaultTransition = css`
  transition: all 0.2s ease-out;
`;

export {
  DefaultFlexStyles,
  DefaultTransition
};
