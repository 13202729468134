import React, { FC } from "react";
import styled from "styled-components";
import { ButtonTextDesktopStyles } from "../../typography/index";

const LinkContainer = styled.a`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  ${ButtonTextDesktopStyles};
  text-decoration: none;
`;

export type RedirectLinkProps = {
  children: any;
  href: string;
};

const RedirectLink: FC<RedirectLinkProps> = ({ children, href }) => (
  <LinkContainer href={href}>
    {children}
  </LinkContainer>
);

export default RedirectLink;
