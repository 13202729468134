import React, { ChangeEvent, FC, useRef, useEffect, useCallback } from "react";
import AddressParser from "parse-google-address";
import { Input, LabelContainer, TextFieldContainer, Wrapper } from "./elements";
import withGoogleMapsAPI from "../../../withGoogleMapsAPI";

type AddressAutocompleteProps = {
  label?: string;
  placeholder?: string;
  value?: string | number;
  type?: "text" | "number";
  disabled?: boolean;
	error?: boolean;
  onChange(event: ChangeEvent<HTMLInputElement>): void;
  onSelect(place: Object): void;
};

let autocomplete: google.maps.places.Autocomplete;
let listener;

const AddressAutocomplete: FC<AddressAutocompleteProps> = ({
  label,
  placeholder,
  error,
  disabled,
  type,
	value,
  onChange,
  onSelect,
}) => {
  const initialType = type ? type : "text";
  const placeholderText = placeholder ? placeholder : "";
  const autoCompleteRef = useRef();

  const onPlaceChanged = useCallback(async () => {
    const place = autocomplete.getPlace();
    if (place && place.address_components) {
      const parsedData = await AddressParser.Parse_Reverse_GeoCode_Address(place.address_components);
      onSelect(parsedData);
    }
  }, [onSelect]);

  const initAutocomplete = useCallback(() => {
    autocomplete = new google.maps.places.Autocomplete(autoCompleteRef.current, {
      types: ["geocode"],
      strictBounds: true,
      componentRestrictions: { country: "fr" },
    });
    autocomplete.setFields(["address_component"]);
    listener = autocomplete.addListener("place_changed", onPlaceChanged);
  }, [onPlaceChanged]);

  useEffect(() => {
    initAutocomplete();
    return () => {
      if (listener) {
        listener.remove();
      }
    };
  }, [initAutocomplete]);

  return (
    <TextFieldContainer>
      <LabelContainer>{label?.toUpperCase()}</LabelContainer>
      <Wrapper error={error} disabled={disabled}>
        <Input
          ref={autoCompleteRef}
          name={label}
          type={initialType}
          placeholder={placeholderText}
          disabled={disabled}
					value={value}
					onChange={onChange}
					
        />
      </Wrapper>
    </TextFieldContainer>
  );
};

export default withGoogleMapsAPI(AddressAutocomplete);
