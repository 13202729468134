import React from "react";
import { useHistory } from "react-router-dom";
import { CardButton, CardSection, CardText, CardWrapper, VoucherIcon } from "./elements";

const VoucherCardSection: React.FC = () => {
  const { push } = useHistory();

  return (
    <CardSection>
      <CardWrapper>
        <VoucherIcon />
        <CardText>
          Vous êtes possesseur d'une nouvelle mobilité électrique ?<br />
          Assurez-vous dès aujourd'hui pour rouler serein et en toute légalité.
        </CardText>
        <CardButton
          onClick={() => {
            push("/questionnaire/step1");
          }}
        >
          Découvrir mon assurance
        </CardButton>
      </CardWrapper>
    </CardSection>
  );
};

export default VoucherCardSection;
