import React, { FC } from "react";
import ReactLoader from 'react-loader-spinner';
import styled from "styled-components";
import { selectedTheme} from "../../../themeProviderHOC"
const StyledLoader = styled(ReactLoader)`
  text-align: center;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export type LoaderProps = {
  children?: any;
  isLoading: boolean;
};

const Loader : FC<LoaderProps>  = (props) => {
	const color: string = selectedTheme.colors.primaryText;

  return (
    <>
      {(props.isLoading) ?
      <StyledLoader
        type="TailSpin"
        color={color}
        height={100}
        width={100}
        timeout={0}
      /> : props.children}
    </>
  );
}

export {
  Loader
};
