import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";

type UsePersistedStateReturnValue<T> = [T, Dispatch<SetStateAction<T>>, () => void];

function usePersistedState<T>(key: string, defaultValue: T): UsePersistedStateReturnValue<T> {
  const initValueFromLocalStorage = () => JSON.parse(localStorage.getItem(key));

  const [storage, setStorage] = useState<T>(initValueFromLocalStorage() || defaultValue);

  const clearStorage = useCallback(() => {
    localStorage.clear();
    setStorage(defaultValue);
  }, [defaultValue]);

  useEffect(() => localStorage.setItem(key, JSON.stringify(storage)), [key, storage]);

  return [storage, setStorage, clearStorage];
}

export default usePersistedState;
