import { media } from "styled-bootstrap-grid";
import styled from "styled-components";
import { StyledThemePropsType } from "../../config/theme/index";
import { HexToRgba } from "../../shared/helpers/index";
import { H2Desktop, H3PreFooterStyles, HeroTitleStyles } from "../../shared/typography/index";
import contact from "../../shared/images/contact.jpg";

export const TopImageBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: url(${contact}) no-repeat top;
  background-size: cover;
  width: 100%;
  height: 430px;
  padding: 170px 12px 160px;
  margin-top: 60px;
  
  ${media.xl`
    margin-top: 80px;
    height: 430px;
    padding: 195px 0 135px;
  `};
`;

export const TransparentTextBlock = styled.div`
  border-radius: 5px;
  background-color: ${(props: StyledThemePropsType) => HexToRgba(props.theme.colors.white, 0.5)};
  padding: 12px 20px 18px;
  width: 295px;
  
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: 0.2px;
  text-align: center;
  
  ${media.lg`
    padding: 20px 64px;
    width: fit-content;
    ${HeroTitleStyles};
  `};  
`;

export const ContactInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin: 0 auto;
  padding: 38px 0 44px;
  ${media.lg`
    align-items: center;
    padding: 94px 0 62px;
  `};
  ${media.xl`
    width: 810px;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  `};
`;

export const LeftContactInfo = styled.div`
  display: flex;
  flex-direction: column;  
  align-items: flex-start;
  justify-content: flex-start;
`;

export const ContactImageTitle = styled.div`
  color: ${(props: StyledThemePropsType) => props.theme.colors.primaryTextLight};
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.25px;
  line-height: 1.36;
  ${media.lg`
    line-height: 2.57;    
  `};
  margin-bottom: 18px;
`;

export const ContactImage = styled.div`
  height: 316px;
  width: 401px;
  background: url(${(props: StyledThemePropsType) => props.theme.icons['contact']}) center no-repeat ${(props: StyledThemePropsType) => props.theme.colors.disabled};
  border-radius: 10px;
  display: none;
  ${media.lg`
    display: block;
  `};
`;

export const RightContactInfo = styled(LeftContactInfo)`
  padding-top: 46px;
  max-width: 337px;
`;

export const RightSideTitle = styled.div`
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  font-size: 16px;
  line-height: 1.5;  
  ${media.lg`
    font-size: 18px;
    line-height: 1.67;
  `};  
  color: ${(props: StyledThemePropsType) => props.theme.colors.textStyleMain};
  margin-bottom: 32px;
`;

export const ContactLink = styled.a`
  ${H3PreFooterStyles};
  line-height: 1.67;
  color: ${(props: StyledThemePropsType) => props.theme.colors.gray};
  margin-bottom: 40px;
  ${media.lg`
    margin-bottom: 20px;
  `};
`;

export const ContactText = styled.div`
  ${H3PreFooterStyles};
  line-height: 1.67;
  color: ${(props: StyledThemePropsType) => props.theme.colors.gray};
  margin-bottom: 40px;
  ${media.lg`
    margin-bottom: 20px;
  `};
`;

export const QuestionBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  background-color: ${(props: StyledThemePropsType) => props.theme.colors.disabled};
  padding: 46px 0 60px;
  ${media.xl`
    padding: 53px 0 77px;
  `};
`;

export const QuestionText = styled(H2Desktop)`
  margin: 0 auto 36px;
  width: 280px;
  
  ${media.lg`
    width: 100%;
    margin: 0 auto 46px;
  `};
  text-transform: uppercase;
`;

export const QuestionCards = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  ${media.xl`
    flex-direction: row;
  `};
`;

