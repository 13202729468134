import { media } from "styled-bootstrap-grid";
import styled from "styled-components";
import { StyledThemePropsType } from "../../../config/theme/index";
import { HexToRgba } from "../../helpers/index";
import { DefaultFlexStyles } from "../../styles/index";
import { DefinedColors } from "../../types/index";
import { ErrorPopupTextDesktopStyles } from "../../typography/index";

export type TextColor = {
	textColor: DefinedColors;
};

export type BackgroundColor = {
  backgroundColor: DefinedColors;
};

export type Icon = {
  icon: "success" | "warning" | "error";
  iconColor: DefinedColors;
};

const ModalBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: ${(props: StyledThemePropsType) => HexToRgba(props.theme.colors.darkColor, 0.5)};

  ${media.xl`
    display: none;
  `};
`;

const MessageWrapper = styled.div`
  ${DefaultFlexStyles};
  position: relative;
  border-radius: 2px;
  ${media.lg`
  margin: 30px 0 0; 
  `};
  ${media.xl`
   margin: 0; 
  `};
`;

const CrossWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  ${DefaultFlexStyles};
  z-index: 5;
  cursor: pointer;
  height: 30px;
  width: 30px;

  ${media.lg`
		right: 0px;
		top: -3px;
  `};

  ${media.xl`
    display: none;
  `};
`;

const MessageContainer = styled.div<BackgroundColor>`
  z-index: 4;
  background-color: ${(props: StyledThemePropsType & BackgroundColor) => props.theme.colors[props.backgroundColor]};
  width: 100%;
  padding: 53px 7px;

  ${media.lg`
    border-radius: 15px;
    padding: 14px 20px 18px 11px;
    ${DefaultFlexStyles};
    align-items: center;
  `};
`;

const MessageText = styled.div<TextColor>`
	${ErrorPopupTextDesktopStyles};
  position: relative;
	text-align: center;
	color: ${(props: StyledThemePropsType & TextColor) => props.theme.colors[props.textColor]};
	line-height: 22px;
  ${media.lg`
    ${ErrorPopupTextDesktopStyles};
		text-align: initial;
		line-height: 22px;
  `};
`;

const IconWrapper = styled.div`
  ${DefaultFlexStyles};
  display: none;
  outline: transparent;
  border: none;

  ${media.xl`
    display: flex;
    justify-content: center;
    align-items: center;
  `};
`;

export { MessageWrapper, MessageContainer, ModalBackground, MessageText, IconWrapper, CrossWrapper };
