import React from "react";
import { InsurancePricingPlanDiscountType, InsurancePricingPlanType, TariffTitleVariants } from "../../../api/interfaces";
import { InsuranceTariff } from "../../../pages/subscription/insurance/TariffCard/index";
import { CompletedStep } from "../../index";

export type InsuranceInfoData = CompletedStep & {
	regular: InsuranceTariff;
	premium: InsuranceTariff;
};

type InsuranceContext = InsuranceInfoData & {
	saveInsuranceInfo(modifiedData: InsuranceInfoData): void;
};

export const initialInsuranceInfoData: InsuranceTariff = {
	planId: 3,
	monthlyPrice: (7.45),
	yearlyPrice: (7.45 * 12),
	title: TariffTitleVariants.simple,
	type: InsurancePricingPlanType.REGULAR,
	selected: true,
	isVoucher: false,
	discount: {
		text: '',
		discountedMonths: null,
		initialPayment: 0,
		percentage: 0,
		validityMonths: 0,
		monthsToPay: 0,
		type: InsurancePricingPlanDiscountType.MONTHS,
		isPrepaid: false,
	}
};

export const alternativeInsuranceInfoData: InsuranceTariff = {
	planId: 4,
	monthlyPrice: (11.2),
	yearlyPrice: (11.2 * 12),
	title: TariffTitleVariants.premium,
	type: InsurancePricingPlanType.PREMIUM,
	selected: true,
	isVoucher: false,
	discount: {
		text: '',
		discountedMonths: null,
		initialPayment: 0,
		percentage: 0,
		validityMonths: 0,
		monthsToPay: 0,
		type: InsurancePricingPlanDiscountType.MONTHS,
		isPrepaid: false,
	}
};

export const InsuranceContext = React.createContext<InsuranceContext>({
	regular: { ...initialInsuranceInfoData },
	premium: { ...alternativeInsuranceInfoData },
	completed: false,
	saveInsuranceInfo(modifiedData: InsuranceInfoData): InsuranceInfoData {
		return modifiedData;
	},
});

export const InsuranceContextProvider = InsuranceContext.Provider;
