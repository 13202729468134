import styled from "styled-components";
import { StyledThemePropsType } from "../../../../../config/theme";
import {
  H3Styles,
  H2Styles,
  BottomSingleTextDesktopStyles
} from "../../../../../shared/typography";

export const CardWrapper = styled.div``;

export const Card = styled.div`
  width: 273px;
  height: 369px;
  border-radius: 5px;
  background-color: ${(props: StyledThemePropsType) => props.theme.colors.white};
  padding: 7px 19px 23px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const CardButton = styled.button`
  ${H3Styles};
  display: block;
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
  letter-spacing: 1px;
  background-color: ${(props: StyledThemePropsType) => props.theme.colors.primaryDarkButton};
  color: ${(props: StyledThemePropsType) => props.theme.colors.white};
  box-shadow: 0 2px 34px 0 ${(props: StyledThemePropsType) => props.theme.colors.buttonShadow1};
  width: 256px;
  height: 62px;
  border: none;
  border-radius: 8px;
  margin: 34px auto 0;
  cursor: pointer;
  padding: 0 35px;
  box-sizing: border-box;

	&:hover {
   background-color: ${(props: StyledThemePropsType) => props.theme.colors.primaryDarkButtonHover};
  }
  &:active {
    background-color: ${(props: StyledThemePropsType) => props.theme.colors.primaryDarkButtonActive};
  }
  &:disabled {
    background-color: ${(props: StyledThemePropsType) => props.theme.colors.primaryDarkButtonDisabled};
  }
`;

export const CardTitle = styled.div`
  ${H3Styles};
  color: ${(props: StyledThemePropsType) => props.theme.colors.textStyleMain};
  font-size: 18px;
  line-height: 27px;
`;

export const PlanName = styled.div`
  ${H3Styles};
  color: ${(props: StyledThemePropsType) => props.theme.colors.primaryTextLight};
  font-size: 18px;
  line-height: 27px;
  display: inline;
`;

export const CheckmarksWrapper = styled.div`
  margin: 51px 0 0;
  height: 152px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const CardDivider = styled.div`
  width: 100%;
  height: 1px;
  margin-top: 28px;
  background-color: ${(props: StyledThemePropsType) => props.theme.colors.textStyleMain};
`;

export const UncheckIcon = styled.div`
  width: 16px;
  height: 1px;
  margin: 16px 7px 4px;
`;

export const PriceWrapper = styled.div`
  margin-top: 18px;
  display: flex;
`;

export const PriceText = styled.div`
  ${H2Styles};
  letter-spacing: 0.77px;
  color: ${(props: StyledThemePropsType) => props.theme.colors.primaryTextLight};
  line-height: 30px;
`;

type DescriptionTextProps = {
  bottom?: boolean;
}

export const DescriptionText = styled.div<DescriptionTextProps>`
  ${BottomSingleTextDesktopStyles};
  line-height: 17px;
  color: ${(props: StyledThemePropsType) => props.theme.colors.primaryTextLight};
  letter-spacing: 1px;
  margin: ${(props: DescriptionTextProps) => props.bottom ? '15px 0 0' : '10px 0 0 8px'};
`;
