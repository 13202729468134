import { media } from "styled-bootstrap-grid";
import styled from "styled-components";
import { StyledThemePropsType } from "../../../config/theme/index";
import { SummaryFormItemValueTextStyles } from "../../../shared/typography/index";

export const QuestionCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: ${(props: StyledThemePropsType) => props.theme.colors.white};
  border-radius: 10px;
  box-shadow: 0 2px 34px 0 rgba(29, 37, 45, 0.15);
  width: 280px;
  padding: 25px 20px 18px;
  margin: 10px 0;
  
  ${media.lg`
    margin: 10px 0;
    width: 395px;
    padding: 26px 18px 30px 30px;    
  `};
  
  ${media.xl`
    margin: 0 18px;
  `};
`;

export const QuestionTitle = styled.div`
  font-size: 18px;
  margin-bottom: 12px;
  ${media.lg`
    font-size: 24px;
    margin-bottom: 19px;
  `};
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${(props: StyledThemePropsType) => props.theme.colors.textStyleMain};  
`;

export const QuestionValue = styled.div`
  ${SummaryFormItemValueTextStyles};
  color: ${(props: StyledThemePropsType) => props.theme.colors.textStyleMain};  
  width: 240px;
  margin-bottom: 26px;
  ${media.lg`
    width: 280px;
    margin-bottom: 32px;
  `};
`;

export const QuestionButtonWrapper = styled.div`
  width: 100%;
  ${media.lg`
    width: 170px;
  `};
`;

