import React, { FC } from "react";
import { LabelWithDescription } from "../../../../shared/elements/index";
import { formatStoredDate } from "../../../../shared/helpers/index";
import { Driver } from "../../drivers/mocks";
import {
  ModifierButton,
  PaymentBlockTitle,
  PaymentInfoBlock,
  PaymentInfoBlockBackgroundForTransport,
  PropertyWidthWrapper,
  SeveralPropertiesContainer,
} from "../elements";
import { BasicTransportInfo } from "../../transport";
import createFullDriversBirthday from "../../../../shared/helpers/fullDriversBirthday";
type TransportInfoProps = {
  transport: BasicTransportInfo;
  driversArray: Driver[];
  modify(): void;
};

const TransportInfo: FC<TransportInfoProps> = ({ transport, driversArray, modify }) => {

  return (
    <PaymentInfoBlockBackgroundForTransport>
      <PaymentInfoBlock overflow="auto">
        <PaymentBlockTitle>Votre véhicule</PaymentBlockTitle>
        <LabelWithDescription
          label={`${transport.typeName} ${transport.brandName}`}
          description={`${transport.model} ${transport.serial}`}
        />
        {driversArray.map((drv, index) => {
					const labelName: string = drv.key > 1 ? "Date de naissance" : "Date et lieu de naissance";
          const userNameLabel = drv.title === "primary" ? "Conducteur principal" : "Conducteur sup.";
          const userNameDescription = `${drv.firstName} ${drv.lastName.toUpperCase()}`;
          const dateDescription = drv.key > 1 ? `${formatStoredDate(
            new Date(createFullDriversBirthday(drv.day, drv.month, drv.year))
          )}` : `${formatStoredDate(
            new Date(createFullDriversBirthday(drv.day, drv.month, drv.year))
          )} à ${drv.city}`;
          return (
            <SeveralPropertiesContainer key={index}>
              <PropertyWidthWrapper width={156}>
                <LabelWithDescription label={userNameLabel} description={userNameDescription} />
              </PropertyWidthWrapper>
              <PropertyWidthWrapper width={185}>
                <LabelWithDescription label={labelName} description={dateDescription} />
              </PropertyWidthWrapper>
            </SeveralPropertiesContainer>
          );
        })}
        <ModifierButton onClick={modify}>MODIFIER</ModifierButton>
      </PaymentInfoBlock>
    </PaymentInfoBlockBackgroundForTransport>
  );
};

export default TransportInfo;
