import React, { ReactElement } from "react";
import "react-app-polyfill/stable";
import { ThemeProvider } from "styled-components";
import theme from "./config/theme";
import { primaryTheme } from "./config/theme/primary";
import { thirdTheme } from "./config/theme/third";

import IconsDefaultTheme from "./shared/icons/defaultTheme";
import IconsSecondTheme from "./shared/icons/secondTheme";
import IconsThirdTheme from "./shared/icons/thirdTheme";

interface IThemeProviderHOC {
  children: ReactElement;
}

const defaultIconsTheme = {
  ...IconsDefaultTheme,
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const secondIconsTheme = {
  ...IconsDefaultTheme,
  ...IconsSecondTheme,
};

const thirdIconsTheme = {
  ...IconsDefaultTheme,
  ...IconsThirdTheme,
};

export const IconsThemeContext = React.createContext(IconsDefaultTheme);

const chooseTheme = () => {
  switch (process.env.REACT_APP_THEME_KEY) {
    case "OWEN":
      return { ...primaryTheme, icons: secondIconsTheme };
    case "ONEY":
      return { ...theme, icons: defaultIconsTheme };
    case "COVEA":
      return { ...thirdTheme, icons: IconsThirdTheme };
  }
};

const chooseIconsTheme = () => {
  switch (process.env.REACT_APP_THEME_KEY) {
    case "OWEN":
      return secondIconsTheme;
    case "ONEY":
      return defaultIconsTheme;
    case "COVEA":
      return thirdIconsTheme;
  }
};

export const selectedTheme = chooseTheme();
const selectedIconsTheme = chooseIconsTheme();

const ThemeProviderHOC = ({ children }: IThemeProviderHOC) => {
  return (
    <ThemeProvider theme={selectedTheme}>
      <IconsThemeContext.Provider value={selectedIconsTheme}>{children}</IconsThemeContext.Provider>
    </ThemeProvider>
  );
};

export default ThemeProviderHOC;
