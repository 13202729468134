const gridTheme = {
  gridColumns: 12,
  breakpoints: {
    xl: 1440,
    lg: 768,
    md: 320,
    xs: 320,
  },
  row: {
    // default 15
    padding: 0,
    // 1440 = 0 220
    // 768 = 0 115
    // 320 = 0 15
  },
  col: {
    // default 15
    padding: 0,
  },
  container: {
    padding: 0,
    maxWidth: {
      xl: 1000,
      lg: 538,
      md: 290,
      xs: 290,
    },
  },
};

export default gridTheme;
