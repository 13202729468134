import { IconsType } from "../../shared/icons/defaultTheme";

const colors = {
  /**
   * Text related colors
   */
  primaryText: "#81bc00",
  primaryTextSecond: "#254565",
  darkText: "#000000",
  textStyleMain: "#54565b",
  textStyleFirst: "rgba(84, 86, 91, 0.8)",
  textStyleSecond: "rgba(84, 86, 91, 0.7)",
  textStyleThird: "rgba(84, 86, 91, 0.6)",
  textStyleFourth: "rgba(18, 24, 25, 0.4)",
  highlightedText: "#2f3d40",
  inputTextCaption: "rgba(84, 86, 91, 0.4)",
  tooltipColor: "#54565b",
  errorTextColor: "#bd071e",
  errorBorderColor: "#dde5ed",
  buttonSecondaryText: "rgba(47, 61, 64, 0.6)",
  buttonShadow1: "rgba(29, 37, 45, 0.3)",
  buttonShadow2: "rgba(47, 61, 64, 0.2)",
  shadowColor: "rgba(29, 37, 45, 0.15)",
  opacityBackground: "rgba(84, 86, 91, 0.1)",
  warningText: "#B27300",
  warningBackground: "#F0EDE9",

  /**
   * General colors
   */
  grayBackground: "#EDEDEF",
  formButton: "#ffffff",
  formButtonClicked: "#B2B2BA",
  formButtonSelected: "#0000C9",
  orange: "#FFA92C",
  backgroundLight: "#FFE1B7",
  backgroundDark: "#FAFAFAFF",
  primaryLightButton: "#FFB54A",
  primaryLightButtonActive: "#FFB54A",
  primaryLightButtonHover: "#FFB54A",
  primaryLightButtonDisabled: "#FFB54A",
  primaryDarkButton: "#000093",
  primaryDarkButtonActive: "#000093",
  primaryDarkButtonHover: "#000093",
  primaryDarkButtonDisabled: "#000093",
  primaryLight: "#8bbd07",
  primaryDark: "#739910",
  primaryTextLight: "#81bc00",
  primaryTextDark: "#729900",
  secondary: "#f3f8e6",
  darkColor: "#000000",
  gray: "#54565b",
  darkGray: "#2f3d40",
  disabled: "#fafafa",
  borderLight: "#dde5ed",
  container: "#fafafa",
  white: "#ffffff",
  error: "#bd071e",
  success: "#81bc00",
  successMain: "#597F6D",
  errorBackground: "#fcf3f4",
  placeholder: "#d2d2d3",
  closeButtonBackground: "#ebebeb",
  crossIconColor: "rgba(84, 86, 91, 0.2)",
  dropDownColor: "rgba(47, 61, 64, 0.05)",
  hoverButtonDark: "#222f32",
  lequiteImage: "#939393",
};

const typography = {
  text: {
    fontFamily: "Arial",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
  },

  heroTitle: {
    fontSize: "40px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.5",
    letterSpacing: "0.73px",
  },

  heroDescription: {
    fontSize: "20px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.3",
    letterSpacing: "0.2px",
  },

  h1Desktop: {
    fontSize: "26px",
    fontWeight: "bold",
    lineHeight: "40px",
    letterSpacing: "0.2px",
    color: colors.primaryTextSecond,
  },

  h1Mobile: {
    fontSize: "16px",
    lineHeight: "22px",
    fontWeight: "bold",
    letterSpacing: "0.2px",
    color: colors.primaryTextSecond,
  },

  textHighlighted: {
    color: colors.primaryText,
  },

  buttonTextDesktop: {
    fontSize: "16px",
    fontWeight: "bold",
    lineHeight: "22px",
    letterSpacing: "1.09px",
    textTransform: "uppercase",
    color: colors.white,
    cursor: "pointer",
  },

  buttonTextMobile: {
    letterSpacing: "1px",
    textAlign: "center",
  },

  buttonPrimaryText: {
    color: colors.white,
  },

  buttonSecondaryText: {
    color: colors.buttonSecondaryText,
  },

  tooltipTextDesktop: {
    fontSize: "13px",
    lineHeight: "19px",
    letterSpacing: "0.2px",
    color: colors.textStyleFirst,
  },

  tooltipTextMobile: {
    textAlign: "center",
  },

  // no mobile version for this one
  stepperItemText: {
    fontSize: "12px",
    color: colors.textStyleFourth,
  },

  stepperItemTextHighlighted: {
    color: colors.highlightedText,
  },

  inputLabelTextDesktop: {
    fontSize: "12px",
    letterSpacing: "0.22px",
    color: colors.primaryText,
  },

  inputLabelTextMobile: {
    fontSize: "11px",
    letterSpacing: "0.2px",
  },

  inputValueTextDesktop: {
    fontSize: "16px",
    lineHeight: "22px",
    color: colors.primaryTextSecond,
  },

  inputValueTextMobile: {
    fontSize: "14px",
  },

  inputValueCaptionDesktop: {
    fontSize: "16px",
    fontStyle: "italic",
    lineHeight: "22px",
    color: colors.inputTextCaption,
  },

  inputValueCaptionMobile: {
    fontSize: "14px",
  },

  errorPopupTextDesktop: {
    fontSize: "16px",
    lineHeight: "30px",
  },

  errorPopupTextMobile: {
    lineHeight: "29px",
    letterSpacing: "normal",
    textAlign: "center",
  },

  ActivateCouponTitleDesktop: {
    fontSize: "30px",
    lineHeight: "40px",
    letterSpacing: "0.46px",
    textAlign: "center",
    color: colors.primaryText,
  },

  ActivateCouponTitleMobile: {
    fontSize: "18px",
    fontWeight: "bold",
    lineHeight: "22px",
    letterSpacing: "0.23px",
    textAlign: "center",
    color: colors.primaryText,
  },

  ActivateCouponDescriptionDesktop: {
    fontSize: "22px",
    fontWeight: "bold",
    lineHeight: "32px",
    letterSpacing: "0.17px",
    textAlign: "center",
    color: colors.textStyleMain,
  },

  ActivateCouponDescriptionMobile: {
    fontSize: "16px",
    fontWeight: "bold",
    lineHeight: "22px",
    letterSpacing: "normal",
    textAlign: "center",
    color: colors.textStyleMain,
  },

  // same on all resolutions
  checkboxLabel: {
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "0.2px",
    color: colors.textStyleFirst,
  },

  // same on all resolutions
  underlinedLinkText: {
    fontSize: "10px",
    lineHeight: "27px",
    letterSpacing: "0.18px",
    color: colors.textStyleSecond,
  },

  bottomSingleTextDesktop: {
    fontSize: "16px",
    lineHeight: "21px",
    letterSpacing: "0.2px",
    color: colors.textStyleFirst,
  },

  bottomSingleTextMobile: {
    fontSize: "13px",
    lineHeight: "18px",
    letterSpacing: "normal",
  },

  // same on all resolutions
  summaryFormHeaderText: {
    fontSize: "16px",
    fontWeight: "bold",
    lineHeight: "27px",
    letterSpacing: "0.29px",
    color: colors.primaryText,
  },

  summaryFormItemLabelTextDesktop: {
    fontSize: "12px",
    letterSpacing: "0.22px",
    color: colors.textStyleMain,
  },

  summaryFormItemLabelTextMobile: {
    fontSize: "11px",
    letterSpacing: "0.2px",
  },

  // same on all resolutions
  summaryFormItemValueText: {
    fontSize: "16px",
    lineHeight: "22px",
    color: colors.darkText,
  },

  h2Desktop: {
    fontSize: "22px",
    fontWeight: "bold",
    lineHeight: "34px",
    letterSpacing: "0.4px",
    textAlign: "center",
    color: colors.textStyleMain,
  },

  h2Mobile: {
    fontSize: "16px",
    lineHeight: "22px",
    letterSpacing: "normal",
    fontWeight: "bold",
    textAlign: "center",
    color: colors.textStyleMain,
  },

  h2Highlighted: {
    color: colors.primaryText,
  },

  // legacy styles, need to be removed / updated
  h1CenterDesktop: {
    fontWeight: "bold",
    fontSize: "40px",
    lineHeight: "50px",
    letterSpacing: "0.5px",
    color: colors.darkColor,
  },
  h2: {
    fontWeight: "bold",
    fontSize: "26px",
    lineHeight: "40px",
    letterSpacing: "0.2px",
    color: colors.darkColor,
  },
  h3: {
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "22px",
    letterSpacing: "1px",
    color: colors.darkColor,
  },
  h3PreFooter: {
    fontSize: "18px",
    fontWeight: "bold",
    lineHeight: "1.22",
    letterSpacing: "normal",
    color: colors.highlightedText,
  },
  body: {
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "22px",
    letterSpacing: "0.5px",
    color: colors.darkColor,
  },
  label: {
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "12px",
    letterSpacing: "0.2px",
    color: colors.primaryTextLight,
  },
  headerbutton: {
    fontSize: "18px",
    letterSpacing: "0.3px",
    color: colors.primaryText,
  },

};

const icons: IconsType = {};

export const defaultTheme = {
  colors,
  typography,
  icons,
};
