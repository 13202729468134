import React from "react";
import { BasicAddressFields, AddressSubscriptionField } from "../../../pages/subscription/address/index";
import { CompletedStep } from "../../index";

export type AddressInfoFields = BasicAddressFields & AddressSubscriptionField;

export type AddressInfoData = CompletedStep & AddressInfoFields;

type AddressContext = AddressInfoData & {
  saveAddressInfo(modifiedData: AddressInfoData): void;
};

export const initialAddressInfoData: AddressSubscriptionField & BasicAddressFields =  {
  address: "",
  city: "",
  email: "",
  information: "",
  phone: undefined,
  postalCode: undefined,
  subscription: false,
};

export const AddressContext = React.createContext<AddressContext>({
  ...initialAddressInfoData,
  completed: false,
  saveAddressInfo(modifiedData: AddressInfoData): AddressInfoData {
    return modifiedData;
  },
});

export const AddressContextProvider = AddressContext.Provider;
