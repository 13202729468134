import React from "react";
import { useHistory } from "react-router-dom";
import { Row } from "styled-bootstrap-grid";
import Footer from "../../../shared/elements/Footer";
import { Logo } from "../../../shared/elements/index";
import { useReturnToPreviousPage } from "../../../shared/helpers/useReturnToPreviousPage";
import Page, { Container, PageContainer } from "../../../shared/layout/Page/index";
import { HeaderDescription, HeaderText, QuestionnaireFooterWrapper, EmptyContainer } from "../elements";

const QuestionnaireHeader = (props) => {
  const { location } = useHistory();

  const [goBack] = useReturnToPreviousPage({
    currentRoute: location.pathname,
  });

  return (
    <PageContainer>
      <Logo showGoBack={true} goBack={goBack} />
      <Page fullPage>
        <Container>
          <HeaderText>
            <Row>
              <HeaderDescription>
                Pour vous proposer une offre répondant à vos besoins, nous souhaitons en savoir plus sur vous.
              </HeaderDescription>
            </Row>
          </HeaderText>
          {props.children}
          <EmptyContainer />
          <QuestionnaireFooterWrapper>
            <Footer />
          </QuestionnaireFooterWrapper>
        </Container>
      </Page>
    </PageContainer>
  );
};

export default QuestionnaireHeader;
