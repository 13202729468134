import React, { FC } from "react";
import { Button } from "../../../shared/elements/index";
import { QuestionButtonWrapper, QuestionCardContainer, QuestionTitle, QuestionValue } from "./elements";

type QuestionCardProps = {
  title: string;
  description: string;
  buttonText: string;
  onClick(): void;
};

const QuestionCard: FC<QuestionCardProps> = ({ title, description, buttonText, onClick }) => {
  return (
    <QuestionCardContainer>
      <QuestionTitle>
        {title}
      </QuestionTitle>
      <QuestionValue>
        {description}
      </QuestionValue>
      <QuestionButtonWrapper>
        <Button variant="dark" size="mH" disabled={false} onClick={onClick}>
          {buttonText}
        </Button>
      </QuestionButtonWrapper>
    </QuestionCardContainer>
  );
};

export default QuestionCard;
