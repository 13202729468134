import React from "react";
import { useHistory } from "react-router-dom";
import {
  CardWrapper,
  Card,
  CardButton,
  CardTitle,
  PlanName,
  PriceWrapper,
  PriceText,
  EuroText,
  MonthText,
  DescriptionText,
  FeaturesWrapper,
} from "./elements";
import { Feature } from "./feature";

interface PlanCardMobileProps {
  buttonText: string;
  tariffName: string;
  checks: boolean[];
  price: number;
  wholePrice: number;
  url: string;
}

const PlanCardMobile: React.FC<PlanCardMobileProps> = ({ buttonText, tariffName, price, checks, url }) => {
  const { push } = useHistory();

  const priceFormatted = price.toFixed(2).replace(".", ",");
  const yearlyPrice = (price * 12).toFixed(2).replace(".", ",");
  const isPremiumTariff: boolean = tariffName === "PREMIUM";

  return (
    <CardWrapper>
      <Card>
        <CardTitle>
          Formule &nbsp;
          <PlanName>{tariffName}</PlanName>
        </CardTitle>
        <PriceWrapper>
          <PriceText>{priceFormatted}</PriceText>
          <EuroText>€</EuroText>
          <MonthText>/mois</MonthText>
        </PriceWrapper>
        <DescriptionText>soit {yearlyPrice} € pour un an</DescriptionText>
        <FeaturesWrapper>
          <Feature text="Responsabilité civile" active={checks[0]} />
          <Feature text="Garantie Défense pénale et Recours suite à accident" active={checks[1]} />
          {isPremiumTariff ? (
            <Feature
              text="Garantie Protection Corporelle du Conducteur"
              active={checks[2]}
              isPremiumTariff={isPremiumTariff}
            />
          ) : null}
        </FeaturesWrapper>
      </Card>
      <CardButton
        onClick={() => {
          push(url);
        }}
      >
        {buttonText}
      </CardButton>
    </CardWrapper>
  );
};

export default PlanCardMobile;
