import { useMutation } from "@apollo/react-hooks";
import { ExecutionResult } from "graphql";
import React, { FC, useCallback, useContext, useLayoutEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import VALIDATE_QUESTION_ANSWER from "../../../api/mutations/validateQuestionAnswer";
import { Message, Tooltip } from "../../../shared/elements/index";
import { useRedirection } from "../../../shared/helpers";
import { QuestionnaireContext, QuestionnaireDate } from "../../../store/context/questionnaire/questionnaire";
import { dialogQuestionsOptions, SecondStepError, ThirdStepError } from "../configuration";
import {
  ContentHeader,
  DialogButton,
  DialogButtonsContainer,
  DialogButtonSpacing,
  DialogPagesContainer,
  TransportIcon,
  MessageWrapper,
  HiddenWrapper,
  ContentHeaderWrapper,
  TooltipWrapper,
} from "../elements";
import { DialogStepProps } from "../Step2";
import { ValidateQuestionMutationResponse, ValidateQuestionAnswerVariables } from "../../../api/interfaces";
import { QuestionnaireStep2Options } from "../types";

export type ErrorMessage = {
  display: boolean;
  message: string | JSX.Element;
};

const Step3: FC<DialogStepProps> = () => {
  useLayoutEffect(() => window.scrollTo({ top: 0, behavior: "smooth" }), []);
  const { push } = useHistory();
  //const { path } = useRouteMatch<RouteParams>();
  //const modelIndex = Number(path.split("/questionnaire/step")[1]);
  const [nextRoute] = useRedirection({ currentRoute: "step3", nextRoute: "step4" });

  /**
   * Logic related to the storing page-data in the state.
   */
  const { saveQuestionnaireInfo, ...questionnaireContextInfo } = useContext(QuestionnaireContext);

  const [questionnaireInfo] = useState<QuestionnaireDate>({ ...questionnaireContextInfo });

  const options: QuestionnaireStep2Options[] = [...dialogQuestionsOptions];

  /**
   * Logic related to the storing page-data in the state.
   */
  const [error, setError] = useState<ErrorMessage>({ display: false, message: SecondStepError() });

  const hideErrorMessage = useCallback(() => setError({ ...error, display: false }), [error]);

  /**
   * Logic related to the validation process and page submitting.
   */
  const [validateQuestion] = useMutation<ValidateQuestionMutationResponse, ValidateQuestionAnswerVariables>(
    VALIDATE_QUESTION_ANSWER
  );
  const onRedirect = useCallback(() => push("/questionnaire/step1"), [push]);

  const onResetError = useCallback(() => setError({ display: false, message: "" }), [setError]);
  const submit = useCallback(
    (optionId: number) => async () => {
      await validateQuestion({ variables: { optionId, questionId: 3 } })
        .then(({ data: { validateQuestionAnswer } }: ExecutionResult<ValidateQuestionMutationResponse>) => {
          if (validateQuestionAnswer.valid) {
            setError({ ...error, display: false });
            saveQuestionnaireInfo({
              ...questionnaireInfo,
              thirdStepCompleted: true,
            });
            push(nextRoute);
          } else {
            setError({ display: true, message: ThirdStepError(onRedirect, onResetError) });
          }
        })
        .catch((e) => ({ e }));
    },
    [validateQuestion, error, saveQuestionnaireInfo, questionnaireInfo, push, nextRoute, onRedirect, onResetError]
  );

  /**
   * General logic and variables needed to define render of something.
   */
  const showErrorComponent = error.display && !!error.message;

  // TODO change index after adding on back-end
  // const question = data.questions[modelIndex - 2] && data.questions[modelIndex - 2].questionText;

  return (
    <>
      <ContentHeaderWrapper>
        <ContentHeader>L’usage de votre véhicule est-il exclusivement privé ?</ContentHeader>
        <TooltipWrapper>
          <Tooltip
            rightPosition="bottom"
            text={
              "Vous utilisez votre véhicule uniquement dans le cadre de trajets privés ou trajet/travail (c'est à dire en dehors de toute activité professionnelle)"
            }
          />
        </TooltipWrapper>
      </ContentHeaderWrapper>
      <DialogPagesContainer>
        <DialogButtonsContainer>
          {/* In future refactor to mapping. */}
          <DialogButton fullWidth={true} size="s" variant="white" active={false} disabled={false} onClick={submit(1)}>
            {options[0].name}
          </DialogButton>
          <DialogButtonSpacing>
            <DialogButton fullWidth={true} size="s" variant="white" active={false} disabled={false} onClick={submit(2)}>
              {options[1].name}
            </DialogButton>
          </DialogButtonSpacing>
          <TransportIcon icon={questionnaireContextInfo.vehicle} />
        </DialogButtonsContainer>
        {showErrorComponent ? (
          <>
            <HiddenWrapper />
            <MessageWrapper>
              <Message
                close={hideErrorMessage}
                icon="error"
                iconColor="error"
                backgroundColor="errorBackground"
                textColor="error"
                message={error.message}
              />
            </MessageWrapper>{" "}
          </>
        ) : null}
      </DialogPagesContainer>
    </>
  );
};

export default Step3;
