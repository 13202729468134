import { useQuery } from "@apollo/react-hooks";
import React, { ChangeEvent, useCallback, useContext, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { GetProductsQueryResult } from "../../../api/interfaces";
import GET_PRODUCTS from "../../../api/queries/getProducts";
import { Button, CustomCheckBox, Dropdown, Logo, Stepper, TextField, Tooltip } from "../../../shared/elements";
import { DropdownOption } from "../../../shared/elements/Dropdown/index";
import Footer from "../../../shared/elements/Footer";
import { Message } from "../../../shared/elements/index";
import { stepperMock } from "../../../shared/elements/Stepper/mock";
import { useRedirection } from "../../../shared/helpers/index";
import Page, { Container, PageContainer } from "../../../shared/layout/Page";
import { transportSchema } from "../../../shared/validation/transport";
import { QuestionnaireContext } from "../../../store/context/questionnaire/questionnaire";
import { initialTransportInfoData, PersonalTransportContext } from "../../../store/context/subscription/transport";
import { Vehicles } from "../../questionnaire/configuration/vehicles";
import { ErrorMessage } from "../../questionnaire/Step3/index";
import { QuizStepTitle, QuizStepWrapper } from "../elements";
import {
  ButtonWrapper,
  CheckboxWrapper,
  ContentWrapper,
  CustomSpan,
  ElementsWrapper,
  FieldsWrapper,
  HiddenWrapper,
  IconContainer,
  MessageWrapper,
  PolicyText,
  SingleField,
  TooltipWrapper,
  SingleFieldWrapper,
} from "./elements";

export type BasicTransportInfo = {
  type: number;
  brand: number;
  typeName: Vehicles;
  brandName: string;
  model: string;
  serial: string;
  speedLimitPolicy: boolean;
};

const TransporterInfo = () => {
  useLayoutEffect(() => window.scrollTo({ top: 0, behavior: "smooth" }), []);

  /**
   * Logic related to the routing actions.
   */

  const { push } = useHistory();

  const [nextRoute, prevRoute] = useRedirection({
    currentRoute: "transport",
    nextRoute: "drivers",
    prevRoute: "insurance",
  });

  const back = useCallback(() => push(prevRoute), [prevRoute, push]);

  /**
   * Logic related to the storing page-data in the state.
   */
  const { data, loading } = useQuery<GetProductsQueryResult>(GET_PRODUCTS);
  const dropdownOptionsTransform = (productsData: GetProductsQueryResult) => {
    return {
      productsOptions: productsData?.products.map((product) => {
        if (product.name.match(/ /g)) {
          return {
            label: product.name.slice(0, product.name.indexOf(" ")).toLowerCase(),
            value: product.id,
          };
        } else {
          return {
            label: product.name.toLowerCase(),
            value: product.id,
          };
        }
      }),
      brandsOptions: productsData?.products.map((product) => ({
        id: product.id,
        brands: product.brands.map((brandValue) => ({
          label: brandValue.name,
          value: brandValue.id,
        })),
      })),
    };
  };

  const { vehicle, saveQuestionnaireInfo, ...questionnaireData } = useContext(QuestionnaireContext);
  const { brand, model, typeName, brandName, serial, type, speedLimitPolicy, savePersonalTransportInfo } = useContext(
    PersonalTransportContext
  );
  const [vehicleFields, setVehicleFields] = useState<BasicTransportInfo>({
    brand,
    model,
    serial,
    type,
    typeName,
    brandName,
    speedLimitPolicy,
  });

  const typeDropdownOnChange = useCallback(
    (option: DropdownOption) => {
      const label =
        option.label === "trottinette électrique"
          ? (option.label = "trottinette")
          : option.label === "e-skateboard"
          ? (option.label = "skateboard")
          : option.label;
      setVehicleFields((prev) => ({
        ...prev,
        type: option.value,
        brand: 0,
        typeName: Vehicles[label],
        brandName: "",
      }));
      return saveQuestionnaireInfo({ ...questionnaireData, vehicle: Vehicles[label] });
    },
    [questionnaireData, saveQuestionnaireInfo]
  );

  const brandDropdownsOnChange = useCallback(
    (field) => (option: DropdownOption) => {
      return setVehicleFields((prev) => ({
        ...prev,
        [field]: option.value,
        [`${field}Name`]: option.label,
      }));
    },
    []
  );

  const inputsOnChange = useCallback(
    (field) => (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      return setVehicleFields((prev) => ({ ...prev, [field]: value }));
    },
    []
  );

  const speedCheckBox = useCallback(() => {
    setVehicleFields({ ...vehicleFields, speedLimitPolicy: !vehicleFields.speedLimitPolicy });
  }, [vehicleFields]);

  /**
   * Logic related to the setting data in the context and move forward to the next step.
   */
  const saveTransportInfoContext = useCallback(() => {
    savePersonalTransportInfo({ ...vehicleFields, completed: true });
    push(nextRoute);
  }, [nextRoute, push, savePersonalTransportInfo, vehicleFields]);

  /**
   * Logic related to the storing page-data in the state.
   */
  const [error, setError] = useState<ErrorMessage>({
    display: false,
    message: "Vous devez attester sur l’honneur avant de poursuivre.",
  });

  const hideErrorMessage = useCallback(() => setError({ ...error, display: false }), [error]);

  const showErrorComponent = error.display && !!error.message;

  /**
   * Logic related to the validation process and page submitting.
   */
  const [validationErrors, setValidationErrors] = useState(initialTransportInfoData);
  const submitTransportInfo = useCallback(async () => {
    const validationPassed = await transportSchema
      .validate(vehicleFields, { abortEarly: false })
      .then(() => {
        setValidationErrors({ ...initialTransportInfoData });
        return true;
      })
      .catch((e) => {
        const errors: any = { ...initialTransportInfoData };
        e.inner.map((err: yup.ValidationError) => (errors[err.path] = err.message));
        setValidationErrors({ ...errors });
        return false;
      });

    if (validationPassed) {
      saveTransportInfoContext();
    }
    if (!vehicleFields.speedLimitPolicy) {
      setError({ display: true, message: error.message });
    } else {
      setError({ ...error, display: false });
    }
  }, [saveTransportInfoContext, vehicleFields, error]);

  /**
   * General logic and variables needed to define render of something.
   */
  const { productsOptions, brandsOptions: rawOptions } = dropdownOptionsTransform(data);

  const typeDropdownVehicleValue = useMemo(() => {
    return loading
      ? undefined
      : productsOptions.map((item) => {
          if (item.label === "trottinette") item.label = "trottinette électrique";
          if (item.label === "skateboard") item.label = "e-skateboard";
          return item;
        });
  }, [loading, productsOptions]);

  const typeDropdownValue = useMemo(() => {
    return loading
      ? undefined
      : typeDropdownVehicleValue.find(({ label }) => {
          if (label === "trottinette électrique") return "trottinette" === vehicle;
          if (label === "e-skateboard") return "skateboard" === vehicle;
          return label === vehicle;
        });
  }, [loading, typeDropdownVehicleValue, vehicle]);

  const brandsOptions = useMemo(() => {
    return !typeDropdownValue ? [] : rawOptions.find(({ id }) => id === typeDropdownValue.value).brands;
  }, [rawOptions, typeDropdownValue]);

  useEffect(() => {
    if (!brand || !brandsOptions || !rawOptions || !typeDropdownValue || vehicleFields.brandName) return;
    const options = rawOptions.find(({ id }) => id === typeDropdownValue?.value).brands;
    const choosedBrand = options.filter((oneBrand) => {
      return +oneBrand.value === +brand;
    });
    setVehicleFields((prev) => ({
      ...prev,
      brand: choosedBrand[0]?.value,
      brandName: choosedBrand[0]?.label,
    }));
  }, [brand, brandsOptions, rawOptions, typeDropdownValue, vehicleFields.brand, vehicleFields.brandName]);

  const brandDropdownValue = useMemo(() => {
    return loading ? null : brandsOptions.find(({ value }) => Number(value) === Number(vehicleFields?.brand));
  }, [brandsOptions, loading, vehicleFields]);

  const finalBrandDropdownValue = useMemo(() => {
    return !!vehicleFields.brandName ? brandDropdownValue : null;
  }, [vehicleFields.brandName, brandDropdownValue]);

  const upperCaseBrandsOptions = useMemo(() => {
    return brandsOptions.map((opt) => {
      return { label: opt.label.toUpperCase(), value: opt.value };
    });
  }, [brandsOptions]);

  return (
    <PageContainer>
      <Logo showGoBack goBack={back} />
      <Stepper steps={stepperMock.steps} currentlySelected={1} />
      <Page>
        <Container>
          <QuizStepWrapper>
            <QuizStepTitle>Merci de nous fournir un peu plus de détails sur votre véhicule</QuizStepTitle>
            <ContentWrapper>
              <IconContainer icon={Vehicles[vehicle]} />
              <FieldsWrapper>
                {/*TODO: Add validation or input mask.*/}
                <SingleField>
                  <Dropdown
                    isDisabled={loading}
                    label="TYPE DE VÉHICULE EDPM"
                    placeholder="Sélectionner une option"
                    options={typeDropdownVehicleValue}
                    onChange={typeDropdownOnChange}
                    value={typeDropdownValue}
                    error={!!validationErrors.type && !!!vehicleFields.typeName}
                    upperCaseOptions
                  />
                </SingleField>
                <SingleField>
                  <Dropdown
                    isDisabled={loading || !typeDropdownValue}
                    label="Marque du véhicule"
                    placeholder="Sélectionner une option"
                    options={upperCaseBrandsOptions}
                    onChange={brandDropdownsOnChange("brand")}
                    value={finalBrandDropdownValue}
                    error={!!validationErrors.brandName}
                    upperCaseOptions={true}
                  />
                </SingleField>
              </FieldsWrapper>
              <FieldsWrapper>
                <SingleField>
                  <TextField
                    label="Modèle de véhicule"
                    placeholder="M 456"
                    initialVisibility={true}
                    onChange={inputsOnChange("model")}
                    value={vehicleFields.model}
                    error={!!validationErrors.model}
                    capitalize="capitalize"
                  />
                </SingleField>

                <SingleField>
                  <SingleFieldWrapper>
                    <TooltipWrapper>
                      <Tooltip
                        rightPosition="top"
                        text={
                          "Le numéro de série est souvent situé sur l'étiquette ou la gravure sous le véhicule ou alors dans l’application de votre véhicule."
                        }
                      />
                    </TooltipWrapper>
                    <TextField
                      label="N° de série"
                      placeholder="456 789 098"
                      initialVisibility={true}
                      onChange={inputsOnChange("serial")}
                      value={vehicleFields.serial}
                      error={!!validationErrors.serial}
                      capitalize="uppercase"
                    />
                  </SingleFieldWrapper>
                </SingleField>
              </FieldsWrapper>
            </ContentWrapper>
            <CheckboxWrapper>
              <label>
                <CustomCheckBox disabled={false} checked={vehicleFields.speedLimitPolicy} onChange={speedCheckBox} />
                <CustomSpan error={!!validationErrors.speedLimitPolicy} />
              </label>
              <PolicyText onClick={speedCheckBox} error={!!validationErrors.speedLimitPolicy}>
                J’atteste que mon véhicule ne dépasse pas la vitesse de 25km/h ou si le véhicule a été acheté avant le
                26/10/2019 et dont la vitesse sortie d’usine dépasse les 25 km/h, être en possession du certificat de
                mise en conformité (bridage) émanant du distributeur ou du revendeur. Ce certificat me sera demandé en
                cas de sinistre.
              </PolicyText>
            </CheckboxWrapper>
            <ElementsWrapper>
              <ButtonWrapper>
                <Button
                  fullWidth={true}
                  variant="dark"
                  size="m"
                  active={vehicleFields.speedLimitPolicy}
                  disabled={false}
                  onClick={submitTransportInfo}
                >
                  Étape suivante
                </Button>
              </ButtonWrapper>
              {showErrorComponent && (
                <>
                  <HiddenWrapper />
                  <MessageWrapper>
                    <Message
                      close={hideErrorMessage}
                      icon="error"
                      iconColor="error"
                      backgroundColor="errorBackground"
                      textColor="error"
                      message={error.message}
                    />
                  </MessageWrapper>
                </>
              )}
            </ElementsWrapper>
          </QuizStepWrapper>
        </Container>
      </Page>
      <Footer />
    </PageContainer>
  );
};

export default TransporterInfo;
