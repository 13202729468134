import { useApolloClient } from "@apollo/react-hooks";
import React, { useCallback, useContext, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { GetVoucherQueryResult, GetVoucherQueryVariables } from "../../../api/interfaces";
import GET_VOUCHER from "../../../api/queries/getVoucher";
import { CustomIcon, Tooltip } from "../../../shared/elements";
import { MASKED } from "../../../shared/helpers/variables";
import { AddressContext } from "../../../store/context/subscription/address";
import { DriversContext } from "../../../store/context/subscription/drivers";
import { PaymentContext } from "../../../store/context/subscription/payment";
import { RedirectionContext } from "../../../store/context/subscription/redirection";
import { PersonalTransportContext } from "../../../store/context/subscription/transport";
import { DefaultLink } from "../automatic/elements";
import {
  CouponContainer,
  CouponIconContainer,
  CouponText,
  DescriptionText,
  DescriptionTextContainer,
  SubmitButton,
  TitleText,
  TooltipWrapper,
  VoucherInputSingle,
} from "../elements";

interface ManualVoucher {
  voucher: string;
  status: string | JSX.Element;
  isValid: boolean;
}

const ManualVoucherActivation = () => {
  useLayoutEffect(() => window.scrollTo({ top: 0, behavior: "smooth" }), []);

  const client = useApolloClient();

  const { push } = useHistory();

  const { clearPersistedStorage } = useContext(RedirectionContext);

  useEffect(() => clearPersistedStorage(), [clearPersistedStorage]);

  const { savePersonalTransportInfo, ...transportParams } = useContext(PersonalTransportContext);

  const { saveAddressInfo, ...addressParams } = useContext(AddressContext);

  const { savePaymentInfo, ...paymentParams } = useContext(PaymentContext);

  const { saveDriversInfo, driversResponsibilityPolicy, completed } = useContext(DriversContext);

  const [state, setState] = useState<ManualVoucher>({
    voucher: "",
    status: "",
    isValid: true,
  });

  const [errorFlag, setErrorFlag] = useState<boolean>(false);

  const [result, setResult] = useState<boolean>(false);

  const handleInputChange = useCallback(
    (e) => {
      setState({ ...state, voucher: e.target.value });
      setResult(false);
    },
    [state]
  );

  const isVoucherShort: boolean = useMemo(() => {
    const clearVoucher = state.voucher.split("_")[0];
    if (state.voucher.length < 1) return true;
    return clearVoucher.length < 19;
  }, [state.voucher]);

  useEffect(() => {
    if (isVoucherShort) {
      setState({ isValid: true, status: "", voucher: state.voucher });
    }
  }, [isVoucherShort, state.voucher]);

  const handleButtonClick = useCallback(async () => {
    try {
      const { data } = await client.query<GetVoucherQueryResult, GetVoucherQueryVariables>({
        query: GET_VOUCHER,
        variables: {
          voucher: state.voucher,
        },
      });

      const voucher = data?.voucher;
      setState({ ...state, status: voucher.discount, isValid: true });
      setErrorFlag(true);
      const {
        productTypeId,
        productModel,
        productSerial,
        productBrandId,
        email,
        mobile,
        lastName,
        firstName,
        discount,
      } = voucher;
      savePersonalTransportInfo({
        ...transportParams,
        type: productTypeId,
        brand: productBrandId,
        model: productModel,
        serial: productSerial,
      });
      saveAddressInfo({
        ...addressParams,
        email,
        phone: `0${mobile}`.replace(/\d{2}(?=.)/g, '$& '),
      });

      savePaymentInfo({
        ...paymentParams,
        discount,
        voucher: state.voucher,
        userName: firstName ? `${lastName} ${firstName}` : "",
        firstName: firstName || "",
        lastName: lastName || "",
      });

      saveDriversInfo({
        completed,
        driversResponsibilityPolicy,
        drivers: [
          {
            lastName,
            firstName,
            key: 1,
            title: "primary",
            city: "",
            country: "",
            day: undefined,
            month: undefined,
            year: undefined,
          },
        ],
      });
    } catch (errors) {
      setState({
        ...state,
        status: (
          <span>
            Oups, le code saisi a déjà été utilisé, vous pouvez toujours souscrire à&nbsp;
            <DefaultLink to="/questionnaire/step1" manual>
              l’assurance sans coupon
            </DefaultLink>
            &nbsp;ou consulter&nbsp;
            <DefaultLink to="/" manual>
              nos autres offres
            </DefaultLink>
          </span>
        ),
        isValid: false,
      });
      setErrorFlag(false);
    }
  }, [
    state,
    client,
    savePersonalTransportInfo,
    transportParams,
    saveAddressInfo,
    addressParams,
    savePaymentInfo,
    paymentParams,
    saveDriversInfo,
    completed,
    driversResponsibilityPolicy,
  ]);

  useEffect(() => {
    if (!isVoucherShort && !result) {
      handleButtonClick().then(() => {
        setResult(true);
      });
    }
  }, [isVoucherShort, handleButtonClick, result]);

  const redirect = useCallback(() => push(`/voucher/${state.voucher}`), [push, state.voucher]);

  const disabledButton = useMemo(() => isVoucherShort || !result || !state.isValid, [
    isVoucherShort,
    state.isValid,
    result,
  ]);

  return (
    <>
      <TitleText>Activez votre coupon !</TitleText>
      <DescriptionTextContainer>
        <DescriptionText>
          Il est temps de profiter de votre avantage. Saisissez les 16 caractères de votre coupon.
        </DescriptionText>
        <TooltipWrapper>
          <Tooltip
            text={
              "Utilisez le code d'activation dans l'email ou SMS qui vous a été envoyé ou qui se trouve dans la version papier remise en magasin"
            }
          />
        </TooltipWrapper>
      </DescriptionTextContainer>
      <CouponContainer>
        <CouponIconContainer>
          <CustomIcon icon="coupon" iconColor="primaryTextLight" width="100" height="65" />
        </CouponIconContainer>
        <VoucherInputSingle
          alwaysShowMask={true}
          isValid={state.isValid}
          mask={MASKED}
          value={state.voucher}
          onChange={handleInputChange}
        />
        <CouponText isValid={errorFlag}>{state.status}</CouponText>
      </CouponContainer>
      <SubmitButton variant="dark" size="l" active={true} disabled={disabledButton} onClick={redirect}>
        confirmer mon coupon
      </SubmitButton>
    </>
  );
};

export default ManualVoucherActivation;
