import { useQuery } from "@apollo/react-hooks";
import React, { useCallback, useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import Slider, { Settings } from "react-slick";
import { GetPlansQueryResult, GetPlansQueryVariables, InsurancePricingPlanType } from "../../../api/interfaces/index";
import GET_PLANS from "../../../api/queries/getPlans";
import Footer from "../../../shared/elements/Footer";
import { CustomIcon, Logo, RulesContainer, Stepper } from "../../../shared/elements/index";
import { stepperMock } from "../../../shared/elements/Stepper/mock";
import { useRedirection } from "../../../shared/helpers/useRedirection";
import Page, { Container, PageContainer } from "../../../shared/layout/Page/index";
import { AddressContext } from "../../../store/context/subscription/address";
import { DriversContext } from "../../../store/context/subscription/drivers";
import { InsuranceContext } from "../../../store/context/subscription/insurance";
import { PaymentContext } from "../../../store/context/subscription/payment";
import { PersonalTransportContext } from "../../../store/context/subscription/transport";
import { InformationLink } from "../../home/elements";
import { QuizStepTitle, QuizStepWrapper } from "../elements";
import { TariffCardWrapper, TariffsWrapper, InsurersWrapper } from "./elements";
import { TabsMenu } from "./TariffCard/elements";
import TariffCard from "./TariffCard/index";
import { advantages } from "./TariffCard/mocks";
import Tab from "./TariffCard/Tab";
import { getFormulaType } from "../../../shared/helpers";

const Insurance = () => {
  useLayoutEffect(() => window.scrollTo({ top: 0, behavior: "smooth" }), []);
  const slideRef = useRef<Slider>();
  /**
   * Logic related to the routing actions.
   */
  const { completed: transportStepCompleted } = useContext(PersonalTransportContext);
  const { completed: insuranceStepCompleted } = useContext(InsuranceContext);
  const { completed: driversStepCompleted } = useContext(DriversContext);
  const { completed: addressStepCompleted } = useContext(AddressContext);
  const { completed: paymentStepCompleted } = useContext(PaymentContext);

  const flowComppleted: boolean = useMemo(
    () =>
      transportStepCompleted &&
      insuranceStepCompleted &&
      driversStepCompleted &&
      addressStepCompleted &&
      paymentStepCompleted,
    [addressStepCompleted, driversStepCompleted, insuranceStepCompleted, paymentStepCompleted, transportStepCompleted]
  );
  const { push } = useHistory();
  const [nextRoute, prevRoute] = useRedirection({
    currentRoute: "insurance",
    nextRoute: "drivers",
    prevRoute: "questionnaire/step5",
  });
  const back = useCallback(() => push(prevRoute), [prevRoute, push]);

  const [currentSlider, setCurrentSlider] = useState(0);

  const sliderSettings: Settings = useMemo(() => {
    return {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 2,
      responsive: [
        {
          breakpoint: 1440,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: false,
            dots: false,
          },
        },
        {
          breakpoint: 1439,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false,
            infinite: false,
            afterChange: (current) => {
              setCurrentSlider(current);
            },
          },
        },
      ],
    };
  }, []);
  /**
   * Fetching actual plans data.
   */
  const { saveInsuranceInfo, completed, ...insuranceInfo } = useContext(InsuranceContext);

  const { savePaymentInfo, ...paymentContext } = useContext(PaymentContext);

  const { data, loading } = useQuery<GetPlansQueryResult, GetPlansQueryVariables>(GET_PLANS, {
    variables: { voucher: paymentContext.voucher },
  });
  const [firstLoad, setFirstLoad] = useState<boolean>(true);
  useEffect(() => {
    if (!loading && firstLoad && data) {
      setFirstLoad(false);
      const {
        plans: { premium: premiumPlan, regular: regularPlan },
      } = data;
      let fetchedRegular = {
        planId: regularPlan.id,
        monthlyPrice: regularPlan.monthlyPrice,
        yearlyPrice: regularPlan.yearlyPrice,
        title: regularPlan.title,
        type: regularPlan.type,
        selected: false,
        isVoucher: false,
        discount: regularPlan.discount,
      };
      let fetchedPremium = {
        planId: premiumPlan.id,
        monthlyPrice: premiumPlan.monthlyPrice,
        yearlyPrice: premiumPlan.yearlyPrice,
        title: premiumPlan.title,
        type: premiumPlan.type,
        selected: true,
        isVoucher: false,
        discount: premiumPlan.discount,
      };

      if (premiumPlan.discount && regularPlan.discount) {
        fetchedRegular = {
          ...fetchedRegular,
          discount: {
            text: regularPlan.discount.text,
            discountedMonths: regularPlan.discount.discountedMonths,
            initialPayment: regularPlan.discount.initialPayment,
            percentage: regularPlan.discount.percentage,
            validityMonths: regularPlan.discount.validityMonths,
            monthsToPay: regularPlan.discount.monthsToPay,
            type: regularPlan.discount.type,
            isPrepaid: regularPlan.discount.isPrepaid,
          },
        };
        fetchedPremium = {
          ...fetchedPremium,
          discount: {
            text: premiumPlan.discount.text,
            discountedMonths: premiumPlan.discount.discountedMonths,
            initialPayment: premiumPlan.discount.initialPayment,
            percentage: premiumPlan.discount.percentage,
            validityMonths: premiumPlan.discount.validityMonths,
            monthsToPay: premiumPlan.discount.monthsToPay,
            type: premiumPlan.discount.type,
            isPrepaid: premiumPlan.discount.isPrepaid,
          },
        };
      }

      saveInsuranceInfo({
        ...insuranceInfo,
        completed,
        regular: { ...fetchedRegular },
        premium: { ...fetchedPremium },
      });
    }
  }, [completed, data, firstLoad, insuranceInfo, loading, saveInsuranceInfo, paymentContext.voucher]);

  const { regular, premium } = insuranceInfo;

  /**
   * Logic related to the storing page-data in the state.
   */
  const currentTariff = regular.selected ? regular.type : premium.type;
  const [tariff, setTariff] = useState<string>(currentTariff);
  const pickTariff = useCallback((value: string) => {
    setTariff(value);
  }, []);

  useEffect(() => {
    const currentSliderString: string =
      currentSlider === 0 ? InsurancePricingPlanType.REGULAR : InsurancePricingPlanType.PREMIUM;

    setTariff(currentSliderString);
  }, [setTariff, currentSlider]);

  const tabSelect = useCallback(
    (value: InsurancePricingPlanType) => {
      const slideNames = [regular.type, premium.type];
      slideRef.current.slickGoTo(slideNames.indexOf(value));
      pickTariff(value);
    },
    [regular.type, premium.type, pickTariff]
  );

  /**
   * Logic related to the validation process and page submitting.
   */
  const isVoucher: boolean = !!paymentContext.voucher;
  const submitInsuranceInfo = useCallback(
    (type: InsurancePricingPlanType) => () => {
      const validationPassed = regular.selected || premium.selected;

      savePaymentInfo({ ...paymentContext, formula: getFormulaType(type) });

      if (validationPassed) {
        saveInsuranceInfo({
          regular: { ...regular, selected: type === regular.type },
          premium: { ...premium, selected: type === premium.type },
          completed: true,
        });
        if (!flowComppleted) push(nextRoute);
        push("/subscription/summary");
      }
      if (!isVoucher && validationPassed) {
        saveInsuranceInfo({
          regular: { ...regular, selected: type === regular.type },
          premium: { ...premium, selected: type === premium.type },
          completed: true,
        });
        if (!flowComppleted) push(nextRoute);
        push("/subscription/summary");
      }
    },
    [nextRoute, flowComppleted, premium, push, regular, saveInsuranceInfo, isVoucher, paymentContext, savePaymentInfo]
  );

  return (
    <PageContainer>
      <Logo showGoBack={true} goBack={back} />
      <Stepper steps={stepperMock.steps} currentlySelected={0} />
      <Page>
        <Container>
          <QuizStepWrapper>
            <QuizStepTitle centered>
              Merci ! Après analyse, nous vous proposons deux formules{" "}
              <InformationLink href="#rules"> (1) </InformationLink>{" "}
            </QuizStepTitle>
            <TabsMenu>
              <Tab onClick={tabSelect} activeTariff={tariff} tabTariff={regular.type} />
              <Tab onClick={tabSelect} activeTariff={tariff} tabTariff={premium.type} />
            </TabsMenu>
            <TariffsWrapper>
              <Slider ref={slideRef} {...sliderSettings}>
                <TariffCardWrapper cardNumber={1}>
                  <TariffCard
                    planId={regular.planId}
                    type={regular.type}
                    selected={regular.selected}
                    key={regular.title}
                    title={regular.title}
                    monthlyPrice={regular.monthlyPrice}
                    yearlyPrice={regular.yearlyPrice}
                    discount={regular.discount}
                    advantages={advantages.simple}
                    actualChoice={tariff}
                    pickTariff={pickTariff}
                    applyTariff={submitInsuranceInfo(regular.type)}
                    isVoucher={isVoucher}
                    linkNumber={2}
                  />
                </TariffCardWrapper>
                <TariffCardWrapper cardNumber={2}>
                  <TariffCard
                    planId={premium.planId}
                    type={premium.type}
                    selected={premium.selected}
                    key={premium.title}
                    title={premium.title}
                    monthlyPrice={premium.monthlyPrice}
                    yearlyPrice={premium.yearlyPrice}
                    discount={premium.discount}
                    advantages={advantages.premium}
                    actualChoice={tariff}
                    pickTariff={pickTariff}
                    applyTariff={submitInsuranceInfo(premium.type)}
                    isVoucher={isVoucher}
                    linkNumber={3}
                  />
                </TariffCardWrapper>
              </Slider>
            </TariffsWrapper>
            <InsurersWrapper>
              <CustomIcon icon="lequite" iconColor="lequiteImage" height="81" width="160" />
            </InsurersWrapper>
            <RulesContainer />
          </QuizStepWrapper>
        </Container>
      </Page>
      <Footer />
    </PageContainer>
  );
};

export default Insurance;
