import React, { useContext, useEffect, useLayoutEffect } from "react";
import Slider from "react-slick";
import Footer from "../../shared/elements/Footer/index";
import { Header, RulesContainer } from "../../shared/elements/index";
import { PageContainer } from "../../shared/layout/Page/index";
import { RedirectionContext } from "../../store/context/subscription/redirection";
import InterestsCard from "./components/InterestsCard/index";
import { interests } from "./components/InterestsCard/mocks";
import { interestsSliderSettings, transportSliderSettings } from "./components/sliderSettings";
import { homeTransportSlides } from "./components/Transport/config";
import TransportCard from "./components/Transport/TransportCard";
import { selectedTheme } from "../../themeProviderHOC";
import {
  Description,
  InterestsContent,
  InterestsTitle,
  InterestsWrapper,
  MainText,
  MainWrapper,
  PageWrapper,
  TextWrapper,
  TransportSection,
  TransportSliderWrapper,
  TransportText,
  TransportTitle,
  InformationLink,
  EmptyContainer,
} from "./elements";
import VoucherCard from "./components/VoucherCard";
import PlansSection from "./components/PlansSection";

const Home = () => {
  useLayoutEffect(() => window.scrollTo({ top: 0, behavior: "smooth" }), []);
  const { clearPersistedStorage } = useContext(RedirectionContext);
  useEffect(() => clearPersistedStorage(), [clearPersistedStorage]);

  useEffect(() => {
    document.head.appendChild(
      document.createElement("style")
    ).innerHTML = `button:before {color: ${selectedTheme.colors.primaryText} !important;}`;
  }, []);
  return (
    <PageContainer>
      <PageWrapper>
        <Header />
        <MainWrapper>
          <TextWrapper>
            {process.env.REACT_APP_THEME_KEY === "ONEY" ? (
              <MainText>L'Assurance Responsabilité Civile Oney</MainText>
            ) : (
              <MainText>L'Assurance Responsabilité Civile </MainText>
            )}
            <Description>
              A partir de 7,50 € par mois, offrez-vous une protection et roulez tranquille{" "}
              <InformationLink href="#rules"> (1) </InformationLink>
            </Description>
          </TextWrapper>
        </MainWrapper>
        <VoucherCard />
        <PlansSection />
      </PageWrapper>
      <TransportSection>
        <TransportTitle>En savoir plus sur les EDPM</TransportTitle>
        <TransportText>
          Non polluant et peu encombrant, l’EDPM est devenu un moyen de transport économique et efficace pour tous les
          trajets courts. Tour d’horizon des véhicules protégés par Owen.
        </TransportText>
        <TransportSliderWrapper>
          <Slider {...transportSliderSettings}>
            {homeTransportSlides.map(({ icon, name, description }) => (
              <TransportCard key={name} icon={icon} name={name} description={description} />
            ))}
          </Slider>
        </TransportSliderWrapper>
      </TransportSection>
      <InterestsTitle>ces offres peuvent aussi vous intéresser</InterestsTitle>
      <InterestsWrapper>
        <InterestsContent>
          <Slider {...interestsSliderSettings} className="interests-slider">
            {interests.map(({ title, description, icon, buttonText }, index) => (
              <InterestsCard key={index} title={title} description={description} icon={icon} buttonText={buttonText} />
            ))}
          </Slider>
        </InterestsContent>
      </InterestsWrapper>
      <RulesContainer />
      <EmptyContainer />
      <Footer />
    </PageContainer>
  );
};

export default Home;
