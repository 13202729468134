import React, { FC } from "react";
import styled from "styled-components";
import { StyledThemePropsType } from "../../../config/theme/index";
import { H3Styles, LabelStyles } from "../../typography/index";

type LabelWithDescriptionContainer = {
  last?: boolean;
};

const LabelWithDescriptionContainer = styled.div<LabelWithDescriptionContainer>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  overflow-x: hidden;
  margin-bottom: 17px;
`;

const LabelText = styled.div`
  ${LabelStyles};
  color: ${(props: StyledThemePropsType) => props.theme.colors.gray};
  text-transform: uppercase;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 2px;
`;

const DescriptionTextwrapper = styled.div`
  width: 100%;
  overflow: hidden;
`;

const DescriptionText = styled.p`
  ${H3Styles};
  margin: 0;
  font-weight: normal;
  color: ${(props: StyledThemePropsType) => props.theme.colors.darkColor};
  word-wrap: break-word;
`;

type LabelWithDescription = LabelWithDescriptionContainer & {
  label: string;
  description: string | number | Date;
};

const LabelWithDescription: FC<LabelWithDescription> = ({ label, description, last }) => {
  return (
    <LabelWithDescriptionContainer last={last}>
      <LabelText>{label}</LabelText>
      <DescriptionTextwrapper>
        <DescriptionText>{description}</DescriptionText>
      </DescriptionTextwrapper>
    </LabelWithDescriptionContainer>
  );
};

export default LabelWithDescription;
