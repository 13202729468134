import gql from "graphql-tag";

const VALIDATE_QUESTION_ANSWER = gql`
    mutation validateQuestionAnswer($questionId: ID!, $optionId: ID!) {
        validateQuestionAnswer(questionId: $questionId, optionId: $optionId) {
            valid
            error
        }
    }
`;

export default VALIDATE_QUESTION_ANSWER;
