import React, { FC } from "react";
import { LabelWithDescription } from "../../../../shared/elements/index";
import {
  ModifierButton,
  PaymentBlockTitle,
  PaymentInfoBlock,
  PaymentInfoBlockBackgroundForInsurance,
  PropertyWidthWrapper,
} from "../elements";

type PaymentInfoProps = {
  userName: string;
  iban: string;
  frequency: string;
  modify(): void;
};

const PaymentInfo: FC<PaymentInfoProps> = ({ modify, iban, userName, frequency }) => {
  return (
    <PaymentInfoBlockBackgroundForInsurance>
      <PaymentInfoBlock overflow="visible">
        <PaymentBlockTitle>
          VOS DONNÉES BANCAIRES
        </PaymentBlockTitle>
          <PropertyWidthWrapper width={156}>
            <LabelWithDescription label="Titulaire du compte" description={userName} />
          </PropertyWidthWrapper>
					<LabelWithDescription label="IBAN" description={iban} />
          <PropertyWidthWrapper width={185}>
            <LabelWithDescription label="fréquence de paiement" description={frequency} />
          </PropertyWidthWrapper>
        
        <ModifierButton onClick={modify}>
          MODIFIER
        </ModifierButton>
      </PaymentInfoBlock>
    </PaymentInfoBlockBackgroundForInsurance>
  );
};

export default PaymentInfo;
