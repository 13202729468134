import styled, { css } from "styled-components";
import { media } from "styled-bootstrap-grid";
import { StyledThemePropsType } from "../../../config/theme/index";
import { DefaultFlexStyles } from "../../styles/index";
import { BodyStyles, ButtonTextDesktopStyles, H3Styles } from "../../typography/index";

export type ButtonContainerProps = {
  variant: "light" | "dark" | "white";
  size: "xs" | "s" | "m" | "l" | "sH" | "mH" | "auto";
  fullWidth?: boolean;
  disabled: boolean;
  active?: boolean;
  className?: string;
};

const sizeXS = css`
  width: 60px;
`;

const sizeS = css`
  width: 120px;
`;

const sizeM = css`
  width: 185px;
`;

const sizeL = css`
  width: 280px;
`;

const smallHigh = css`
  width: 134px;
  height: 62px;
`;

const mediumHigh = css`
  width: 166px;
  height: 62px;
`;

const auto = css`
  width: auto;
  height: 62px;
`;

const basicStyles = css`
  ${({ size }: ButtonContainerProps & StyledThemePropsType) => {
    switch (size) {
      case "xs":
        return sizeXS;
      case "s":
        return sizeS;
      case "m":
        return sizeM;
      case "l":
        return sizeL;
      case "sH":
        return smallHigh;
      case "mH":
        return mediumHigh;
      case "auto":
        return auto;
    }
  }};
  ${(props: ButtonContainerProps) =>
    props.fullWidth &&
    css`
      width: 100%;
    `};
  // In future, we could use custom helper hexToRgba.
  border: none;
  cursor: pointer;
  text-align: center;

  &:focus {
    outline: none;
  }
`;

const dark = css`
  ${basicStyles};
  background-color: ${(props: StyledThemePropsType) => props.theme.colors.primaryDarkButton};
  color: ${(props: StyledThemePropsType) => props.theme.colors.white};

  &:hover {
    background-color: ${(props: StyledThemePropsType) => props.theme.colors.primaryDarkButtonHover};
  }
  &:active {
    background-color: ${(props: StyledThemePropsType) => props.theme.colors.primaryDarkButtonActive};
  }
  &:disabled {
    background-color: ${(props: StyledThemePropsType) => props.theme.colors.primaryDarkButtonDisabled};
  }
`;

const light = css`
  ${basicStyles};
  background-color: ${(props: StyledThemePropsType) => props.theme.colors.white};
  color: ${(props: StyledThemePropsType) => props.theme.colors.primaryTextLight};
  border: 1px solid ${(props: StyledThemePropsType) => props.theme.colors.primaryTextLight};

  &:hover {
    background-color: ${(props: StyledThemePropsType) => props.theme.colors.white};
    color: ${(props: StyledThemePropsType) => props.theme.colors.primaryTextDark};
    border: 1px solid ${(props: StyledThemePropsType) => props.theme.colors.primaryTextDark};
  }
`;

const white = css`
  ${basicStyles};
  background-color: ${(props: StyledThemePropsType) => props.theme.colors.white};
  color: ${(props: StyledThemePropsType) => props.theme.colors.highlightedText};
  border: 1px solid transparent;
  box-shadow: unset;

  &:hover {
    background-color: ${(props: StyledThemePropsType) => props.theme.colors.white};
    color: ${(props: StyledThemePropsType) => props.theme.colors.primaryTextDark};
    border: 1px solid ${(props: StyledThemePropsType) => props.theme.colors.primaryTextDark};
  }
`;

const disabledButtonStyles = css`
  box-shadow: unset;
  border-radius: 8px;
  background-color: ${(props: StyledThemePropsType) => props.theme.colors.primaryTextLight};
  color: ${(props: StyledThemePropsType) => props.theme.colors.gray};
  border: none;
  cursor: unset;
  
`;

const activeButtonStyles = css`
  border-radius: 8px;
`;

const ButtonContainer = styled.button<ButtonContainerProps>`
  ${DefaultFlexStyles};
  ${ButtonTextDesktopStyles};
  text-transform: uppercase;
  border-radius: 8px;
  padding: 14px 17px;
  ${({ size }: ButtonContainerProps) => (size === "l" ? H3Styles : BodyStyles)};
  ${({ variant }: StyledThemePropsType & ButtonContainerProps) => {
    switch (variant) {
      case "dark":
        return dark;
      case "light":
        return light;
      case "white":
        return white;
    }
  }};
  ${({ disabled }: ButtonContainerProps & StyledThemePropsType) => disabled && disabledButtonStyles};
  ${({ active }: ButtonContainerProps & StyledThemePropsType) => (!active ? activeButtonStyles : null)};

  ${media.lg`
		padding: 20px 30px;
	`};
`;

export { ButtonContainer };
