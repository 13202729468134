import React, { useCallback, useContext, useLayoutEffect, useState, useMemo } from "react";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import Footer from "../../../shared/elements/Footer";
import { Button, CustomCheckBox, Logo, Message, Stepper } from "../../../shared/elements/index";
import { stepperMock } from "../../../shared/elements/Stepper/mock";
import { useRedirection } from "../../../shared/helpers/useRedirection";
import Page, { Container, PageContainer } from "../../../shared/layout/Page/index";
import { driversSchema } from "../../../shared/validation/drivers";
import { AddressContext } from "../../../store/context/subscription/address";
import { DriversContext } from "../../../store/context/subscription/drivers";
import { InsuranceContext } from "../../../store/context/subscription/insurance";
import { PaymentContext } from "../../../store/context/subscription/payment";
import { PersonalTransportContext } from "../../../store/context/subscription/transport";
import { ErrorMessage } from "../../questionnaire/Step3/index";
import { QuizStepTitle, QuizStepWrapper } from "../elements";
import { CheckboxWrapper, CustomSpan, PolicyText } from "../transport/elements";
import SingleDriverInfo from "./driver/index";
import {
  AddButtonIcon,
  AddButtonText,
  AddDriverWrapper,
  ButtonContainer,
  ContentWrapper,
  ElementsWrapper,
  HiddenWrapper,
  MessageWrapper,
  VerticalDriversBlock,
} from "./elements";
import { Driver, newSecondaryDriver } from "./mocks";

export type DriverErrors = {
  firstName: string;
  lastName: string;
  day: string;
  month: string;
  year: string;
  city: string;
  country: string;
  gender: string;
};

const initialDriverErrors: DriverErrors = {
  firstName: "",
  lastName: "",
  day: "",
  month: "",
  year: "",
  city: "",
  country: "",
  gender: "",
};

const Drivers = () => {
  useLayoutEffect(() => window.scrollTo({ top: 0, behavior: "smooth" }), []);
  /**
   * Logic related to the routing actions.
   */
  const { firstName, lastName, completed: paymentStepCompleted } = useContext(PaymentContext);
  const {
    drivers: driversArray,
    driversResponsibilityPolicy,
    saveDriversInfo,
    completed: driversStepCompleted,
  } = useContext(DriversContext);
  const { completed: transportStepCompleted } = useContext(PersonalTransportContext);
  const { completed: insuranceStepCompleted } = useContext(InsuranceContext);
  const { completed: addressStepCompleted } = useContext(AddressContext);

  const flowComppleted: boolean = useMemo(
    () =>
      transportStepCompleted &&
      insuranceStepCompleted &&
      driversStepCompleted &&
      addressStepCompleted &&
      paymentStepCompleted,
    [addressStepCompleted, driversStepCompleted, insuranceStepCompleted, paymentStepCompleted, transportStepCompleted]
  );
  const { push } = useHistory();
  const [nextRoute, prevRoute] = useRedirection({
    currentRoute: "drivers",
    nextRoute: "address",
    prevRoute: "transport",
  });
  const back = useCallback(() => push(prevRoute), [prevRoute, push]);

  /**
   * Logic related to the storing page-data in the state.
   */

  const initialDrivers: Driver[] =
    driversArray.length > 1
      ? [...driversArray]
      : [
          {
            ...driversArray[0],
            firstName: driversArray[0].firstName || firstName,
            lastName: driversArray[0].lastName || lastName,
          },
        ];

  const [drivers, setDrivers] = useState<Driver[]>([...initialDrivers]);
  const [checkDrivers, setCheckDrivers] = useState<boolean>(driversResponsibilityPolicy);
  const [ageValidation, setAgeValidation] = useState<string | null>(null);
  const [validationErrors, setValidationErrors] = useState<DriverErrors[]>([
    { ...initialDriverErrors },
    { ...initialDriverErrors },
    { ...initialDriverErrors },
    { ...initialDriverErrors },
  ]);

  const inputsOnChange = useCallback(
    (field: string, index: number, value: string | Date) => {
      const allDrivers = [...drivers];
      allDrivers[index][field] = value;
      return setDrivers([...allDrivers]);
    },
    [drivers]
  );

  const checkAgeValidation = useCallback(
    (value: string | null) => {
      return setAgeValidation(value);
    },
    [setAgeValidation]
  );

  const deleteCurrentDriver = useCallback(
    (key: number) => {
      const allDrivers = drivers.filter((driver, index) => index !== key);
      return setDrivers([...allDrivers]);
    },
    [drivers]
  );

  const isFullDriversList = drivers.length >= 4;

  const addNewDriver = useCallback(() => {
    if (!isFullDriversList) {
      setDrivers([...drivers, { ...newSecondaryDriver, key: drivers.length + 1 }]);
    }
  }, [drivers, isFullDriversList]);

  /**
   * Logic related to the setting data in the context and move forward to the next step.
   */
  const saveDriversContext = useCallback(() => {
    saveDriversInfo({ drivers: [...drivers], driversResponsibilityPolicy: checkDrivers, completed: true });
    if (!flowComppleted) push(nextRoute);
    push("/subscription/summary");
  }, [flowComppleted, checkDrivers, drivers, nextRoute, push, saveDriversInfo]);

  /**
   * Show/hide error message by clicking on checkbox .
   */
  const [checkError, setCheckError] = useState<ErrorMessage>({
    display: false,
    message: "Vous devez attester sur l’honneur avant de poursuivre.",
  });
  const hideCheckErrorMessage = useCallback(() => setCheckError({ ...checkError, display: false }), [checkError]);
  const showCheckErrorComponent = checkError.display && !!checkError.message;

  const driverCheckBox = useCallback(() => {
    setCheckDrivers(!checkDrivers);
    setCheckError({ display: false, message: checkError.message });
  }, [checkDrivers, checkError, setCheckError]);
  /**
   * Logic related to the validation process and page submitting.
   */
  const [checkboxValidation, setCheckboxValidation] = useState<boolean>(false);
  const submitDriversInfo = useCallback(async () => {
    setCheckboxValidation(false);
    const validationPassed = await driversSchema
      .validate(drivers, { abortEarly: false })
      .then(() => {
        setValidationErrors([
          { ...initialDriverErrors },
          { ...initialDriverErrors },
          { ...initialDriverErrors },
          { ...initialDriverErrors },
        ]);
        return true;
      })
      .catch((e) => {
        const errors: any = [
          { ...initialDriverErrors },
          { ...initialDriverErrors },
          { ...initialDriverErrors },
          { ...initialDriverErrors },
        ];
        e.inner.forEach((err: yup.ValidationError) => {
          const errPath = err.path.split(".")[1];
          const pathIndex = Number(err.path.split("].")[0].split("[")[1]);
          const errMsg = err.message.split(".")[1];
          return (errors[pathIndex][errPath] = errMsg);
        });
        setValidationErrors([...errors]);
        return false;
      });

    if (!checkDrivers) {
      setCheckboxValidation(true);
    }

    if (ageValidation) {
      return setCheckError({ display: true, message: ageValidation });
    }

    if (!checkDrivers) {
      setCheckError({ display: true, message: checkError.message });
    } else {
      setCheckError({ ...checkError, display: false });
    }

    if (validationPassed && checkDrivers) {
      saveDriversContext();
    }
  }, [checkDrivers, drivers, saveDriversContext, setCheckError, checkError, ageValidation]);

  return (
    <PageContainer>
      <Logo showGoBack={true} goBack={back} />
      <Stepper steps={stepperMock.steps} currentlySelected={2} />
      <Page>
        <Container>
          <QuizStepWrapper>
            <QuizStepTitle>Dites-nous qui va piloter votre véhicule</QuizStepTitle>
            <ContentWrapper>
              {drivers.map((driver, index) => {
                return (
                  index === 0 && (
                    <SingleDriverInfo
                      key={index}
                      index={index}
                      title={driver.title}
                      driverInfo={{ ...driver }}
                      driverErrors={{ ...validationErrors[index] }}
                      inputsOnChange={inputsOnChange}
                      checkAgeValidation={checkAgeValidation}
                      deleteCurrentDriver={deleteCurrentDriver}
                    />
                  )
                );
              })}
              <VerticalDriversBlock>
                {drivers.map((driver, index) => {
                  return (
                    index > 0 && (
                      <SingleDriverInfo
                        key={index}
                        index={index}
                        title={driver.title}
                        driverInfo={{ ...driver }}
                        driverErrors={{ ...validationErrors[index] }}
                        inputsOnChange={inputsOnChange}
                        checkAgeValidation={checkAgeValidation}
                        deleteCurrentDriver={deleteCurrentDriver}
                      />
                    )
                  );
                })}
                {!isFullDriversList && (
                  <AddDriverWrapper firstPosition={drivers.length === 1} tabIndex={0} onClick={addNewDriver}>
                    <AddButtonIcon />
                    <AddButtonText>Ajouter un conducteur</AddButtonText>
                  </AddDriverWrapper>
                )}
              </VerticalDriversBlock>
            </ContentWrapper>
            <CheckboxWrapper>
              <label>
                <CustomCheckBox disabled={false} checked={checkDrivers} onChange={driverCheckBox} />
                <CustomSpan error={checkboxValidation} />
              </label>
              <PolicyText onClick={driverCheckBox} error={checkboxValidation}>
                J’atteste sur l’honneur que les conducteurs de ce véhicule seront aptes à la conduite (aptitude physique
                et mentale pour une conduite non dangereuse).
              </PolicyText>
            </CheckboxWrapper>
            <ElementsWrapper>
              <ButtonContainer>
                <Button
                  fullWidth={true}
                  variant="dark"
                  size="m"
                  active={checkDrivers}
                  disabled={false}
                  onClick={submitDriversInfo}
                >
                  Étape suivante
                </Button>
              </ButtonContainer>
              {showCheckErrorComponent ? (
                <>
                  <HiddenWrapper />
                  <MessageWrapper>
                    <Message
                      close={hideCheckErrorMessage}
                      icon="error"
                      iconColor="error"
                      backgroundColor="errorBackground"
                      textColor="error"
                      message={checkError.message}
                    />
                  </MessageWrapper>
                </>
              ) : null}
            </ElementsWrapper>
          </QuizStepWrapper>
        </Container>
      </Page>
      <Footer />
    </PageContainer>
  );
};

export default Drivers;
